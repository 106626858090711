import React from 'react';

type CheckboxProps = {
    /**
     * true si la case est sur l'état cochée/oui/true, false sinon
     */
    checked?: boolean | undefined;
    /**
     * fonction qui permet de gérer le changement d'état de la case
     */
    onCheckedChange: (checked: boolean) => void;
};

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    let className = 'form-check form-switch custom-switch switch-intermediate input-values';
    if (props.checked === undefined) {
        className += ' undefined';
    }
    return (
        <div>
            <div className={className}>
                <input className="form-check-input" type="checkbox" checked={props.checked} onChange={() => {}} />
                <button type="button" className="btn-true" onClick={() => props.onCheckedChange(true)} />
                <button type="button" className="btn-false" onClick={() => props.onCheckedChange(false)} />
            </div>
        </div>
    );
};
export default Checkbox;
