import React from 'react';
import { ReactComponent as IconTrash } from '../../assets/icons/konva/icon-trash.svg';
import FileUpload from './FileUpload';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { DocumentEntry } from './types';
import DocumentButton from './DocumentButton';

type FileFormRowProps<T> = {
    /**
     * le texte qui s'affiche dans la ligne de formulaire
     */
    label: string;
    /**
     * le document concerné par ce Composant. il ne sera utilise que pour être passé aux autres fonctions.
     */
    fileDescriptor: T;
    /**
     * true par défaut. true si on peut supprimer le document, false sinon
     */
    canDeleteDocs?: boolean | undefined;
    /**
     * false par défaut. true si on ne peut pas modifier la ligne de formulaire (si true, on ne peut ni modifier, ni supprimer), false sinon.
     */
    disabled?: boolean | undefined;
    /**
     * fonction qui permet de convertir le document en DocumentEntry, qu'on sait afficher avec DocumentsViewer
     */
    convertToDocumentEntry: (fileDescriptor: T) => DocumentEntry | undefined;
    /**
     * fonction appelée lorsqu'on clique sur la poubelle
     */
    onDelete: (fileDescriptor: T) => void;
    /**
     * on appelle cette fonction avec le fichier sélectionné (dans la popup systeme de file picker.)
     */
    onUpload: (file: File) => void;
    /** pour personnaliser les labels secondaires. */
    optionalLabels?: {
        uploadTitle?: string;
        deleteTitle?: string;
        deleteMessage?: string;
        uploadFilename?: string;
    };
    /**
     * Largeur fixe pour le label (ex: '200px' ou '12rem')
     */
    labelWidth?: string;
};

/**
 * Composant qui permet de gérer les documents dans un formulaire.
 * @param props Les propriétés du composant
 * @returns Un composant React permettant de gérer un document dans un formulaire
 * @example
 * ```tsx
 * <FileFormRow<apiDRA.DraDocOutput | undefined>
 *     label={apiDRA.knownDraDocTypeToString('piece_identite')}
 *     fileDescriptor={pieceIdentite}
 *     disabled={disabled}
 *     canDeleteDocs={true}
 *     convertToDocumentEntry={draDocOutputToDocumentEntry}
 *     onDelete={handleDeleteDocument}
 *     onUpload={(file: File) => uploadDra(file, 'piece_identite')}
 * />
 * ```
 */
const FileFormRow = <T extends any>(props: FileFormRowProps<T>): JSX.Element => {
    const AlertSwal = withReactContent(Swal);

    const doc: DocumentEntry | undefined = props.convertToDocumentEntry(props.fileDescriptor);

    const displayConfirmRemovalModal = () => {
        AlertSwal.fire({
            title: props.optionalLabels?.deleteTitle || 'Suppression du document',
            html: <p>{props.optionalLabels?.deleteMessage || 'Merci de confirmer la suppression du document'}</p>,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-red min-width',
                cancelButton: 'btn btn-secondary min-width',
            },
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                props.onDelete(props.fileDescriptor);
            }
        });
    };

    return (
        <div className="form-inline">
            <div className="form-group account align-items-center mb-2">
                <label
                    htmlFor="certifLcc"
                    className="col-form-label"
                    style={props.labelWidth ? { width: props.labelWidth, minWidth: props.labelWidth } : undefined}
                >
                    {props.label} :
                </label>
                <div className="position-relative d-flex align-items-center">
                    {doc ? (
                        <div className="btn-grp align-items-center nowrap">
                            <DocumentButton {...doc} />
                            {props.disabled !== true && props.canDeleteDocs !== false && (
                                <button type="button" className="btn btn-remove" onClick={displayConfirmRemovalModal}>
                                    <IconTrash fill="#5a5a5a" height={16} />
                                </button>
                            )}
                        </div>
                    ) : (
                        <>
                            {props.disabled !== true && (
                                <FileUpload
                                    label={props.optionalLabels?.uploadTitle || 'Télécharger document'}
                                    name={props.optionalLabels?.uploadFilename || 'Document'}
                                    onChange={props.onUpload}
                                    className="mt-0"
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileFormRow;
