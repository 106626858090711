import { useState } from 'react';
import './ToolTip.scss';
import ToolTip from './ToolTip';

interface CopyToClipboardProps {
    text: string;
    children?: React.ReactNode;
}
const CopyToClipboard: React.FC<CopyToClipboardProps> = (props: CopyToClipboardProps) => {
    const [showCopied, setShowCopied] = useState(false);

    const handleCopy = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigator.clipboard.writeText(props.text);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 1000);
    };

    return (
        <ToolTip tooltip={showCopied ? 'Copié !' : 'Cliquer pour copier'}>
            <span onClick={handleCopy}>{props.children || ''}</span>
        </ToolTip>
    );
};

export default CopyToClipboard;
