import React, { useEffect, useState } from 'react';
import { DocumentEntry, imageDocs, imageDocsRegex } from './types';

type DocumentsViewerProps =
    | {
          docs: DocumentEntry[];
      }
    | {
          doc: DocumentEntry;
      };

//const viewableDocs = ['pdf'];

export const DocumentsViewer: React.FC<DocumentsViewerProps> = (props: DocumentsViewerProps) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [activeTabIndex, setActiveTab] = useState<number>(0);

    const docs = 'docs' in props ? props.docs : [props.doc];
    const filteredDocs = docs.filter((element) => element.url);
    // Change active tab
    const handleActiveTab = async (index: number) => {
        console.log('active tab index = ' + index);
        setActiveTab(index);
        const element = filteredDocs[index];
        const isImage = (element.extension && imageDocs.includes(element.extension)) || imageDocsRegex.test(element.url) || element.url.includes('_blob');
        if (isImage) {
            // const response = await fetch(element.url);
            // const blob = await response.blob();
            // // Create a temporary URL for display
            // setImageUrl(URL.createObjectURL(blob));
            setImageUrl(element.url);
        } else {
            setImageUrl(null);
        }
    };

    useEffect(() => {
        handleActiveTab(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="tabs">
            <div className="tabs-header">
                {/* <button className={`btn btn-tabs active`}>{props.title ?? 'Document'}</button> */}
                {filteredDocs.length > 1 &&
                    filteredDocs.map((element, index) => {
                        console.log(element.url, index);
                        return (
                            <button
                                className={`btn btn-tabs${activeTabIndex === index ? ' active' : ''}`}
                                key={index}
                                data-index={index}
                                onClick={() => handleActiveTab(index)}
                            >
                                {element.title ?? 'Document'}
                            </button>
                        );
                    })}
            </div>
            <div className="tabs-body">
                {imageUrl ? (
                    <img src={imageUrl} alt={filteredDocs[activeTabIndex].title || ''} className="img-fluid" />
                ) : (
                    <iframe
                        src={filteredDocs[activeTabIndex].url}
                        title={filteredDocs[activeTabIndex].title || 'Document'}
                        width="100%"
                        height="600"
                        id="iframePdf"
                    />
                )}
            </div>
        </div>
    );
};

export default DocumentsViewer;
