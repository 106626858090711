import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import * as apiDRA from '../../../services/apiDRAService';
import { RenovationDAmpleurElligible } from '../../../services/calculs/aides/aideRenovationDAmpleurDefs';
import Swal from 'sweetalert2';
// Définir le type pour les données du formulaire
export type ScenarioProps = 'scenario1' | 'scenario2' | 'scenarioPerso' | '';

type MailToMarFormData = {
    comment: string | undefined;
};

export type MailToMarFormProps = {
    dossierRenoDAmpleur: apiDRA.DossierRenoDAmpleur;
};

const mailToMarFormSchema = yup.object().shape({
    comment: yup.string().required('Veuillez sélectionner un scénario.'),
});

const DEFAULT_COMMENT_FOR_MAR_1 = 'Le client a retenu le scénario 1';
const DEFAULT_COMMENT_FOR_MAR_2 = 'Le client a retenu le scénario 2';
const DEFAULT_COMMENT_FOR_MAR_PERSO_LABEL = 'Le client a retenu un scénario personnalisé (merci de compléter) : ';
const DEFAULT_COMMENT_FOR_MAR_PERSO = 'Le client a retenu un scénario personnalisé : ';

const MailToMarForm: React.FC<MailToMarFormProps> = (props: MailToMarFormProps) => {
    const [selectedScenario, setSelectedScenario] = useState<ScenarioProps>('');
    const [scenarios, setScenarios] = useState<{ value: ScenarioProps; label: string; comment: string }[]>([]);
    const [scenario3Text, setScenario3Text] = useState<string>('');
    useEffect(() => {
        const reno = props.dossierRenoDAmpleur.renoDampleurParams as RenovationDAmpleurElligible;
        const scenarioSummary =
            reno.scenariosText !== null && reno.scenariosText !== undefined && reno.scenariosText.length !== 0
                ? reno.scenariosText[reno.scenariosText.length - 1]
                : '';

        setScenario3Text(scenarioSummary); // texte du scénario 2 par défaut.

        setScenarios([
            {
                value: 'scenario1',
                label: DEFAULT_COMMENT_FOR_MAR_1,
                comment: DEFAULT_COMMENT_FOR_MAR_1,
            },
            {
                value: 'scenario2',
                label: DEFAULT_COMMENT_FOR_MAR_2,
                comment: DEFAULT_COMMENT_FOR_MAR_2,
            },
            {
                value: 'scenarioPerso',
                label: DEFAULT_COMMENT_FOR_MAR_PERSO_LABEL,
                comment: DEFAULT_COMMENT_FOR_MAR_PERSO + scenarioSummary,
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = (values: MailToMarFormData) => {
        // Déplacer l'appel API ici
        if (values && props.dossierRenoDAmpleur?.flowId) {
            try {
                // console.log('appel api avec = ' + JSON.stringify(formikRef.current.values.comment, null, 2));
                apiDRA.sendDraToMar(props.dossierRenoDAmpleur.id, values.comment).then(() => {
                    // on le fait aps dans await car on veut que le code continue
                    // Recharger la page après l'envoi réussi

                    const message = 'Tous les éléments viennent d’être envoyés au MAR. Nous ne manquerons pas de vous informer de la date de son intervention.';
                    Swal.fire({
                        title: 'Mail envoyé',
                        text: message,
                        icon: 'success',
                        timer: 5000,
                        showConfirmButton: false,
                    }).then(() => {
                        window.location.reload();
                    });
                });
            } catch (error) {
                console.error("Erreur lors de l'envoi du mail au MAR:", error);
            }
        }
    };

    return (
        <div>
            <p>Sélectionnez un commentaire à destination de l'accompagnateur Rénov'</p>
            <Formik
                initialValues={{
                    comment: '',
                }}
                validationSchema={mailToMarFormSchema}
                onSubmit={() => {}}
                validateOnMount
                validateOnChange
            >
                {({ values, setValues, isValid }) => (
                    <Form className="container">
                        <div className="row">
                            {scenarios.map((scenario, index) => (
                                <div className="col-12" key={index}>
                                    <div className="form-group">
                                        <div className="custom-radio">
                                            <Field
                                                type="radio"
                                                id={scenario.value}
                                                value={scenario.value}
                                                name="scenario"
                                                checked={selectedScenario === scenario.value}
                                                onChange={() => {
                                                    setSelectedScenario(scenario.value);
                                                    setValues({
                                                        comment: scenario.comment,
                                                    });
                                                }}
                                            />
                                            <div className="fake-radio">{/* Leave empty */}</div>
                                            <label htmlFor={scenario.value}>{scenario.label}</label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p className="text-start mt-4">Veuillez saisir les élements que le client souhaiterait installer : </p>
                        <div className="position-relative">
                            <Field
                                as="textarea"
                                name="scenarioPersoText"
                                placeholder="Entrez votre scénario personnalisé"
                                disabled={selectedScenario !== 'scenarioPerso'}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setSelectedScenario('scenarioPerso');
                                    const newScenarioPersoText = e.target.value;
                                    setValues({
                                        comment: DEFAULT_COMMENT_FOR_MAR_PERSO + newScenarioPersoText,
                                    });
                                    // Mettre à jour le scénario personnalisé
                                    setScenarios((prevScenarios) =>
                                        prevScenarios.map((scenario) =>
                                            scenario.value === 'scenarioPerso'
                                                ? { ...scenario, comment: DEFAULT_COMMENT_FOR_MAR_PERSO + newScenarioPersoText }
                                                : scenario
                                        )
                                    );
                                }}
                                className="form-control"
                                value={selectedScenario === 'scenarioPerso' ? values.comment?.replace(DEFAULT_COMMENT_FOR_MAR_PERSO, '') : scenario3Text}
                            />

                            <ErrorMessage name="scenarioPersoText" component="span" className="invalid-feedback " />
                        </div>
                        <div className="btn-grp justify-content-center mt-3">
                            <button type="submit" className="btn btn-continue min-width" disabled={!isValid} onClick={() => handleSave(values)}>
                                Envoyer le mail au MAR
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
export default MailToMarForm;
