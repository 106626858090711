import { currencyFormat, Labelled } from '../../tools/TypeHelper';
import { isBallonThermo } from '../filterPreconisation/filterEauChaude';
import { PackageData } from '../package';
import { isKnownCategories } from '../products';
export const PAC_AA_START = 'PAC-AA';
export const PAC_AE_START = 'PAC-AE';
// Read me :
//
// la rénovation globale a disparue !
// elle est rempalcée par la rénovation d'ampleur.
//
// la surface n'est plus un critere,
// le chauffage n'est plus un critere, seulement une des trois isolation à faire.

export type categoryRevenu = 'tresModeste' | 'modeste' | 'intermediaire' | 'superieur';
export type localisation = 'ileDeFrance' | 'ailleurs';
export const niveauAmeliorationNames = ['gain2classes', 'gain3classes', 'gain4classes'] as const;
export type NiveauAmelioration = (typeof niveauAmeliorationNames)[number];
export const niveauAmeliorationList: Array<Labelled<NiveauAmelioration>> = [
    { label: '2 classes', value: 'gain2classes' },
    { label: '3 classes', value: 'gain3classes' },
    { label: '4 classes', value: 'gain4classes' },
];
export const sortiePassoireList: Array<Labelled<boolean>> = [
    { label: 'OUI', value: true },
    { label: 'NON', value: false },
];
export type RenovationDAmpleurTarget = {
    niveau: NiveauAmelioration;
    sortiePassoire: boolean;
};
export const getLocalisation = (department: string): localisation => {
    return department === '75' ||
        department === '77' ||
        department === '78' ||
        department === '91' ||
        department === '92' ||
        department === '93' ||
        department === '94' ||
        department === '95'
        ? 'ileDeFrance'
        : 'ailleurs';
};

export type SeuilsRevenus = { [cat in categoryRevenu]: number };
export type Plus = (nb: number) => SeuilsRevenus;

export const getCategorieRevenus = (nbPersonnes: number, revenuAnnuel: number, department: string): categoryRevenu => {
    if (nbPersonnes <= 5) {
        const cond: { [key in categoryRevenu]: number } = conditionsDeRevenus[getLocalisation(department)][nbPersonnes];
        if (revenuAnnuel > cond.superieur) return 'superieur';
        if (revenuAnnuel > cond.intermediaire) return 'intermediaire';
        if (revenuAnnuel > cond.modeste) return 'modeste';
        if (revenuAnnuel > cond.tresModeste) return 'tresModeste';
        return 'tresModeste';
    } else {
        const cond = conditionsDeRevenus[getLocalisation(department)].plus(nbPersonnes);
        if (revenuAnnuel > cond.superieur) return 'superieur';
        if (revenuAnnuel > cond.intermediaire) return 'intermediaire';
        if (revenuAnnuel > cond.modeste) return 'modeste';
        if (revenuAnnuel > cond.tresModeste) return 'tresModeste';
        return 'tresModeste';
    }
};
export const conditionsDeRevenus: Record<localisation, { [nb in number]: SeuilsRevenus } & { plus: Plus }> = {
    ileDeFrance: {
        '1': { superieur: 40018, intermediaire: 28657, modeste: 23541, tresModeste: 0 },
        '2': { superieur: 58827, intermediaire: 42058, modeste: 34551, tresModeste: 0 },
        '3': { superieur: 70382, intermediaire: 50513, modeste: 41493, tresModeste: 0 },
        '4': { superieur: 82839, intermediaire: 58981, modeste: 48447, tresModeste: 0 },
        '5': { superieur: 94844, intermediaire: 67473, modeste: 55427, tresModeste: 0 },
        plus: (nb: number) => ({
            tresModeste: conditionsDeRevenus.ileDeFrance[5].tresModeste + (nb - 5) * 6970,
            modeste: conditionsDeRevenus.ileDeFrance[5].modeste + (nb - 5) * 8486,
            intermediaire: conditionsDeRevenus.ileDeFrance[5].intermediaire + (nb - 5) * 12006,
            superieur: conditionsDeRevenus.ileDeFrance[5].superieur + (nb - 5) * 12006, // oui c'est le même
        }),
    },
    ailleurs: {
        '1': { superieur: 30549, intermediaire: 21805, modeste: 17009, tresModeste: 0 },
        '2': { superieur: 44907, intermediaire: 31889, modeste: 24875, tresModeste: 0 },
        '3': { superieur: 54071, intermediaire: 38349, modeste: 29917, tresModeste: 0 },
        '4': { superieur: 63235, intermediaire: 44802, modeste: 34948, tresModeste: 0 },
        '5': { superieur: 72400, intermediaire: 51281, modeste: 40002, tresModeste: 0 },
        plus: (nb: number) => ({
            tresModeste: conditionsDeRevenus.ailleurs[5].tresModeste + (nb - 5) * 5045,
            modeste: conditionsDeRevenus.ailleurs[5].modeste + (nb - 5) * 6462,
            intermediaire: conditionsDeRevenus.ailleurs[5].intermediaire + (nb - 5) * 9165,
            superieur: conditionsDeRevenus.ailleurs[5].superieur + (nb - 5) * 9165, // oui c'est le même
        }),
    },
};

export type RenovationDAmpleurNotElligible = {
    elligible: false;
    marShouldContactCustomer: false;
};
export type RenovationDAmpleurElligible = {
    elligible: true;
    categorieRevenus: categoryRevenu;
    marShouldContactCustomer: boolean | undefined;
    scenarios: Array<Scenario>;
    scenariosText: Array<string>;
    scenariosExt?: Array<ScenarioExt>;
    scenariosExtText?: Array<[string, string]>;
    renoTextes: RenoTextes;
    plafonds: Record<NiveauAmelioration, string>;
};
export type RenovationDAmpleurParams = RenovationDAmpleurElligible | RenovationDAmpleurNotElligible;

export type DraContext = { draParams: RenovationDAmpleurParams; draTarget: RenovationDAmpleurTarget };

// TODO à foutre dans le BO.
export const RENO_COUT_PRESTATION_MAR = 2400;

export const RENO_PLAFOND_CONST: Record<NiveauAmelioration | 'aideFraisExpertise', number> = {
    gain2classes: 40000,
    gain3classes: 55000,
    gain4classes: 70000,
    aideFraisExpertise: 2000,
};
export const BONIFICATION_PASSOIRE = 0.1;

export const RENO_VALUES: Record<categoryRevenu, Record<NiveauAmelioration | 'aideFraisExpertise', number>> = {
    tresModeste: {
        gain2classes: 0.8,
        gain3classes: 0.8,
        gain4classes: 0.8,
        aideFraisExpertise: 1,
    },
    modeste: {
        gain2classes: 0.6,
        gain3classes: 0.6,
        gain4classes: 0.6,
        aideFraisExpertise: 0.8,
    },
    intermediaire: {
        gain2classes: 0.45,
        gain3classes: 0.5,
        gain4classes: 0.5,
        aideFraisExpertise: 0.4, // changé en février 2025
    },
    superieur: {
        gain2classes: 0.1, // changé en janvier 2025
        gain3classes: 0.15, // changé en janvier 2025
        gain4classes: 0.2, // changé en janvier 2025
        aideFraisExpertise: 0.2, // changé en février 2025
    },
};
export const RENO_PLAFOND_TEXT: Record<NiveauAmelioration, string> = {
    gain2classes: currencyFormat(RENO_PLAFOND_CONST.gain2classes, false),
    gain3classes: currencyFormat(RENO_PLAFOND_CONST.gain3classes, false),
    gain4classes: currencyFormat(RENO_PLAFOND_CONST.gain4classes, false),
};
export type RenoTextes = {
    priseEnCharge: string;
    priseEnChargeBonusPassoire: string;
    resteACharge: string;
    fraisExpertises: string;
    aideFraisExpertise: string;
    aideFraisExpertiseResteACharge: string;
};

// #region quelques calculs pour les affichages des aides reno d'ampleur selon le revenu.
const getResteAChargeString = (categoryRevenu: categoryRevenu): { resteACharge: string } => {
    const RatioMin = 1 - (RENO_VALUES[categoryRevenu].gain4classes + BONIFICATION_PASSOIRE); // avec sortie d epassoire.
    const RatioMax = 1 - RENO_VALUES[categoryRevenu].gain2classes;

    const plafondMin = RENO_PLAFOND_CONST.gain2classes;
    const plafondMax = RENO_PLAFOND_CONST.gain4classes;

    return { resteACharge: `de ${currencyFormat(plafondMin * RatioMin, false)} à ${currencyFormat(plafondMax * RatioMax, false)}` };
};

const getPriseEnChargeString = (categoryRevenu: categoryRevenu): { priseEnCharge: string; priseEnChargeBonusPassoire: string } => {
    let RatioMin = RENO_VALUES[categoryRevenu].gain2classes; // avec sortie d epassoire.
    let RatioMax = RENO_VALUES[categoryRevenu].gain4classes;

    const pec = RatioMin === RatioMax ? `${Math.round(RatioMin * 100)}%` : `${Math.round(RatioMin * 100)}% à ${Math.round(RatioMax * 100)}%`;
    RatioMin += BONIFICATION_PASSOIRE;
    RatioMax += BONIFICATION_PASSOIRE;

    const pecPassoire = RatioMin === RatioMax ? `${Math.round(RatioMin * 100)}%` : `${Math.round(RatioMin * 100)}% à ${Math.round(RatioMax * 100)}%`;

    return { priseEnCharge: pec, priseEnChargeBonusPassoire: pecPassoire };
};

const getFraisExpertisesString = (
    categoryRevenu: categoryRevenu
): { fraisExpertises: string; aideFraisExpertise: string; aideFraisExpertiseResteACharge: string } => {
    const aideFraisExpertiseMontant = RENO_PLAFOND_CONST.aideFraisExpertise * RENO_VALUES[categoryRevenu].aideFraisExpertise;
    const aideFraisExpertise =
        currencyFormat(aideFraisExpertiseMontant, false) +
        ' (' +
        Math.round(RENO_VALUES[categoryRevenu].aideFraisExpertise * 100) +
        '% de ' +
        currencyFormat(RENO_PLAFOND_CONST.aideFraisExpertise, false) +
        ')';

    return {
        fraisExpertises: currencyFormat(RENO_COUT_PRESTATION_MAR, false),
        aideFraisExpertise: aideFraisExpertise,
        aideFraisExpertiseResteACharge: currencyFormat(RENO_COUT_PRESTATION_MAR - aideFraisExpertiseMontant, false),
    };
};

const getRenoTextes = (categoryRevenu: categoryRevenu): RenoTextes => {
    return {
        ...getPriseEnChargeString(categoryRevenu),
        ...getResteAChargeString(categoryRevenu),
        ...getFraisExpertisesString(categoryRevenu),
    };
};
// #endregion

export const RENO_TEXT_PAR_REVENUS: Record<categoryRevenu, RenoTextes> = {
    tresModeste: getRenoTextes('tresModeste'),
    modeste: getRenoTextes('modeste'),
    intermediaire: getRenoTextes('intermediaire'),
    superieur: getRenoTextes('superieur'),
};

export const scenarioShortNamesNames = [
    'Isolation Rampants',
    'Isolation Combles',
    'Isolation des planchers bas',
    'Isolation des murs exterieurs',
    'Pompe à chaleur air eau',
    'Pompe à chaleur air air',
    'Ballon Thermodynamique',
    'Ventilation mécanique double flux (VMC)',
    'Fenêtres',
] as const;
export type ScenarioShortNames = (typeof scenarioShortNamesNames)[number];

export type Scenario = Record<ScenarioShortNames, boolean>;
export type ScenarioWithPrice = Record<ScenarioShortNames, { inclus: boolean; prixHt: number }>;

export type ScenarioExt = ScenarioWithPrice & {
    totalHt: number;
    resteACharge: number;
};

export const createShortScenarioNameForRA = (pack: PackageData): ScenarioShortNames | undefined => {
    let result: ScenarioShortNames | undefined = undefined;

    if (!pack.themeLb.startsWith("Economie d'énergie")) {
        result = undefined;
        pack.scenarioNameForRA = result;
        return result;
    }
    if (
        pack.themeLb === "Economie d'énergie/Photovoltaïque" ||
        pack.themeLb === "Economie d'énergie/Changement comportemental" ||
        pack.themeLb === "Economie d'énergie/Batterie"
    ) {
        result = undefined;
        pack.scenarioNameForRA = result;
        return result;
    }
    if (pack.reference.startsWith(PAC_AA_START) || (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Pac Air Air')) {
        result = 'Pompe à chaleur air air';
        pack.scenarioNameForRA = result;
        return result;
    }
    if (pack.reference.startsWith(PAC_AE_START) || (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Pac Air Eau')) {
        result = 'Pompe à chaleur air eau';
        pack.scenarioNameForRA = result;
        return result;
    }

    if (isBallonThermo(pack) || (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'Ballon')) {
        result = 'Ballon Thermodynamique';
        pack.scenarioNameForRA = result;
        return result;
    }

    if (pack.themeLb === "Economie d'énergie/Ventilation" || (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie === 'VMC')) {
        result = 'Ventilation mécanique double flux (VMC)';
        pack.scenarioNameForRA = result;
        return result;
    }
    if (
        pack.themeLb === "Economie d'énergie/Isolation" ||
        (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie_parent === 'Isolation')
    ) {
        if (pack.mainProduct.categorie === 'Murs') result = 'Isolation des murs exterieurs';
        if (pack.mainProduct.categorie === 'Planchers') result = 'Isolation des planchers bas';
        if (pack.mainProduct.categorie === 'Combles') result = 'Isolation Combles';
        if (pack.mainProduct.categorie === 'Rampants') result = 'Isolation Rampants';

        // on passe la aussi pour les porte et fenetre,
        // qui ont la catégorie parent Isolation
        // on si on a pas trouvé on laisse passer pour la suite.
        if (result) {
            pack.scenarioNameForRA = result;
            return result;
        }
    }
    if (
        pack.themeLb === "Economie d'énergie/Porte et fenêtre" ||
        (isKnownCategories(pack.mainProduct.categorie) && pack.mainProduct.categorie_parent === 'Isolation')
    ) {
        if (pack.mainProduct.categorie === 'Portes') result = undefined;
        if (pack.mainProduct.categorie === 'Fenêtres') result = 'Fenêtres';
        pack.scenarioNameForRA = result;

        return result;
    }

    // Normalement on a traité tous les cas, donc ca ne devrait pas arriver.
    pack.scenarioNameForRA = result;
    console.log('Package sans scénario : ' + pack.reference + ' / ' + pack.themeLb + '/' + pack.mainProduct.breadcrumb + ' => ' + pack.scenarioNameForRA);
    return result;
};
