import { atom, selector } from 'recoil';
import {
    ROUTE_PV_FINALISATION,
    ROUTE_PV_PHOTOVOLTAIQUE,
    ROUTE_PV_POMPE_A_CHALEUR,
    ROUTE_PV_PREVISION,
    ROUTE_PV_THERMODYNAMIQUE,
    ROUTE_PV_VALIDATION,
} from '../../../routing/paths';
import * as themesService from '../../calculs/theme';
import { themesStateAtom } from './Themes.atom';
import { isCurrentAuditMar } from '../../localStorageService';
import { prettyLog } from '../../tools/auditTools';
import { PackageData } from '../../calculs/package';

// Subthemes applicable to Previsit
export type PrevisitSubThemes = Exclude<themesService.ThemeOfEconomie, 'Isolation' | 'Changement comportemental'>;

// Typeguard to check if subtheme is applicable to Previsit
export const isSearchedSubThemesType = (candidate: string): candidate is PrevisitSubThemes => {
    if (!themesService.isSubThemeType(candidate)) return false;
    if (candidate === 'Isolation' || candidate === 'Changement comportemental') return false;
    return true;
};

type PackageRouter = typeof ROUTE_PV_VALIDATION | typeof ROUTE_PV_POMPE_A_CHALEUR | typeof ROUTE_PV_PHOTOVOLTAIQUE | typeof ROUTE_PV_THERMODYNAMIQUE;

const packageMap = new Map<PrevisitSubThemes, PackageRouter>([
    ['Photovoltaïque', ROUTE_PV_PHOTOVOLTAIQUE],
    ['Chauffage', ROUTE_PV_POMPE_A_CHALEUR],
    ['Eau chaude sanitaire', ROUTE_PV_THERMODYNAMIQUE],
]);

/** Dans l'atom themesStateAtom, extrait tous les packages  */
export const previsitContextPacksSelector = selector<Array<PackageData> | null>({
    key: 'previsitContextPacks',
    get: ({ get }) => {
        const themes = get(themesStateAtom);

        prettyLog('previsitContextPacksSelector themes', themes);

        const packs: Array<PackageData> = [];

        themes?.forEach((theme: themesService.Theme) => {
            // Prends que le thème économie d'énergie
            if (theme.themeType !== "Economie d'énergie") return;
            if (!theme.subThemes) return;

            theme.subThemes?.forEach((subTheme: themesService.SubTheme) => {
                // Prends que les sous thèmes "Photovoltaïque" | "Chauffage" | "Eau chaude sanitaire" |  "Ventilation"  | "Batterie" | "Porte et fenêtre"
                if (!isSearchedSubThemesType(subTheme.subThemeType)) return;
                // on prends tous les packages.
                subTheme.packages.forEach((pack: PackageData) => {
                    if (pack.applicable) packs.push(pack);
                });
            });
        });

        return packs;
    },
});

// stores current location in previsit
export const currentRouteAtom = atom<PackageRouter>({
    key: 'currentPackagesRoute',
    default: ROUTE_PV_VALIDATION,
});

// extracts only routes from packages
export const packageRoutesSelector = selector<Array<PackageRouter>>({
    key: 'packagesRoutes',
    get: ({ get }) => {
        const packages = get(previsitContextPacksSelector);

        const order = packages?.map((pack: PackageData) => {
            // le cast est OK, car previsitContextPacks est filtré par isSearchedSubThemesType, dans le code de l'atom
            const subTheme = pack.themeLb.split('/')[1] as PrevisitSubThemes;
            return packageMap.get(subTheme);
        });

        return [ROUTE_PV_VALIDATION, ...new Set(order as Array<PackageRouter>)];
    },
});

// Determines the next route
export const nextPackageRouteSelector = selector<PackageRouter>({
    key: 'nextPackagesRoute',
    get: ({ get }) => {
        const packagesElements = get(packageRoutesSelector);
        const current = get(currentRouteAtom);

        const key = packagesElements.findIndex((step) => step === current);

        let outOfBound = ROUTE_PV_PREVISION;
        if (isCurrentAuditMar()) outOfBound = ROUTE_PV_FINALISATION;

        return packagesElements[key + 1] ?? outOfBound;
    },
});
