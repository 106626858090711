// Style
import './ModalePvgisGraph.scss';
import { ReactComponent as IconClose } from '../../assets/icons/icon-close.svg';
import useOnClickOutside from '../../hook/useOnClickOutside';
import { useEffect, useRef, useState } from 'react';
import { Line, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, DotProps, LineChart } from 'recharts';
import * as pvgis from '../../services/pvgis/api';
import * as pvService from '../../services/calculs/calculPhotovoltaique';
import * as enedisService from '../../services/enedis/enedis';
import * as storageService from '../../services/localStorageService';
import { InstallationPV } from '../../services/calculs/installationPV';
import { ReactComponent as IconTriangle } from '../../assets/icons/icon-triangle.svg';
import * as pvgisService from '../../services/pvgis/service';
import CustomTooltip from './PvgisGraphToolType';

import { themesStateAtom } from '../../services/Recoil/Atom/Themes.atom';
import { useRecoilState } from 'recoil';
import { getFirstPackBattery } from '../../services/calculs/themesTools';
import { BornePeriode, GraphData, ScaledGraphData, getBorne } from '../../services/tools/graphHelper';

import { ReactComponent as LoaderSVG } from '../../assets/icons/loader.svg';

// READ ME !
// ici il se produit plusieurs problèmes :

// 1) divergences de plages de données.
//
//    enedis offre AU MAXIMUM, 2 ans d'historiques, jusqu'à aujourd'hui, typiquement
//    aujourd'hui c'est 3 novembre 2023, j'obtiendrai au mieux des données jusqu'au 2 novembre 2021.
//
//    pvgis offre des plage de données de une ou plusieurs années *complètes* mais maximum jusqu'en 2020.
//    typiquement du 01/01/2020 au 31/12/2020, 1 an,  (ou du 01/01/2010 au 31/12/2015 6 ans)
//
// 2) mettre en face les bonnes données
//
//    Idéalement il faudrait mettre la conso enedi de 03/03/2023  en face du 03/03/2023 de pvgis.
//    On ne peut pas car les plages des deux sources ne se recouvrent jamais.
//
//    Donc on prend jusqu'en 2020 pour pvgis. et sur le nombre d'années de enedis.
//    Donc on agrandit artificiellement la plage de enedis, pour couvrir du 01/01 au 31/12
//
// 3) On borne quand même
//
//    Pour éviter d'afficher des jours et des jours de zéro, on borne les graphiques
//    Les bornes sont calculées la présence de données enedis (vu que les données pvgis sont toujours complètes.)
//
//    dans notre exemple, le jour minimum est 02/11/2021, et le mois maximum est 11/2023. par exemple.

const periodNames = ['Jours', 'Mois', 'Annees'] as const;
type Period = (typeof periodNames)[number];
const uniteNames = ['Kwh', '€'] as const;
type Unite = (typeof uniteNames)[number];
type DataType = `${Period}${Unite}`;

export const XAXIS_NAME = 'line';
export type VisibleGraphEntries = keyof Omit<pvgisService.PvGraphDataEntry, 'label' | 'datakey'>;

type LineProsBase = {
    yAxisId: typeof XAXIS_NAME;
    type: 'monotone';
    activeDot: { onClick: (param: DotProps, event: React.MouseEvent<SVGCircleElement, MouseEvent>) => void };
};
type LinePropHelper<T extends VisibleGraphEntries> = LineProsBase & {
    dataKey: T;
    stroke: string;
    name: string;
};

type LineEventHelper<T extends Record<string, string | number | (() => string | number)>> = DotProps & {
    index: number;
    dataKey: keyof T;
    fill: string;
    strokeWidth: number;
    stroke: string;
    payload: T;
    value: T[keyof T];
};

interface ModaleGraphPVGisProps {
    topTitle?: string;
    closeTop?: boolean;
    title: string;
    close: () => void;
    //
    simuPv: pvService.SimulationPhotovoltaique | undefined;
}
type LineVisibilitiesProps = { [key in VisibleGraphEntries]: boolean };

// interface LegendClickEvent {
//     color?: string;
//     dataKey: string;
//     type?: string;
//     value: string;
// }

type PvGraphDataTotals = {
    consoEnedis: number;
    consoEnedisApres: number;
    consoEnedisApresBatterie: number;
    production: number;
    autoconsommation: number;
    revente: number;
    reventeAvecBatterie: number;
    chargeBatterie: number;
    restitutionBatterie: number;
};

interface GraphKeysMapping {
    [key: string]: string;
    consoEnedis: 'Consommation Enedis actuelle';
    consoEnedisApres: 'Consommation Enedis après installation';
    consoEnedisApresBatterie: '';
    production: 'Production photovoltaïque';
    autoconsommation: 'Autoconsommation';
    revente: 'Revente';
    reventeAvecBatterie: 'Revente avec batterie';
}

const ModalePvgisGraph: React.FC<ModaleGraphPVGisProps> = (props: ModaleGraphPVGisProps) => {
    const ref = useRef(null);
    const [themes] = useRecoilState(themesStateAtom);

    const [MaxChargeBatterie] = useState<number>(getFirstPackBattery(themes)?.mainProduct.puissance ?? 0);

    // LEs 3 jeux de données selon la période
    const [byDay, setByDay] = useState<Array<GraphData<pvgisService.PvGraphDataEntry>> | undefined>(undefined);
    const [byMonth, setByMonth] = useState<Array<GraphData<pvgisService.PvGraphDataEntry>> | undefined>(undefined);
    const [byYear, setByYear] = useState<Array<GraphData<pvgisService.PvGraphDataEntry>> | undefined>(undefined);
    // la période selectionnée
    const [selectedPeriod, setSelectedPeriod] = useState<Period>('Annees'); // Year

    // Les 3 bornes (min,max) pour chaque période
    const [borneByDay, setBorneByDay] = useState<BornePeriode>({ max: 0, min: 0 });
    const [borneByMonth, setBorneByMonth] = useState<BornePeriode>({ max: 0, min: 0 });
    const [borneByYear, setBorneByYear] = useState<BornePeriode>({ max: 0, min: 0 });
    // la borne selectionnée
    const [selectedBorne, setSelectedBorne] = useState<BornePeriode | undefined>(undefined);

    // l'index global (quelque soit le jeu de donnée)
    // il doit être compris entre selectedBorne.min et selectedBorne.max
    const [index, setIndex] = useState<number>(0);

    const [graphData, setGraphData] = useState<ScaledGraphData<pvgisService.PvGraphDataEntry> | undefined>(undefined);
    const [isGraphDataReady, setIsGraphDataReady] = useState<boolean>(false);
    const [lineHides, setLineVisibilities] = useState<LineVisibilitiesProps>({
        consoEnedis: false,
        production: false,
        autoconsommation: false,
        revente: false,
        reventeAvecBatterie: false,
        consoEnedisApres: true,
        consoEnedisApresBatterie: false,
        chargeBatterie: false,
        restitutionBatterie: false,
    });

    //#region changement d'échelle de zoom sur les données,
    // par le bouton de selecteur, ou le bar graph

    const setIndexHelper = (targetBorne: BornePeriode, targetIndex: number | undefined): void => {
        let finalIndex;

        if (targetIndex === undefined) {
            //Si pas d'index cible, on décide d'afficher la borne max. et pourquoi pas ?!
            finalIndex = targetBorne.max;
        } else {
            finalIndex = targetIndex;
            if (targetIndex > targetBorne.max) finalIndex = targetBorne.max;
            if (targetIndex < targetBorne.min) finalIndex = targetBorne.min;
        }
        setIndex(finalIndex);
    };

    const setPeriodAndIndexHelper = (period: Period, index: number | undefined): void => {
        let borne;

        switch (period) {
            case 'Annees':
                borne = borneByYear;
                break;
            case 'Mois':
                borne = borneByMonth;
                break;
            case 'Jours':
                borne = borneByDay;
                break;
        }
        setIndexHelper(borne, index);
        setSelectedPeriod(period);
        setSelectedBorne(borne);
    };

    // Switch to day
    const handleDay = () => {
        const targetPeriod = 'Jours';
        if (selectedPeriod === 'Mois') {
            // Je regarde un mois et j'ai clqiué sur jour.
            // je veux le premier jour de ce mois
            const data = getSelectedDatas();
            if (data && byDay) {
                const searched = '01 ' + data?.label;
                const anotherIndex: number = byDay.findIndex((day) => day.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        if (selectedPeriod === 'Annees') {
            // Je regarde une année et j'ai cliqué sur jour.
            // je veux le premier jour de cette année
            const data = getSelectedDatas();
            if (data && byDay) {
                const searched = '01 janvier' + data?.label;
                const anotherIndex: number = byDay.findIndex((day) => day.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        setPeriodAndIndexHelper(targetPeriod, byDay ? byDay.length - 1 : 0);
    };

    // Switch to Month
    const handleMonth = () => {
        const targetPeriod = 'Mois';
        if (selectedPeriod === 'Jours') {
            // Je regarde un jour et j'ai cliqué sur mois.
            // je veux le mois qui contient ce jour.
            const data = getSelectedDatas();
            if (data && byMonth) {
                const elements = data?.label.split(' ');
                const searched = elements[1] + ' ' + elements[2];
                const anotherIndex: number = byMonth.findIndex((month) => month.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        if (selectedPeriod === 'Annees') {
            // Je regarde une année et j'ai cliqué sur mois.
            // je veux le mois de janvier de cette année.
            const data = getSelectedDatas();
            if (data && byMonth) {
                const searched = 'janvier ' + data.label;
                const anotherIndex = byMonth.findIndex((month) => month.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }

        // en dernier recours, on choisit un index, le dernier ou le 0.
        setPeriodAndIndexHelper(targetPeriod, byMonth ? byMonth.length - 1 : 0);
    };

    // Switch to Year
    const handleYear = () => {
        const targetPeriod = 'Annees';
        if (selectedPeriod === 'Jours') {
            // Je regarde un jour et j'ai cliqué sur années.
            // je veux l'année qui contient ce jour.
            const data = getSelectedDatas();
            if (data && byYear) {
                const elements = data?.label.split(' ');
                const anotherIndex: number = byYear.findIndex((year) => year.label === elements[2]);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        if (selectedPeriod === 'Mois') {
            // Je regarde un mois et j'ai cliqué sur années.
            // je veux l'année qui contient ce mois.
            const data = getSelectedDatas();
            if (data && byYear) {
                const elements = data?.label.split(' ');
                const anotherIndex: number = byYear.findIndex((year) => year.label === elements[1]);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        setPeriodAndIndexHelper(targetPeriod, byYear ? byYear.length - 1 : 0);
    };

    const handleDotClick = (param: DotProps, event: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
        const myLineEvent = event as unknown as LineEventHelper<pvgisService.PvGraphDataEntry>;
        selectEntry(myLineEvent.payload);
    };
    //const handleBarClick = (data: any, index: number) => {
    const selectEntry = (entry: pvgisService.PvGraphDataEntry) => {
        // `data` contient les données associées à la barre cliquée
        // `index` est l'indice de la barre dans le tableau de données

        // On ne click pas sur une barre d'heure quand on affiche un jour.
        if (selectedPeriod === 'Jours') return;

        const periodData = getSelectedDatas();

        if (selectedPeriod === 'Annees') {
            // searched = "month year"
            const searched = entry?.label + ' ' + periodData?.label;
            const newIndex = byMonth?.findIndex((month) => {
                const found = month.label === searched;
                return found;
            });

            setPeriodAndIndexHelper('Mois', newIndex);
            return;
        }
        if (selectedPeriod === 'Mois') {
            // searched = "month year"
            const searched = entry?.label.split(' ')[0] + ' ' + periodData?.label;
            const newIndex = byDay?.findIndex((day) => day.label === searched);
            setPeriodAndIndexHelper('Jours', newIndex);
            return;
        }
    };

    // Year / Month + 1
    const incrementGraphDataIndex = () => {
        setIndexHelper(selectedBorne!, index + 1);
    };

    // Year / Month - 1
    const decrementGraphDataIndex = () => {
        setIndexHelper(selectedBorne!, index - 1);
    };

    //#endregion

    /** return all data from one type (month / year) */
    const getSelectedDataSet = (): Array<GraphData<pvgisService.PvGraphDataEntry>> | undefined => {
        const selectedDataType: DataType = `${selectedPeriod}Kwh`;
        switch (selectedDataType) {
            case 'JoursKwh':
                return byDay;
            case 'MoisKwh':
                return byMonth;
            case 'AnneesKwh':
                return byYear;

            default:
                return undefined;
        }
    };

    /** return one entry of data (on month, or one year) */
    const getSelectedDatas = (): GraphData<pvgisService.PvGraphDataEntry> | undefined => {
        let data = getSelectedDataSet();
        if (!data) return undefined;
        if (index >= data.length) return undefined;
        return data[index];
    };

    const loadPvgis = async (installation: InstallationPV | undefined, yearCount: number): Promise<Array<GraphData<pvgisService.PvGraphShortDataEntry>>> => {
        const audit = storageService.stepListToAuditAndClient().audit;

        const endyear = 2020; //la dernière années disponible dans la DB Pvgis.
        const startyear = 2020 - yearCount + 1; // Pour avoir le même nombre d'années que dans le graph pvgis.

        const input: pvgis.SeriesCalcParams = {
            outputformat: 'json',
            lat: audit.userAddressLatitude?.value,
            lon: audit.userAddressLongitude?.value,

            usehorizon: '1',
            //userhorizon:1,
            raddatabase: 'PVGIS-SARAH3',
            startyear,
            endyear,

            pvcalculation: '1',
            peakpower: installation && installation.panneau ? installation.panneau.puissance / 1000 : 0,
            mountingplace: 'building',
            pvtechchoice: 'crystSi',
            loss: installation && installation.panneau ? (1 - installation.panneau.efficacite) * 100 : 0,
            trackingtype: 0,
            angle: installation && installation.site ? installation.site.inclinaison : 0,
            aspect: pvgis.toAzimut(installation && installation.site ? installation.site.orientation : ''),
            optimalinclination: '1',
            optimalangles: '1',
            components: '0',
        };

        return await pvgisService.getMyPvgisSerie(input);
    };

    useEffect(() => {
        const enedisData: enedisService.GroupedMesuresDetaillees = storageService.getAudit().step4.enedisMesureDetaillee.value;

        loadPvgis(props.simuPv?.installation, enedisData.byYear.length).then((groupedPvgisSeries) => {
            const graphData = pvgisService.buildGraphData(props.simuPv?.installation!, groupedPvgisSeries, enedisData, MaxChargeBatterie);
            if (graphData) {
                setGraphData(graphData);
                setByYear(graphData.byYear);
                setByMonth(graphData.byMonth);
                setByDay(graphData.byDay);

                //chercher les premières/dernières données non nulles.
                const bby = getBorne(enedisData.byYear);
                setBorneByYear(bby);
                setBorneByMonth(getBorne(enedisData.byMonth));
                setBorneByDay(getBorne(enedisData.byDay));
                setSelectedBorne(bby);

                setIsGraphDataReady(true);
                setIndex(graphData.byYear.length - 1);
                // 471
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOutsite = () => {
        props.close();
    };

    useOnClickOutside(ref, handleClickOutsite);

    const handleLegendClick = (e: any) => {
        const { dataKey } = e;
        setLineVisibilities((prevState) => ({
            ...prevState,
            [dataKey]: dataKey === 'dummyForBarGraph' ? true : !prevState[dataKey as keyof LineVisibilitiesProps],
        }));
    };

    const nextButtonText = (period: Period) => {
        switch (period) {
            case 'Jours':
                return 'Jour suivant';

            case 'Mois':
                return 'Mois suivant';

            case 'Annees':
                return 'Année suivante';
        }
    };

    const previousButtonText = (period: Period) => {
        switch (period) {
            case 'Jours':
                return 'Jour précédent';

            case 'Mois':
                return 'Mois précédent';

            case 'Annees':
                return 'Année précédente';
        }
    };
    const baseLineProp: LineProsBase = { yAxisId: 'line', type: 'monotone', activeDot: { onClick: handleDotClick } };

    const lineProp: { [key in VisibleGraphEntries]: LinePropHelper<key> } = {
        consoEnedis: { ...baseLineProp, dataKey: 'consoEnedis', stroke: '#0072ff', name: 'Consommation Enedis actuelle' },
        consoEnedisApres: { ...baseLineProp, dataKey: 'consoEnedisApres', stroke: '#003399', name: 'Consommation Enedis après installation' },
        consoEnedisApresBatterie: { ...baseLineProp, dataKey: 'consoEnedisApresBatterie', stroke: '#003399', name: 'Conso Enedis après installation' },
        production: { ...baseLineProp, dataKey: 'production', stroke: '#FFC107', name: 'Production photovoltaïque' },
        autoconsommation: { ...baseLineProp, dataKey: 'autoconsommation', stroke: '#82ca9d', name: 'Autoconsommation' },
        revente: { ...baseLineProp, dataKey: 'revente', stroke: '#888888', name: 'Revente' },
        reventeAvecBatterie: { ...baseLineProp, dataKey: 'reventeAvecBatterie', stroke: '#AAAAAA', name: 'Revente' },
        chargeBatterie: { ...baseLineProp, dataKey: 'chargeBatterie', stroke: '#DD0000', name: 'Charge batterie' },
        restitutionBatterie: { ...baseLineProp, dataKey: 'restitutionBatterie', stroke: '#DD0000', name: 'Restitution de la batterie' },
    };

    const calculateTotals = (data: pvgisService.PvGraphDataEntry[] | undefined): { key: string; value: number }[] | null => {
        if (!data) return null;

        const initialTotals: PvGraphDataTotals = {
            consoEnedis: 0,
            consoEnedisApres: 0,
            consoEnedisApresBatterie: 0,
            production: 0,
            autoconsommation: 0,
            revente: 0,
            reventeAvecBatterie: 0,
            chargeBatterie: 0,
            restitutionBatterie: 0,
        };

        const totals = data.reduce((acc, entry) => {
            acc.consoEnedis += entry.consoEnedis;
            acc.consoEnedisApres += entry.consoEnedisApres;
            acc.consoEnedisApresBatterie += entry.consoEnedisApresBatterie;
            acc.production += entry.production;
            acc.autoconsommation += entry.autoconsommation;
            acc.revente += entry.revente;
            acc.reventeAvecBatterie += entry.reventeAvecBatterie;
            acc.chargeBatterie += entry.chargeBatterie;
            acc.restitutionBatterie += entry.restitutionBatterie;
            return acc;
        }, initialTotals);

        return Object.keys(totals).map((key) => ({
            key,
            value: parseFloat(totals[key as keyof PvGraphDataTotals].toFixed(2)),
        }));
    };

    const totals = calculateTotals(getSelectedDatas()?.data);

    const keyMappings: GraphKeysMapping = {
        consoEnedis: 'Consommation Enedis actuelle',
        consoEnedisApres: 'Consommation Enedis après installation',
        consoEnedisApresBatterie: '',
        production: 'Production photovoltaïque',
        autoconsommation: 'Autoconsommation',
        revente: 'Revente',
        reventeAvecBatterie: 'Revente avec batterie',
        restitutionBatterie: 'Restitution de la batterie',
    };

    const translatePeriod = (period: string) => {
        switch (period) {
            case 'Annees':
                return 'annuels';
            case 'Mois':
                return 'mensuels';
            case 'Jours':
                return 'journaliers';
            default:
                return '';
        }
    };

    const keysToExclude = ['consoEnedisApresBatterie', 'reventeAvecBatterie'];

    return (
        <div className="modal modal-custom modal-custom-mh modal-pvgis" tabIndex={0} role="dialog" aria-labelledby={props.title}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" ref={ref}>
                    {props.topTitle && <p className="modal-top-title">{props.topTitle}</p>}

                    {props.closeTop && (
                        <button type="button" className="btn btn-modal-close" onClick={handleClickOutsite}>
                            <IconClose fill="#FD5C21" />
                        </button>
                    )}
                    <div className="modal-header">
                        <h2 className="modal-title sr-only">{props.title}</h2>
                    </div>

                    {isGraphDataReady && graphData ? (
                        <>
                            <div className="modal-body">
                                <div className="row align-items-center">
                                    <div className="col-6 col-md-1">
                                        {selectedBorne && (
                                            <button
                                                type="button"
                                                className="btn btn-graph-prev"
                                                onClick={decrementGraphDataIndex}
                                                disabled={index <= selectedBorne.min || selectedBorne.min === selectedBorne.max}
                                            >
                                                <div className="triangle" data-direction="prev">
                                                    <IconTriangle />
                                                </div>
                                                {previousButtonText(selectedPeriod)}
                                            </button>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="graph-current-title">
                                            {getSelectedDatas()?.label}
                                            {/* {+ ' - ' + powerFormat(getSelectedDatas()?.total) + ' kWh'} */}
                                        </div>
                                        <ResponsiveContainer width="99%" height={400}>
                                            <LineChart width={800} height={400} data={getSelectedDatas()?.data}>
                                                {/* <ComposedChart width={730} height={250} data={getSelectedDatas()?.data}> */}
                                                <XAxis dataKey="label" tick={{ fill: '#333' }} width={100} />
                                                <YAxis yAxisId={XAXIS_NAME} label={{ value: 'kWh', angle: -90, position: 'insideLeft', fill: '#666' }} />
                                                <YAxis yAxisId="bar" hide={true} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip
                                                    content={<CustomTooltip selectedUnite={'kWh'} />}
                                                    cursor={{ fill: 'rgba(225, 225, 225, 0.5)' }}
                                                    wrapperStyle={{ outline: 'none' }}
                                                />{' '}
                                                <Legend onClick={(e) => handleLegendClick(e)} />
                                                <Line {...lineProp['consoEnedis']} hide={lineHides.consoEnedis} />
                                                <Line {...lineProp['production']} hide={lineHides.production} />
                                                <Line {...lineProp['autoconsommation']} hide={lineHides.autoconsommation} />
                                                {/* Si on a PAS de batterie, max charge à zero  */}
                                                {MaxChargeBatterie === 0 ? (
                                                    <>
                                                        <Line {...lineProp['consoEnedisApres']} hide={lineHides.consoEnedisApres} />
                                                        <Line {...lineProp['revente']} hide={!lineHides.revente} />
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* Si on a une batterie */}
                                                        <Line {...lineProp['consoEnedisApresBatterie']} hide={lineHides.consoEnedisApresBatterie} />
                                                        <Line {...lineProp['reventeAvecBatterie']} hide={lineHides.reventeAvecBatterie} />
                                                        {/* Selon le niveau de zoom, on affiche différement.  */}
                                                        {selectedPeriod === 'Jours' ? (
                                                            <Line {...lineProp['chargeBatterie']} hide={lineHides.chargeBatterie} />
                                                        ) : (
                                                            <Line {...lineProp['restitutionBatterie']} hide={lineHides.restitutionBatterie} />
                                                        )}
                                                    </>
                                                )}
                                                {/* {selectedPeriod === 'Mois' ? (
                                            <Bar yAxisId="bar" dataKey="dummyForBarGraph" fill="#63a600" opacity={0} onClick={handleBarClick} />
                                        ) : selectedPeriod === 'Annees' ? (
                                            <Bar yAxisId="bar" dataKey="dummyForBarGraph" fill="#0063a6" opacity={0} onClick={handleBarClick} />
                                        ) : (
                                            <></>
                                        )} */}
                                                {/* </ComposedChart> */}
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div className="col-6 col-md-1">
                                        {selectedBorne && (
                                            <button
                                                type="button"
                                                className="btn btn-graph-next"
                                                onClick={incrementGraphDataIndex}
                                                disabled={index >= selectedBorne.max || selectedBorne.min === selectedBorne.max}
                                            >
                                                <div className="triangle" data-direction="next">
                                                    <IconTriangle />
                                                </div>
                                                {nextButtonText(selectedPeriod)}
                                            </button>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="modal-content-list">
                                            <h2>Totaux {translatePeriod(selectedPeriod)}</h2>

                                            <ul>
                                                {totals &&
                                                    totals
                                                        .filter((item) => !keysToExclude.includes(item.key))
                                                        .map((item, index) => {
                                                            // Purge unused items
                                                            if (item.value === 0) return null;

                                                            return (
                                                                <li key={index}>
                                                                    <p data-key={item.key}>
                                                                        <span>{keyMappings[item.key] || item.key}:</span> {item.value} kWh
                                                                    </p>
                                                                </li>
                                                            );
                                                        })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col col-md-9">
                                        <div className="header-filter justify-content-center">
                                            <div className="btn-tabs">
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={handleDay}
                                                    data-active={selectedPeriod === 'Jours'}
                                                    disabled={selectedPeriod === 'Jours'}
                                                >
                                                    Jours
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={handleMonth}
                                                    data-active={selectedPeriod === 'Mois'}
                                                    disabled={selectedPeriod === 'Mois'}
                                                >
                                                    Mois
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={handleYear}
                                                    data-active={selectedPeriod === 'Annees'}
                                                    disabled={selectedPeriod === 'Annees'}
                                                >
                                                    Années
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="modal-loader text-center">
                            <LoaderSVG width={80} height={80} />
                            <p className="mb-0">
                                Mise à jour de vos données de consommation.
                                <br />
                                Cette action peut durer plusieurs secondes, merci de patienter.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default ModalePvgisGraph;
