import React, { useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import * as Styles from './selectStyles';
import { CONSTANTES_PHOTOVOLTAIQUES } from '../../services/localStorageService';

import { modeConsommationList } from './dataHelper';
import { currencyFormat, Labelled } from '../../services/tools/TypeHelper';
import * as ticketService from '../../services/calculs/ticket';

import { ReactComponent as IconLightning } from '../../assets/icons/synthese/icon-lightning.svg';
import { packIsPV, PackageData } from '../../services/calculs/package';
import { useRecoilState } from 'recoil';
import { AidesLocalesBuilder, updateTicket } from '../../pages/audit-simulator/ticketUpdater';
import * as pvService from '../../services/calculs/calculPhotovoltaique';
import { caculSimulationComplete } from '../../services/calculs/simulationComplete';
import * as themesService from '../../services/calculs/theme';
import { TicketData } from '../../services/calculs/ticket';
import { aidesLocalesListStateAtom, themesStateAtom, ticketStateAtom } from '../../services/Recoil/Atom/Themes.atom';
import { InstallationPV, dummyInstallationPV, setModeConsommation } from '../../services/calculs/installationPV';
import { calculProjectionFinanciereGlobale, ProjectionFinanciere } from '../../services/calculs/applyPreconisation';
import { DUREE_PROJETCTION_FINANCIERE } from '../../services/calculs/calculEconomie';
import { DepenseParPoste } from '../../services/calculs/bilanEnergieService';
import DivDebug from '../DivDebug/DivDebug';
// import { SHOW_DEBUG } from '../../services/tools/generalTools';
// import { prettyLog } from '../../services/tools/auditTools';

const autoconsoRatioList: Array<Labelled<number>> = (() => {
    const result = Array<Labelled<number>>();
    for (let i = 0; i <= 100; i += 5) result.push({ label: i.toString() + '%', value: i / 100.0 });
    return result;
})();

// 3 mode de consommation PV :
//
// -  autoconsommation à 70% (le surplus sera perdu, pas de revente, aucune aide.)
// -  revente du surplus : pourcentage variable. (on autoconsomme un seuil définit par l'utilisateur, on revent le reste (si il y en a), on bénéficie d'une aide.)
// -  revente totale : == revente du surplus à 0% (on revend tout, pas d'aide).

const ModeProductionPVContainerComponent: React.FC<PackageData> = (card: React.PropsWithChildren<PackageData>) => {
    // les states globaux, ici, on ne fait que d ela lecture, donc pas de sets.
    const [themes, setThemes] = useRecoilState(themesStateAtom);
    const [ticket, setTicket] = useRecoilState(ticketStateAtom);
    const [aidesLocales, setAidesLocales] = useRecoilState(aidesLocalesListStateAtom);

    // Les states de l'écran.
    const [tauxAutoconsoSelectorVisible, setTauxAutoconsoSelectorVisible] = useState(true);

    // pour le menu déroulant de taux d'autoconsommation
    const defaultGain = card.mainProduct.gain ?? CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C;
    const defaultAutoConsoSelection =
        autoconsoRatioList.find((e) => {
            return e.value === defaultGain;
        }) ?? autoconsoRatioList[6];

    // calcul des caractéristiques du panneau selectionné.
    // panneau, peut être undefined si aucun package panneau solaire n'est selectionné.
    // Les calculs suivants en tiennent compte.
    // const panneau: pvService.PanneauPhotovoltaique = pvService.getPanneauFromProduct(card.mainProduct);
    let installation: InstallationPV = dummyInstallationPV();
    if (packIsPV(card)) {
        installation = card.installationPV;
    }

    const { depenseInitiale, depenseFinaleApresTravaux, bilanPv, simuPv } = caculSimulationComplete(themes, installation); //, panneau);

    let currentProjectionFinanciere: ProjectionFinanciere = {
        economieHorsPV: new DepenseParPoste(),
        economiePV: { autoconsomation: 0, revente: 0, total: 0 },
        projectionsAnnuelles: [],
        rendement: 0,
    };
    // normalement ici, comme on a un panneau solaire, donc on a simuPv, et bilanPv non undefined.
    // Mais typescript fait chier alors on élimine ce cas dans le code.
    if (simuPv !== undefined && bilanPv !== undefined) {
        currentProjectionFinanciere = calculProjectionFinanciereGlobale(
            depenseInitiale,
            depenseFinaleApresTravaux,
            simuPv,
            bilanPv,
            11382,
            DUREE_PROJETCTION_FINANCIERE
        );
    } else {
        console.log('something run very very very bad, in ModeProductionPVContainerComponent.');
    }

    // if (SHOW_DEBUG && card.applicable) {
    //     console.groupCollapsed('DETAIL PV CONTAINER ' + card.reference);

    //     prettyLog('depenseInitiale ', depenseInitiale);
    //     prettyLog('depenseFinaleApresTravaux ', depenseFinaleApresTravaux);
    //     prettyLog('bilanPv ', bilanPv);
    //     prettyLog('simuPv', simuPv);

    //     console.groupEnd();
    // }

    // gère le changement du menu déroulant du mode (autoconso, revente totale, revente en surplus)
    const modeChanged = (selection: Labelled<pvService.ModeConsommation> | null, action: ActionMeta<Labelled<pvService.ModeConsommation>>): void => {
        // Could not happen
        if (selection == null) return;

        setTauxAutoconsoSelectorVisible(selection.value === 'ReventeSurplus');

        // MAIS, en plus => si revente totale : 0% d'autoconso
        if (selection.value === 'ReventeTotale') {
            setAutoConsoOfThisCard(selection.value, undefined);
        }
        if (selection.value === 'ReventeSurplus') {
            // set le taux si il existe, ou défaut

            setAutoConsoOfThisCard(selection.value, card.mainProduct.gain);
        }
        if (selection.value === 'Autoconsommation') {
            setAutoConsoOfThisCard(selection.value, CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C); // on met le taux d'autoconsommation (constant)
        }
    };

    const setAutoConsoOfThisCard = (mode: pvService.ModeConsommation, tauxAutoconso?: number): void => {
        // Je dois faire un set du gain ici, avec le taux d'autoconsomation :
        // je clone mon theme, je le parcours, je set la valeur du gain, puis je set le theme avec le clone.
        // const themeClone = JSON.parse(JSON.stringify(themes)) as Array<Theme>;
        const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;

        for (const theme of allThemesClone) {
            const allSubThemesClone = theme.subThemes!;
            for (const subTheme of allSubThemesClone) {
                for (const pack of subTheme.packages) {
                    if (pack.uuid === card.uuid) {
                        if (tauxAutoconso !== undefined) pack.mainProduct.gain = tauxAutoconso;
                        if (packIsPV(pack)) {
                            setModeConsommation(pack.installationPV, mode, tauxAutoconso);
                        }
                    }
                }
            }
        }
        // NO : setThemes(themeClone);
        // updateTicket will do it _after_  changes
        // force le re-render de l'ecran parent
        const ticketClone: TicketData = JSON.parse(JSON.stringify(ticket)) as TicketData;

        const aideBuilder: AidesLocalesBuilder = async (
            aidesLocales: ticketService.AidesLocalesList,
            allThemesClone: Array<themesService.Theme>,
            ticket: ticketService.TicketData
        ): Promise<ticketService.AidesLocalesList> => {
            // On recalcule les aides locales liées aux packages.
            const aides: ticketService.AidesLocalesList = {
                ...aidesLocales,
                [ticketService.AIDE_PANNEAU_SOLAIRE_NAME]: await ticketService.createAideReventeSurplus(allThemesClone),
            };
            return aides;
        };
        // On vient de changer le mode de consommation.
        // Ca change le montant de l'aide lié à ce package PV. et la valeur tu total avec aide et du reste à charge.
        // MAIS, ca ne change pas le montant du devis icoll
        // => pas besoin de refaire la simu du ticket !
        // => pas besoin de recalculer l'aide renoovation d'ampleur.
        // (La fonction update ticket n'appellera pas l'api si on lui passe le ticket en parametre)
        updateTicket(allThemesClone, setThemes, ticketClone, setTicket, aidesLocales, setAidesLocales, aideBuilder);
    };
    // gère le changement du menu déroulant de pourcentage d'autoconos
    const tauxAutoconsoChanged = (selection: Labelled<number> | null, action: ActionMeta<Labelled<number>>): void => {
        let result = CONSTANTES_PHOTOVOLTAIQUES.tauxAutoconsommationMax_B2C;

        if (selection) result = +selection.value; // can be 0 it is ok.

        setAutoConsoOfThisCard('ReventeSurplus', result);
    };

    return (
        <div className="card-col-body--production">
            <h5>
                Mode de production
                <IconLightning width="24" height="24" />
            </h5>

            <Select
                options={modeConsommationList}
                defaultValue={modeConsommationList[1]}
                styles={card.applicable ? Styles.panelStylesActive : Styles.panelStyles}
                isClearable={false}
                isSearchable={false}
                placeholder="Autoconsommation"
                className="mb-2"
                onChange={modeChanged}
            />

            <ul className="card-col-production--list">
                {tauxAutoconsoSelectorVisible && (
                    <React.Fragment key="cdda0c0e-d798-46f7-934c-22eb15c0eeb8">
                        <li>
                            Partie produite autoconsommée
                            <span>
                                <Select
                                    options={autoconsoRatioList}
                                    defaultValue={defaultAutoConsoSelection}
                                    isClearable={false}
                                    isSearchable={false}
                                    styles={card.applicable ? Styles.miniPanelStylesActive : Styles.miniPanelStyles}
                                    onChange={tauxAutoconsoChanged}
                                />
                            </span>
                        </li>
                    </React.Fragment>
                )}
                {/* 
                    <li>TOTAL A SUPPRIMER</li>
                    <li>{currencyFormat(economie.totalHorsInstallation)}</li>
                */}
                <li>
                    Autoconso./25ans<span>{currencyFormat(currentProjectionFinanciere.economiePV.autoconsomation)}</span>
                </li>
                <li>
                    Revente sur 20ans<span>{currencyFormat(currentProjectionFinanciere.economiePV.revente)}</span>
                </li>
                <li>
                    Total sur 25 ans<span>{currencyFormat(currentProjectionFinanciere.economiePV.total)}</span>
                </li>
                <li>
                    Autoconso. 1ere année<span>{currencyFormat(bilanPv!.autoconsomationGain)}</span>
                </li>
                <li>
                    Revente 1ere année<span>{currencyFormat(bilanPv!.reventeGain)}</span>
                </li>
                <li>
                    Moyenne annuelle<span>{currencyFormat(currentProjectionFinanciere.economiePV.total / DUREE_PROJETCTION_FINANCIERE)}</span>
                </li>
                {simuPv !== undefined && (
                    <>
                        <li>
                            Production annuelle<span>{Math.round(simuPv?.maxProd()!) + ' kWh'}</span>
                        </li>
                        <DivDebug>
                            <li>
                                auto/revente max
                                <span>{Math.round(simuPv?.maxProd()! * 0.1582) + ' / ' + Math.round(simuPv?.maxProd()! * 0.1) + ' €'}</span>
                            </li>
                        </DivDebug>
                    </>
                )}
            </ul>
        </div>
    );
};

export default ModeProductionPVContainerComponent;
