import { ReactComponent as IconEye } from '../../assets/icons/espace-agent/icon-eye.svg';
import { ReactComponent as IconDownload } from '../../assets/icons/espace-agent/icon-download.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DocumentsViewer } from './DocumentsViewer';
import { DocumentEntry, downloadableDocs, viewableDocs } from './types';

// Style

const DocumentButton: React.FC<DocumentEntry> = (props: DocumentEntry) => {
    const AlertSwal = withReactContent(Swal);
    // Initialiser l'icône avec les props nécessaires

    const showDoc = (doc: DocumentEntry) => {
        // Prevent document title null
        if (!doc || !props.title || !props.url) return;

        AlertSwal.fire({
            title: '',
            html: <DocumentsViewer doc={doc} />,
            width: 900,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
        });
    };

    const downloadDoc = (doc: DocumentEntry) => {
        if (!doc) return;

        // Create dynamic <a> element
        const link = document.createElement('a');
        link.href = props.url;
        //link.download = props.file.fileName; // Nom de fichier souhaité
        link.download = props.title; // Nom de fichier souhaité

        document.body.appendChild(link);
        link.click();

        // Destroy element after download
        document.body.removeChild(link);
    };

    const btnClassName = 'btn btn-orange btn-orange--icon btn-orange--extension w-100';
    if (viewableDocs.includes(props.extension)) {
        return (
            <button type="button" className={btnClassName} onClick={() => showDoc(props)}>
                <>
                    <IconEye width={24} fill="#FFF" />
                    <span>.{props.extension}</span>
                </>
                {props.comment !== props.title && props.comment}
                {props.title}
            </button>
        );
    } else if (downloadableDocs.includes(props.extension)) {
        return (
            <button type="button" className={btnClassName} onClick={() => downloadDoc(props)}>
                <>
                    <IconDownload fill="#FFF" />
                    <span>.{props.extension}</span>
                </>
                {props.title}
            </button>
        );
    } else {
        return (
            <button type="button" className={btnClassName}>
                {props.comment}
                <span style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.title}</span>
            </button>
        );
    }
};

export default DocumentButton;
