import Cookies from 'js-cookie';
import { colors } from '../pages/pre-visite/photovoltaique/Photovoltaique';
import { ConstantesPhotovoltaique, CustomerFlowParameters, FinancoScale, FlowFundingOutput, InvestmentBonus } from './apiParticulierService';
import { AppointmentOutput, AuditType, isAuditMar, isAuditThermique, isAuditType } from './apiFlowService';
import { EnergyPrice } from './calculs/bilanEnergieService';
import { Ensoleillement, Orientation } from './calculs/installationPV';
import { Indexations } from './calculs/calculEconomie';
import { PreconisationChauffage } from './calculs/calculPreconisationsCore';
import { Labelled } from './tools/TypeHelper';
import { picturesNames } from './tools/auditTools';

export enum PropertyType {
    boolean,
    basic,
    number,
    withLabel,
    building,
    window,
    photo,
    buildingDetail,
    dependentDetail,
    anexe,
    typeOfWork,
    workCheckboxes,
    heatingCircuit,
    radiatorCircuit,
    date,
    roofShape,
    loan,
    array,
}

export type Agent = {
    agentCode: string;
    agentPassword: string;
    nom: string;
    nom_agent: string;
    prenom_agent: string;
    email: string;
    tel: string;
    companyName: string;
    companyAddress: string;
    companyLogo: string;
    e_attestation: boolean;
    code_fournisseur: string;
    campagne?: Campagne;
};

export type ClientIdentity = {
    userCivility: string;
    userLastname: string;
    userFirstname: string;
    userAddress: string;
    userZipCode: string;
    userLocality: string;
    userTelephone: string;
    userEmail: string;
    signature: string;
};

// #region rustine de retro compatibilité :

/**
 * Fonction permettant de copier les types de plafond et de sol de l'étape 6 à l'étape 2
 * @param audit (audit)
 * @returns (audit)
 */
export const copyCeilingTypeAndFloorTypeFromStep6ToStep2 = (audit: any) => {
    if (!audit) return audit;
    if (audit.step2 && !audit.step2.ceilingType && audit.step6 && audit.step6.ceilingType) {
        audit.step2.ceilingType = JSON.parse(JSON.stringify(audit.step6.ceilingType));
    }
    if (audit.step2 && !audit.step2.floorType && audit.step6 && audit.step6.floorType) {
        audit.step2.floorType = JSON.parse(JSON.stringify(audit.step6.floorType));
    }
    return audit;
};

// #endregion

// TODO remove this
const setLocalStorage = (name: string, value: any) => {
    if (localStorage.getItem(name)) {
        return localStorage.setItem(name, value);
    }
    return false;
};

export const getCurrentAgent = (): Agent => {
    const agent = Cookies.getJSON('Auth');
    return agent as Agent;
};

export const getCurrentAgentCode = (): string | undefined => {
    const agent = getCurrentAgent();
    return agent?.agentCode;
};
export const getClientIdentity = (): ClientIdentity => {
    const audit = getAudit();
    const step1 = audit?.step1!;
    return {
        userCivility: step1.userCivility?.value,
        userLastname: step1.userLastname?.value,
        userFirstname: step1.userFirstname?.value,
        userAddress: step1.userAddress?.value,
        userZipCode: step1.userZipCode?.value,
        userLocality: step1.userLocality?.value,
        userTelephone: step1.userTelephone?.value,
        userEmail: step1.userEmail?.value,
        signature: 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=',
    };
};

// #region get / set / reset  audit -PV -SIM (full)

// TODO :
// const stepsList = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8'] as const;
// export type StepList = (typeof stepsList)[number];

// export type Audit = {
//     [key in StepList]: any;
// } & {
//     auditSent: boolean;
//     agentCode: string;
//     currentStep: number;
//     campagne: string;
// };

/**
 * Fonction pour vérifier la présence de l'audit
 */
export const checkAudit = (): boolean => {
    const audit = getAudit();
    return audit !== null && audit !== undefined && Object.keys(audit).length > 0;
};
/**
 * Fonction pour récupérer l'audit de l'agent courant, dans le local storage.
 */
export const getAudit = () => {
    const agent = getCurrentAgent();
    if (!agent) return undefined;
    const agentCode = agent.agentCode;
    const strignifiedAudit = localStorage.getItem(agentCode);
    if (strignifiedAudit === null || strignifiedAudit === '') return undefined;
    const audit = JSON.parse(strignifiedAudit);
    if (Object.keys(audit).length === 0) return undefined;
    return audit;
};

/**
 * Fonction pour récupérer le type de l'audit, dans le local storage.
 */
export const getCurrentAuditType = (): AuditType | undefined => {
    const auditType = localStorage.getItem('auditType');
    return isAuditType(auditType) ? (auditType as AuditType) : undefined;
};
/**
 * Fonction pour déterminer si l'audit est de type MAR
 */
export const isCurrentAuditMar = (): boolean => {
    const auditType = localStorage.getItem('auditType');
    return isAuditMar(auditType);
};
/**
 * Fonction pour déterminer si l'audit est de type Thermique
 */
export const isCurrentAuditThermique = (): boolean => {
    const auditType = localStorage.getItem('auditType');
    return isAuditThermique(auditType);
};
/**
 * Fonction pour récupérer la simulation ("-SIM") dans le local storage
 */
export const getSim = () => {
    const agent = getCurrentAgent();
    if (!agent) return undefined;
    const agentCodeSim = agent.agentCode + '-SIM';
    const strignifiedSim = localStorage.getItem(agentCodeSim);
    if (strignifiedSim === null || strignifiedSim === '') return undefined;
    const audit = JSON.parse(strignifiedSim);
    if (Object.keys(audit).length === 0) return undefined;
    return audit;
};

/**
 * Fonction pour récupérer le gabarit JSON pour l'agent courant
 */
export const getPrevisit = () => {
    const agent = getCurrentAgent();
    if (!agent) return undefined;
    const agentCodePV = agent.agentCode + '-PV';
    const strignifiedPV = localStorage.getItem(agentCodePV);
    if (strignifiedPV === null || strignifiedPV === '') return undefined;
    const audit = JSON.parse(strignifiedPV);
    if (Object.keys(audit).length === 0) return undefined;
    return audit;
};

/**
 * retourne 2 objets
 *    - un audit applatit
 *    - un ClientIdentity
 */
export const stepListToAuditAndClient = (): { audit: any; clientIdentity: ClientIdentity } => {
    const auditLocalStorage = getAudit();
    //console.log('auditLocalStorage = ' + JSON.stringify(auditLocalStorage));
    const stepsList: string[] = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8'];

    // Injection de toutes les propriétés dans l'objet Audit
    let audit: any = {};
    stepsList.forEach((step: any) => {
        audit = Object.assign(audit, auditLocalStorage[step]);
    });
    const clientIdentity = getClientIdentity();

    // Suppression informations inutiles
    Object.keys(clientIdentity).forEach((key: string) => {
        delete audit[key];
    });
    // Suppression images au format base64
    picturesNames.forEach((key: string) => {
        if (audit[key].value) {
            audit[key].value = '';
        }
    });
    // Suppression Boolean inutile
    delete audit.isValid;

    // Préparation du formData pour la requête
    let auditAndClient: any = {
        clientIdentity,
        audit,
    };
    return auditAndClient;
};

/**
 * Fonction pour remplir le gabarit JSON pour l'agent courant
 */
export const setAudit = (value: any): void => {
    const agent = getCurrentAgent();
    if (!agent) return undefined;
    const agentCode = agent.agentCode;

    localStorage.setItem(agentCode, JSON.stringify(value));
};
/**
 * Fonction pour VIDER le gabarit JSON pour l'agent courant
 */
export const resetAudit = (): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const agentCode = agent.agentCode;

    localStorage.removeItem(agentCode);
};

// Set simulator template
export const setSim = (value: any): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const simulator = `${agent.agentCode}-SIM`;
    localStorage.setItem(simulator, JSON.stringify(value));
};

/**
 * pour vider les donénes de simulation
 */
export const resetSim = (): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const simulator = `${agent.agentCode}-SIM`;

    localStorage.removeItem(simulator);
};

// Set previsit template
export const setPrevisit = (value: any): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const preVisite = `${agent.agentCode}-PV`;
    localStorage.setItem(preVisite, JSON.stringify(value));
};

// reet previsit template
export const resetPrevisit = (): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const preVisite = `${agent.agentCode}-PV`;
    localStorage.removeItem(preVisite);
};
// Set funding data
export const setFunding = (value: any): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const funding = `${agent.agentCode}-FUND`;
    localStorage.setItem(funding, JSON.stringify(value));
};
// Set funding data
export const resetFunding = (): void => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const funding = `${agent.agentCode}-FUND`;
    localStorage.removeItem(funding);
};

export const getFunding = (): FlowFundingOutput | undefined => {
    const agent = getCurrentAgent();
    if (!agent) return;
    const funding = `${agent.agentCode}-FUND`;
    return JSON.parse(localStorage.getItem(funding) as string);
};

//#endregion

// #region check properties, for audit/sim/previsit

// TODO : refacto TypedLocalStorage, faire une fonction séparée pour retourner la valeur par défaut.
/**
 * Fonction permettant le contrôle si une propriété existe dans l'étape, sinon création, sinon renvoi de l'élément.
 * @param step (Numéro de l"étape)
 * @param propertyName (Nom de la propriété)
 * @param propertyDescription (Description de la propriété dans le JSON)
 * @param propertyType (Le type de propriété boolean|basic|number|withLabel)
 */
export const checkPropertyExistThenCreateOrRender = (step: number, propertyName: string, propertyDescription: string, propertyType: PropertyType) => {
    // Récupération du template global
    const template = getAudit();
    // Vérification présence de la propriété dans l'étape
    if (!template[`step${step}`].hasOwnProperty(propertyName)) {
        let newProperty: any = {
            desc: propertyDescription,
        };
        // Définition du type
        switch (propertyType) {
            case PropertyType['boolean']:
                newProperty.value = false;
                break;
            case PropertyType['basic']:
                newProperty.value = '';
                break;
            case PropertyType['number']:
                newProperty.value = null;
                break;
            case PropertyType['withLabel']:
                newProperty.value = '';
                newProperty.value_label = '';
                break;
            case PropertyType['building']:
                newProperty.value = [
                    {
                        name: '',
                        shape: '1',
                        height: 2.5,
                        length: 10,
                        width: 5,
                        orientation: 'N',
                        img: '',
                    },
                ];
                break;
            case PropertyType['buildingDetail']:
                newProperty.value = [
                    {
                        buildingType: '',
                        buildingTypeSurface: '',
                        identicalBuildingType: '',
                        buildingTypeMainRoom: '',
                        buildingTypeBathroom: '',
                        buildingTypeBathroom2: '',
                        buildingTypeWC: '',
                        buildingTypeEmission: '',
                        buildingTypeECS: '',
                    },
                ];
                break;
            case PropertyType['dependentDetail']:
                newProperty.value = [];
                break;
            case PropertyType['window']:
                newProperty.value = {};
                break;
            case PropertyType['array']:
                newProperty.value = [];
                break;
            case PropertyType['photo']:
                newProperty.value = '';
                newProperty.url = '';
                break;
        }
        // Récupération de l'étape
        let alteredStep = template[`step${step}`];
        // injection du nouveau champ
        alteredStep[propertyName] = newProperty;
        template[`step${step}`] = alteredStep;
        // Injection dans local storage
        // TODO CHECK THIS REPLACEMENT.
        // Récupération code agent
        //const agentCode = Cookies.getJSON('Auth').agentCode;
        //return setLocalStorage(agentCode, JSON.stringify(template));
        return setAudit(template);
    }
    return template[`step${step}`][propertyName].value;
};

/**
 * Fonction permettant le contrôle si une propriété existe dans l'étape, sinon création, sinon renvoi de l'élément.
 * @param propertyName (Nom de la propriété)
 * @param propertyDescription (Description de la propriété dans le JSON)
 * @param propertyType (Le type de propriété boolean|basic|number|withLabel)
 */

export const checkPropertyExistThenCreateOrRenderPreVisite = (propertyName: string, propertyDescription: string, propertyType: PropertyType) => {
    const agent = getCurrentAgent();
    if (agent === undefined) {
        console.log("Problème de cookie, vérifier que `checkPropertyExistThenCreateOrRenderPrevisite` n'a pas été appelé trop tôt");
    }
    // Récupération code agent
    const agentCode = agent.agentCode + '-PV';
    // Récupération du template global
    const template = getPrevisit();
    // Vérification présence de la propriété dans l'étape
    if (!template.hasOwnProperty(propertyName)) {
        let newProperty: any = {
            desc: propertyDescription,
        };
        // Définition du type
        switch (propertyType) {
            case PropertyType['boolean']:
                newProperty.value = '';
                break;
            case PropertyType['basic']:
                newProperty.value = '';
                break;
            case PropertyType['number']:
                newProperty.value = null;
                break;
            case PropertyType['withLabel']:
                newProperty.value = '';
                newProperty.value_label = '';
                break;
            case PropertyType['heatingCircuit']:
                newProperty.value = [{ type: { label: '', value: '' }, source: { label: '', value: '' }, temperature: '' }];
                break;
            case PropertyType['radiatorCircuit']:
                newProperty.value = [
                    {
                        thermostaticValve: '',
                        width: '',
                        height: '',
                        depth: '',
                        inputTemperature: '',
                        outputTemperature: '',
                        radiatorType: '',
                        topView: '',
                    },
                ];
                break;
            case PropertyType['roofShape']:
                newProperty.value = {
                    cheminee: { color: colors[1], elements: [] },
                    chien_assis: { color: colors[3], elements: [] },
                    panneaux_photovoltaiques: { color: colors[4], elements: [] },
                    toiture: { color: colors[0], elements: [] },
                    velux: { color: colors[2], elements: [] },
                };
                break;
            case PropertyType['date']:
                newProperty.value = null;
                break;
        }
        // Injection dans local storage
        return setLocalStorage(agentCode, JSON.stringify(template));
    }

    return template[propertyName].value;
};
/**
 * Fonction permettant le contrôle si une propriété existe dans l'étape, sinon création, sinon renvoi de l'élément.
 * @param propertyName (Nom de la propriété)
 * @param propertyDescription (Description de la propriété dans le JSON)
 * @param propertyType (Le type de propriété boolean|basic|number|withLabel)
 */
export const checkPropertyExistThenCreateOrRenderSimulator = (propertyName: string, propertyDescription: string, propertyType: PropertyType) => {
    const getCookie = Cookies.getJSON('Auth');
    if (getCookie === undefined) {
        console.log("Problème de cookie, vérifier que `checkPropertyExistThenCreateOrRenderSimulator` n'a pas été appelé trop tôt");
    }
    // Récupération code agent
    const sim = getCookie.agentCode + '-SIM';
    // Récupération du template global
    const template = getSim();
    // Vérification présence de la propriété dans l'étape
    if (!template.hasOwnProperty(propertyName)) {
        let newProperty: any = {
            desc: propertyDescription,
        };
        // Définition du type
        switch (propertyType) {
            case PropertyType['boolean']:
                newProperty.value = false;
                break;
            case PropertyType['basic']:
                newProperty.value = '';
                break;
            case PropertyType['number']:
                newProperty.value = null;
                break;
            case PropertyType['withLabel']:
                newProperty.value = '';
                newProperty.value_label = '';
                break;
            case PropertyType['dependentDetail']:
                newProperty.value = [];
                break;
            case PropertyType['anexe']:
                newProperty.value = [
                    {
                        designation: '',
                        prix: '',
                    },
                ];
                break;
            case PropertyType['loan']:
                newProperty.value = null;
        }
        // Injection dans local storage
        return setLocalStorage(sim, JSON.stringify(template));
        // TODO NICOLAS : is this would do it ?
        // setSim(JSON.stringify(template))
    }

    return template[propertyName].value;
};

//#endregion

// #region steps management

export const checkLocalStorageCurrentStep = () => {
    const agentCode = getCurrentAgent().agentCode;
    if (JSON.parse(localStorage.getItem(agentCode) as string)) {
        return JSON.parse(localStorage.getItem(agentCode) as string).currentStep;
    }
    return false;
};

export const getSafeCurrentStep = () => {
    const agent = getCurrentAgent();
    if (agent == null || agent === undefined) return 1;
    if (agent.agentCode == null || agent.agentCode === undefined || agent.agentCode === '') return 1;
    const audit = getAudit();
    if (!audit || audit.currentStep == null || audit.currentStep === undefined) return 1;
    return audit.currentStep;
};

export const getAuditCurrentStep = (): number => {
    return getAudit().currentStep;
};
export const setAuditCurrentStep = (newStep: number) => {
    const audit = getAudit();
    audit.currentStep = newStep;
    return setAudit(audit);
};
/**
 * Fonction pour vérifier si l'étape courante est intégralement remplie.
 * @param step
 */
export const isAuditStepValid = (step: number) => {
    const template = getAudit();
    const currentStep = template[`step${step}`];
    return currentStep.isValid;
};

//#endregion

// #region camapgne

export const campagneNames = ['reste_a_charge', 'action_logement', undefined] as const;
export type Campagne = (typeof campagneNames)[number];

export const getAuditCampagne = (): Campagne => {
    const agentCode = Cookies.getJSON('Auth').agentCode;
    return JSON.parse(localStorage?.getItem(agentCode) as string)?.campagne;
};

/**
 * Fonction pour set une campagne
 */
export const setAuditCampagne = (value: Campagne) => {
    const template = getAudit();
    template.campagne = value;
    return setAudit(template);
};

// #endregion

// #region set audit -PV -SIM values

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setAuditValue = (step: number, isValid: boolean, propertyName: string, value: any, label?: string) => {
    const template = getAudit();
    template[`step${step}`][propertyName].value = value;
    if (label) {
        template[`step${step}`][propertyName].value_label = label;
    }
    template[`step${step}`].isValid = isValid;
    return setAudit(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setSimValue = (propertyName: string, value: any, label?: string) => {
    const template = getSim();
    template[propertyName].value = value;
    if (label) {
        template[propertyName].value_label = label;
    }

    return setSim(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setPrevisitValue = (propertyName: string, value: any, label?: string) => {
    const template = getPrevisit();
    template[propertyName].value = value;
    if (label) {
        template[propertyName].value_label = label;
    }

    return setPrevisit(template);
};

//TODO refacto TypedLocalStorage : mettre une valeur par défaut typée !
/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const removeAuditValue = (step: number, propertyName: string, removeLabel?: boolean) => {
    const audit = getAudit();
    audit[`step${step}`][propertyName].value = '';
    if (removeLabel) {
        audit[`step${step}`][propertyName].value_label = '';
    }
    return setAudit(audit);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON Simulator
 */
export const removeSimValue = (propertyName: string, removeLabel?: boolean, emptyArray?: boolean) => {
    const template = getSim();
    template[propertyName].value = '';

    if (removeLabel) {
        template[propertyName].value_label = '';
    }

    if (emptyArray) {
        template[propertyName].value = [];
    }

    return setSim(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON Simulator
 */
export const removePrevisitValue = (propertyName: string, removeLabel?: boolean, emptyArray?: boolean) => {
    const template = getPrevisit();
    template[propertyName].value = '';

    if (removeLabel) {
        template[propertyName].value_label = '';
    }

    if (emptyArray) {
        template[propertyName].value = [];
    }

    return setPrevisit(template);
};

/**
 * Fonction pour déclarer une étape comme valide ou invalide
 */
export const setAuditStepValidity = (step: number, isValid: boolean) => {
    const template = getAudit();
    template[`step${step}`].isValid = isValid;
    return setAudit(template);
};

//#endregion

//#region  get set reset  de recoAppointement, installationDate, devis id, previsitDocUrl

export const getRecoAppointment = (): AppointmentOutput | null => {
    const r = localStorage.getItem('recoAppointment');
    return r === null ? null : (JSON.parse(r) as AppointmentOutput);
};
export const setRecoAppointment = (recoAppointment: AppointmentOutput | null): void => {
    if (recoAppointment === null) resetRecoAppointment();
    localStorage.setItem('recoAppointment', JSON.stringify(recoAppointment));
};
export const resetRecoAppointment = (): void => {
    localStorage.removeItem('recoAppointment');
};

export const getInstallationDate = (): string | null => {
    const r = localStorage.getItem('installationDate');
    return r === null ? null : r;
};
export const setInstallationDate = (installationDate: string): void => {
    localStorage.setItem('installationDate', installationDate);
};
export const resetInstallationDate = (): void => {
    localStorage.removeItem('installationDate');
};

export const getDevisId = (): string | null => {
    const r = localStorage.getItem('devisId');
    return r === null ? null : r;
};
export const setDevisId = (devisId: string): void => {
    localStorage.setItem('devisId', devisId);
};
export const resetDevisId = (): void => {
    localStorage.removeItem('devisId');
};

export const getPrevisitDocUrl = (): string | null => {
    const r = localStorage.getItem('previsitDocUrl');
    return r === null ? null : r;
};
export const setPrevisitDocUrl = (previsitDocUrl: string): void => {
    localStorage.setItem('previsitDocUrl', previsitDocUrl);
};
export const resetPrevisitDocUrl = (): void => {
    localStorage.removeItem('previsitDocUrl');
};

//#endregion

//#region Building plan

/**
 * Fonction pour mettre à jour la valeur d'un building au changement de forme
 */
export const setGlobalBuildingPlan = (isValid: boolean, value: any) => {
    const template = getAudit();
    template[`step2`]['buildingPlans'].value = value;
    template[`step2`].isValid = isValid;
    return setAudit(template);
};

/**
 * Fonction pour mettre à jour la valeur une donnée précise du JSON
 */
export const setLocalStorageValueBuildingPlan = (isValid: boolean, propertyName: string, index: number, value: string | number) => {
    const template = getAudit();
    template[`step2`]['buildingPlans'].value[index][propertyName] = value;
    template[`step2`].isValid = isValid;
    return setAudit(template);
};

// #endregion Fin Building plan

//#region get config files

// Récupère un certains nombre de fichiers de configuration et les stockes dans le local storage du navigateur.
// Ces fichiers sont lu du serveur directement par appels des routes associées.

const configFileNames = [
    'energyprices',
    'constantesPhotovoltaiques',
    'sunshines',
    'sunshinesyield',
    'indexations',
    'preconisationsChauffage',
    'investmentBonus',
    'customerFlowParameters',
    //'financoScales',
] as const;
export type ConfigFileName = (typeof configFileNames)[number];

export type ConfigFileType<T extends ConfigFileName> = {
    energyprices: Array<EnergyPrice>;
    constantesPhotovoltaiques: ConstantesPhotovoltaique;
    sunshines: Array<Ensoleillement>;
    sunshinesyield: Array<{ inclinaison: number; rendement: { [key in Orientation]: number } }>;
    indexations: Indexations;
    preconisationsChauffage: Array<PreconisationChauffage>;
    investmentBonus: Array<InvestmentBonus>;
    customerFlowParameters: CustomerFlowParameters;
    //financoScales: Array<Labelled<FinancoScale>>;
}[T];

export const getConfig = <T extends ConfigFileName>(name: T): ConfigFileType<T> => {
    return JSON.parse(localStorage.getItem(name)!) as ConfigFileType<T>;
};

// Cas spécial
export const getFinacoScale = (): Array<Labelled<FinancoScale>> => {
    const scalesFromLocalStorage: string | null = localStorage.getItem('financoScales');
    return scalesFromLocalStorage ? JSON.parse(scalesFromLocalStorage) : [];
};

export const CONSTANTES_PHOTOVOLTAIQUES: ConstantesPhotovoltaique = getConfig('constantesPhotovoltaiques');

//#endregion

// TODO : un jour :

// // Faire un type avec le nom des clés du localStorage qu'on utilise :
// const localStorageKeys = ['flowId', 'auditId', 'devisId', 'previsitDocUrl', 'installationDate', 'recoAppointment'] as const;
// export type LocalStorageKeys = (typeof localStorageKeys)[number];

// //ajouter un tableau de type LocalStorageKeys dans le localStorageService.ts
// export type LocalStorageTypesDefs = {
//     flowId: string;
//     auditId: string;
//     devisId: string;
//     previsitDocUrl: string;
//     installationDate: string;
//     recoAppointment: AppointmentOutput;
// };
// // Si cette ligne de compile pas, il faut ajouter le type dans le localStorageTypesDefs
// export type LocalStorageTypes<T extends LocalStorageKeys> = LocalStorageTypesDefs[T];

// const localStorageTypesDefs: { [key in LocalStorageKeys]: boolean } = {
//     flowId: false,
//     auditId: false,
//     devisId: false,
//     previsitDocUrl: false,
//     installationDate: false,
//     recoAppointment: true,
// } as const;

// const isSerialisable = <T extends LocalStorageKeys>(key: T): boolean => {
//     return localStorageTypesDefs[key];
// };

// export const get = <T extends LocalStorageKeys>(key: T): LocalStorageTypes<T> | null => {
//     const r = localStorage.getItem(key);
//     if (r == null) return null;
//     // Selon le typage, désérialiser le JSON ou non.
//     if (isSerialisable(key)) {
//         return JSON.parse(r) as LocalStorageTypes<T>;
//     } else {
//         return r as LocalStorageTypes<T>;
//     }
// };

// export const set = <T extends LocalStorageKeys>(key: T, value: LocalStorageTypes<T>): void => {
//     // Selon le typage, sérialiser le JSON ou non.
//     if (isSerialisable(key)) {
//         localStorage.setItem(key, JSON.stringify(value));
//     } else {
//         localStorage.setItem(key, value.toString());
//     }
// };
// export const reset = <T extends LocalStorageKeys>(key: T): void => {
//     localStorage.removeItem(key);
// };
