import { DocumentEntry } from './types';
import DocumentButton from './DocumentButton';

// Style
import '../../pages/espace-agent/list-audit/ListAudit.scss';

export type DocumentRow = Array<DocumentEntry>;

type DocumentsProps = {
    title: string;
    rows: DocumentRow[];
    AddDocument?: (() => void) | undefined;
};

const DocumentList: React.FC<DocumentsProps> = (props: DocumentsProps) => {
    return (
        <div className="container-detail py-4 mb-4">
            <div className="row mb-4">
                <h2>{props.title}</h2>
                {/* {props.AddDocument && (
                    <div className="btn-group ms-auto">
                        <button type="button" className="btn btn-save" onClick={props.AddDocument}>
                            Ajouter un document
                        </button>
                    </div>
                )} */}
            </div>
            {props.rows.map((row, index) => {
                return (
                    <div className="row mb-3" key={index}>
                        {row.map((doc, index2) => {
                            //console.log('doc ' + index2);
                            const isLast = index === row.length - 1;
                            const divClassName = isLast ? 'col-12 col-md-3' : 'col-12 col-md-3 mb-4';
                            return (
                                <div key={index2} className={divClassName}>
                                    <DocumentButton {...doc} />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default DocumentList;
