import queryString from 'query-string';
import { getData } from '../apiParticulierService';

export const MRcalc = async (input: MRcalcParams): Promise<MRcalcReport> => {
    const qs = queryString.stringify(input);
    const url = `tech/pvgis/MRcalc?${qs}`;

    return await getData(url);
};

export const PVcalc = async (lat: number, long: number, power: number, loss: number, tilt: number, azimut: number): Promise<PVcalcReport> => {
    const input: PVcalcParams = {
        outputformat: 'json',
        raddatabase: 'PVGIS-SARAH3',
        pvtechchoice: 'crystSi', // | 'CIS' | 'CdTe' | 'Unknown',
        /** panel power un KWc (excel clientInfo!D28) */
        lat,
        lon: long,
        peakpower: power,
        loss,
        angle: tilt,
        /** azimuth en degres sud:0 est:-90  */
        aspect: azimut,
        /** 1 for yes, usefull when mountains as example */
        usehorizon: '1',
        // inclined_optimum: '1',
        // vertical_optimum: '1',
        // optimalangles: '1',
    };

    const qs = queryString.stringify(input);
    const url = `tech/pvgis/PVcalc?${qs}`;

    return await getData(url);
};

export const seriescalc = async (input: SeriesCalcParams): Promise<SeriesCalcReport> => {
    const qs = queryString.stringify(input);
    const url = `tech/pvgis/seriescalc?${qs}`;

    return await getData(url);
};

//#region tools

/**
 * Azimuth in degree. sud = 0, east  = -90, west = 90
 * @param orientation
 */
export const toAzimut = (cardinal: string): number => {
    const exposure = cardinal.replaceAll('-', '').toLocaleLowerCase();
    switch (exposure) {
        case 'nord':
        case 'n':
            return 180;
        case 'nordnordest':
        case 'nne':
            return -157.5;
        case 'nordest':
        case 'ne':
            return -135;
        case 'estnordest':
        case 'ene':
            return -112.5;
        case 'est':
        case 'e':
            return -90;
        case 'estsudest':
        case 'ese':
            return -67.5;
        case 'sudest':
        case 'se':
            return -45;
        case 'sudsudest':
        case 'sse':
            return -22.5;
        case 'sud':
        case 's':
            return 0;
        case 'sudsudouest':
        case 'sso':
            return 22.5;
        case 'sudouest':
        case 'so':
            return 45;
        case 'ouestsudouest':
        case 'oso':
            return 67.5;
        case 'ouest':
        case 'o':
            return 90;
        case 'ouestnordouest':
        case 'ono':
            return 112.5;
        case 'nordouest':
        case 'no':
            return 135;
        case 'nordnordouest':
        case 'nno':
            return 157.5;
    }
    throw Error('bad orientation');
};

//#endregion
export const MonthNames: Array<string> = ['', 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'];

export const pvgisRouteNames = ['PVcalc', 'SHScalc', 'MRcalc', 'DRcalc', 'seriescalc', 'tmy', 'printhorizon'] as const;
export type PvGisRoute = (typeof pvgisRouteNames)[number];

export const isPvgisRoute = (candidate: string | undefined): candidate is PvGisRoute => {
    return !!candidate && Object.values(pvgisRouteNames).includes(candidate as PvGisRoute);
};

//#region input types

export const radiationdatabases = ['PVGIS-CMSAF', 'PVGIS-SARAH', 'PVGIS-SARAH2', 'PVGIS-SARAH3', 'PVGIS-NSRDB', 'PVGIS-ERA5', 'PVGIS-COSMO'] as const;
export type PVGISRadiationDatabase = (typeof radiationdatabases)[number];

export const pvTechnologies = ['crystSi', 'CIS', 'CdTe', 'Unknown'] as const;
export type PVGISPVTechnologies = (typeof pvTechnologies)[number];

export type MonutingSystemsTypes = 'fixed' | 'inclined_axis' | 'two_axis' | 'vertical_axis' | 'fixed_horizontal';
export enum SunTrackingType {
    Fixed = 0,
    SingleHorizontalAxisNorthSouth = 1,
    TwoAxisTracking = 2,
    VerticalAxisTracking = 3,
    SingleHorizontalAxisEastWest = 4,
    SingleInclinedAxisNorthSouth = 5,
}
export const mountingPlaceNames = ['free', 'free-standing', 'building'] as const;
export type MountingPlaceType = (typeof mountingPlaceNames)[number] | undefined;

export const outputFormatNames = ['csv', 'basic', 'epw', 'json'] as const;
export type OutputFormat = (typeof outputFormatNames)[number];

export const qSbooleanNames = ['0', '1'] as const;
export type QSboolean = (typeof qSbooleanNames)[number];

//#region inputs
/**
 * Inputs for PVCalc.
 */
export type PVcalcParams = {
    /**
     * Latitude, in decimal degrees, south is negative.
     * @example -33.8688
     */
    lat: number;

    /**
     * Longitude, in decimal degrees, west is negative.
     * @example 151.2093
     */
    lon: number;

    /**
     * Calculate taking into account shadows from high horizon. Value of 1 for "yes".
     * @default 1
     */
    usehorizon?: QSboolean;

    /**
     * Height of the horizon at equidistant directions around the point of interest, in degrees.
     * Starting at north and moving clockwise.
     * The series '0,10,20,30,40,15,25,5' would mean the horizon height is 0° due north, 10° for north-east, 20° for east, 30° for south-east, etc.
     */
    userhorizon?: number[];

    /**
     * Name of the radiation database.
     * "PVGIS-SARAH" for Europe, Africa and Asia or "PVGIS-NSRDB" for the Americas between 60°N and 20°S,
     * "PVGIS-ERA5" and "PVGIS-COSMO" for Europe (including high-latitudes),
     * and "PVGIS-CMSAF" for Europe and Africa (will be deprecated).
     * @default "PVGIS-SARAH"
     */
    raddatabase?: PVGISRadiationDatabase;

    /**
     * Nominal power of the PV system, in kW.
     * @example 5.2
     */
    peakpower: number;

    /**
     * PV technology. Choices are: "crystSi", "CIS", "CdTe" and "Unknown".
     * @default "crystSi"
     */
    pvtechchoice?: PVGISPVTechnologies;

    /**
     * Type of mounting of the PV modules. Choices are: "free" for free-standing and "building" for building-integrated.
     * @default "free"
     */
    mountingplace?: MountingPlaceType;

    /**
     * Sum of system losses, in percent.
     * @example 15
     */
    loss: number;

    /**
     * Calculate a fixed mounted system. Value of 0 for "no". All other values (or no value) mean "Yes".
     * Note that this means the default is "yes".
     * @default 1
     */
    fixed?: number;

    /**
     * Inclination angle from horizontal plane of the (fixed) PV system.
     * @default 0
     */
    angle?: number;

    /**
     * Orientation (azimuth) angle of the (fixed) PV system, 0=south, 90=west, -90=east.
     * @default 0
     */
    aspect?: number;

    /**
     * Calculate the optimum inclination angle. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    optimalinclination?: number;

    /**
     * Calculate the optimum inclination AND orientation angles. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    optimalangles?: QSboolean;

    /**
     * Calculate a single inclined axis system. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    inclined_axis?: QSboolean;

    /**
     * Calculate optimum angle for a single inclined axis system. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    inclined_optimum?: QSboolean;

    /**
     * Inclination angle for a single inclined axis system. Ignored if the optimum angle should be calculated (parameter "inclined_optimum").
     * @default 0
     */
    inclinedaxisangle?: number;

    /**
     * Calculate a single vertical axis system. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    vertical_axis?: QSboolean;

    /**
     * Calculate optimum angle for a single vertical axis system. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    vertical_optimum?: QSboolean;

    /**
     * Inclination angle for a single vertical axis system. Ignored if the optimum angle should be calculated (parameter "vertical_optimum" set to 1).
     * @default 0
     */
    verticalaxisangle?: number;

    /**
     * Calculate a two-axis tracking system. Value of 1 for "yes". All other values (or no value) mean "no".
     * @default 0
     */
    twoaxis?: QSboolean;

    /**
     * Calculate the PV electricity price [kWh/year] in the currency introduced by the user for the system cost.
     * @example 0.12
     */
    pvprice?: number;

    /**
     * Total cost of installing the PV system [your currency].
     * Required if "pvprice" is provided.
     */
    systemcost?: number;

    /**
     * Interest in %/year
     * Required if "pvprice" is provided.
     */
    interest?: number;

    /**
     * Expected lifetime of the PV system in years.
     * @default 25
     */
    lifetime?: number;

    /**
     * Type of output. Choices are: "csv" for the normal csv output with text explanations, "basic" to get only the data output with no text, and "json".
     * @default "csv"
     */
    outputformat?: OutputFormat;

    /**
     * Use this with a value of "1" if you access the web service from a web browser and want to save the data to a file.
     * @default 0
     */
    browser?: QSboolean;
};

/**
 * Inputs for  SHScalc.
 */
export type SHScalcParams = {
    /**
     * Latitude, in decimal degrees, south is negative.
     * @example -33.8688
     */
    lat: number;

    /**
     * Longitude, in decimal degrees, west is negative.
     * @example 151.2093
     */
    lon: number;

    /**
     * Calculate taking into account shadows from high horizon. Value of 1 for "yes".
     * @default 1
     */
    usehorizon?: QSboolean;

    /**
     * Height of the horizon at equidistant directions around the point of interest, in degrees.
     * Starting at north and moving clockwise. The series '0,10,20,30,40,15,25,5' would mean the horizon height is 0° due north, 10° for north-east, 20° for east, 30° for south-east, etc.
     */
    userhorizon?: number[];

    /**
     * Name of the radiation database.
     * "PVGIS-SARAH" for Europe, Africa and Asia or "PVGIS-NSRDB" for the Americas between 60°N and 20°S,
     * "PVGIS-ERA5" and "PVGIS-COSMO" for Europe (including high-latitudes),
     * and "PVGIS-CMSAF" for Europe and Africa (will be deprecated).
     * @default "default DB"
     */
    raddatabase?: PVGISRadiationDatabase;

    /**
     * Nominal power of the PV system, in W.
     * @example 5000
     */
    peakpower: number;

    /**
     * Inclination angle from horizontal plane of the (fixed) PV system.
     * @default 0
     */
    angle?: number;

    /**
     * Orientation (azimuth) angle of the (fixed) PV system, 0=south, 90=west, -90=east.
     * @default 0
     */
    aspect?: number;

    /**
     * This is the size, or energy capacity, of the battery used in the off-grid system, measured in watt-hours (Wh).
     * @example 10000
     */
    batterysize: number;

    /**
     * Batteries cutoff in %. The cutoff is imposed so that the battery charge cannot go below a certain percentage of full charge.
     * @example 20
     */
    cutoff: number;

    /**
     * Energy consumption of all the electrical equipment connected to the system during a 24-hour period (Wh)
     * @example 50000
     */
    consumptionday: number;

    /**
     * List of 24 comma-separated values with the hourly consumption.
     * The values in the file should be the fraction of the daily consumption that takes place in each hour, with the sum of the numbers equal to 1.
     * The daily consumption profile should be defined for the standard local time, without consideration of daylight saving offsets if relevant to the location.
     * The format is the same as the default consumption file.
     */
    hourconsumption?: number[];

    /**
     * Type of output. Choices are: "csv" for the normal CSV output with text explanations, "basic" to get only the data output with no text, and "json".
     * @default "csv"
     */
    outputformat?: OutputFormat;

    /**
     * Use this with a value of "1" if you access the web service from a web browser and want to save the data to a file.
     * @default 0
     */
    browser?: QSboolean;
};

/**
 * Inputs for MRcalc
 */
export type MRcalcParams = {
    /**
     * Latitude, in decimal degrees, south is negative.
     * @example -33.8688
     */
    lat: number;

    /**
     * Longitude, in decimal degrees, west is negative.
     * @example 151.2093
     */
    lon: number;

    /**
     * Calculate taking into account shadows from high horizon. Value of 1 for "yes".
     * @default 1
     */
    usehorizon?: QSboolean;

    /**
     * Height of the horizon at equidistant directions around the point of interest, in degrees.
     * Starting at north and moving clockwise. The series '0,10,20,30,40,15,25,5' would mean the horizon height is 0° due north, 10° for north-east, 20° for east, 30° for south-east, etc.
     */
    userhorizon?: number[];

    /**
     * Name of the radiation database (DB).
     * "PVGIS-SARAH" for Europe, Africa and Asia or "PVGIS-NSRDB" for the Americas between 60°N and 20°S,
     * "PVGIS-ERA5" and "PVGIS-COSMO" for Europe (including high-latitudes),
     * and "PVGIS-CMSAF" for Europe and Africa (will be deprecated).
     * The default DBs are PVGIS-SARAH, PVGIS-NSRDB, and PVGIS-ERA5 based on the chosen location.
     * @see Figure xx for location information.
     */
    raddatabase?: PVGISRadiationDatabase;

    /**
     * First year of the output of monthly averages. Availability varies with the temporal coverage of the radiation DB chosen.
     * The default value is the first year of the DB.
     * @default year_min(DB)
     */
    startyear?: number;

    /**
     * Final year of the output of monthly averages. Availability varies with the temporal coverage of the radiation DB chosen.
     * The default value is the last year of the DB.
     * @default year_max(DB)
     */
    endyear?: number;

    /**
     * Output horizontal plane irradiation. Value of 1 for "yes". All other values (or no value) mean "no".
     */
    horirrad?: QSboolean;

    /**
     * Output annual optimal angle plane irradiation. Value of 1 for "yes". All other values (or no value) mean "no".
     */
    optrad?: QSboolean;

    /**
     * Output irradiation on plane of selected inclination. Value of 1 for "yes". All other values (or no value) mean "no".
     */
    selectrad?: QSboolean;

    /**
     * Inclination angle for the selected inclination irradiation option.
     * @default 0
     */
    angle?: number;

    /**
     * Output direct normal irradiation. Value of 1 for "yes". All other values (or no value) mean "no".
     */
    mr_dni?: QSboolean;

    /**
     * Output monthly values of the ratio of diffuse to global radiation (horizontal plane). Value of 1 for "yes". All other values (or no value) mean "no".
     */
    d2g?: QSboolean;

    /**
     * Output monthly average values of daily (24h) temperature. Value of 1 for "yes". All other values (or no value) mean "no".
     */
    avtemp?: QSboolean;

    /**
     * Type of output. Choices are: "csv" for the normal CSV output with text explanations, "basic" to get only the data output with no text, and "json".
     * @default "csv"
     */
    outputformat?: OutputFormat;

    /**
     * Use this with a value of "1" if you access the web service from a web browser and want to save the data to a file.
     * @default 0
     */
    browser?: QSboolean;
};

/**
 * Inputs for DRcalc
 */
export type DRcalcParams = {
    /**
     * Latitude, in decimal degrees, south is negative.
     * @example -33.8688
     * @required
     */
    lat: number;

    /**
     * Longitude, in decimal degrees, west is negative.
     * @example 151.2093
     * @required
     */
    lon: number;

    /**
     * Calculate taking into account shadows from high horizon. Value of 1 for "yes".
     * @default 1
     * @optional
     */
    usehorizon?: QSboolean;

    /**
     * Height of the horizon at equidistant directions around the point of interest, in degrees.
     * Starting at north and moving clockwise. The series '0,10,20,30,40,15,25,5' would mean the horizon height is 0° due north, 10° for north-east, 20° for east, 30° for south-east, etc.
     * @optional
     */
    userhorizon?: number[];

    /**
     * Name of the radiation database (DB).
     * "PVGIS-SARAH" for Europe, Africa and Asia or "PVGIS-NSRDB" for the Americas between 60°N and 20°S,
     * "PVGIS-ERA5" and "PVGIS-COSMO" for Europe (including high-latitudes),
     * and "PVGIS-CMSAF" for Europe and Africa (will be deprecated).
     * The default DBs are PVGIS-SARAH, PVGIS-NSRDB, and PVGIS-ERA5 based on the chosen location.
     * @optional
     * @default default DB
     */
    raddatabase?: PVGISRadiationDatabase;

    /**
     * The value of this parameter should be the number of the month, starting at 1 for January. If you give the value 0 (zero) you will instead get data for all the months.
     * @required
     */
    month: number;

    /**
     * Inclination angle from the horizontal plane of the (fixed) PV system.
     * @default 0
     * @optional
     */
    angle?: number;

    /**
     * Orientation (azimuth) angle of the (fixed) PV system, 0=south, 90=west, -90=east.
     * @default 0
     * @optional
     */
    aspect?: number;

    /**
     * Output the global, direct, and diffuse in-plane irradiances. Value of 1 for "yes". All other values (or no value) mean "no".
     * @optional
     */
    global?: QSboolean;

    /**
     * Output the global, direct, and diffuse two-axis tracking irradiances. Value of 1 for "yes". All other values (or no value) mean "no".
     * @optional
     */
    glob_2axis?: QSboolean;

    /**
     * Output the global clear-sky irradiance. Value of 1 for "yes". All other values (or no value) mean "no".
     * @optional
     */
    clearsky?: QSboolean;

    /**
     * Output the global clear-sky two-axis tracking irradiance. Value of 1 for "yes". All other values (or no value) mean "no".
     * @optional
     */
    clearsky_2axis?: QSboolean;

    /**
     * Output the daily temperature profile. Value of 1 for "yes". All other values (or no value) mean "no".
     * @optional
     */
    showtemperatures?: QSboolean;

    /**
     * Output the time in the local time zone (not daylight saving time), instead of UTC. Value of 1 for "yes". All other values (or no value) mean "no".
     * @optional
     */
    localtime?: QSboolean;

    /**
     * Type of output. Choices are: "csv" for the normal CSV output with text explanations, "basic" to get only the data output with no text, and "json".
     * @default "csv"
     * @optional
     */
    outputformat?: OutputFormat;

    /**
     * Use this with a value of "1" if you access the web service from a web browser and want to save the data to a file.
     * @default 0
     * @optional
     */
    browser?: QSboolean;
};

/**
 * Inputs for Series.
 */
export type SeriesCalcParams = {
    /**
     * Latitude, in decimal degrees, south is negative.
     * @example -33.8688
     * @required
     */
    lat: number;

    /**
     * Longitude, in decimal degrees, west is negative.
     * @example 151.2093
     * @required
     */
    lon: number;

    /**
     * Calculate taking into account shadows from high horizon. Value of 1 for "yes".
     * @default 1
     * @optional
     */
    usehorizon?: QSboolean;

    /**
     * Height of the horizon at equidistant directions around the point of interest, in degrees.
     * Starting at north and moving clockwise. The series '0,10,20,30,40,15,25,5' would mean the horizon height is 0° due north, 10° for north-east, 20° for east, 30° for south-east, etc.
     * @optional
     */
    userhorizon?: number[];

    /**
     * Name of the radiation database (DB).
     * "PVGIS-SARAH" for Europe, Africa and Asia or "PVGIS-NSRDB" for the Americas between 60°N and 20°S,
     * "PVGIS-ERA5" and "PVGIS-COSMO" for Europe (including high-latitudes),
     * and "PVGIS-CMSAF" for Europe and Africa (will be deprecated).
     * The default DBs are PVGIS-SARAH, PVGIS-NSRDB, and PVGIS-ERA5 based on the chosen location.
     * @optional
     * @default default DB
     */
    raddatabase?: PVGISRadiationDatabase;

    /**
     * First year of the output of hourly averages. Availability varies with the temporal coverage of the radiation DB chosen.
     * The default value is the first year of the DB.
     * @optional
     */
    startyear?: number;

    /**
     * Final year of the output of hourly averages. Availability varies with the temporal coverage of the radiation DB chosen.
     * The default value is the last year of the DB.
     * @optional
     */
    endyear?: number;

    /**
     * If "0" outputs only solar radiation calculations, if "1" outputs the estimation of hourly PV production as well.
     * @optional
     */
    pvcalculation?: QSboolean;

    /**
     * Nominal power of the PV system, in kW.
     * @required if pvcalculation is set
     */
    peakpower?: number;

    /**
     * PV technology. Choices are: "crystSi", "CIS", "CdTe", and "Unknown".
     * @default "crystSi"
     * @optional
     */
    pvtechchoice?: PVGISPVTechnologies;

    /**
     * Type of mounting of the PV modules. Choices are: "free" for free-standing and "building" for building-integrated.
     * @default "free"
     * @optional
     */
    mountingplace?: MountingPlaceType;

    /**
     * Sum of system losses, in percent.
     * @required if pvcalculation is set
     */
    loss?: number;

    /**
     * Type of suntracking used, 0=fixed, 1=single horizontal axis aligned north-south, 2=two-axis tracking, 3=vertical axis tracking, 4=single horizontal axis aligned east-west, 5=single inclined axis aligned north-south.
     * @optional
     */
    trackingtype?: SunTrackingType;

    /**
     * Inclination angle from horizontal plane. Not relevant for 2-axis tracking.
     * @optional
     */
    angle?: number;

    /**
     * Orientation (azimuth) angle of the (fixed) plane, 0=south, 90=west, -90=east. Not relevant for tracking planes.
     * @optional
     */
    aspect?: number;

    /**
     * Calculate the optimum inclination angle. Value of 1 for "yes". All other values (or no value) mean "no". Not relevant for 2-axis tracking.
     * @optional
     */
    optimalinclination?: QSboolean;

    /**
     * Calculate the optimum inclination AND orientation angles. Value of 1 for "yes". All other values (or no value) mean "no". Not relevant for tracking planes.
     * @optional
     */
    optimalangles?: QSboolean;

    /**
     * If "1" outputs beam, diffuse, and reflected radiation components. Otherwise, it outputs only global values.
     * @optional
     */
    components?: QSboolean;

    /**
     * Type of output. Choices are: "csv" for the normal CSV output with text explanations, "basic" to get only the data output with no text, and "json".
     * @default "csv"
     * @optional
     */
    outputformat?: OutputFormat;

    /**
     * Use this with a value of "1" if you access the web service from a web browser and want to save the data to a file.
     * @default 0
     * @optional
     */
    browser?: QSboolean;
};

/**
 * Inputs for Tmy
 */
export type TmyParams = {
    /**
     * Latitude, en degrés décimaux, le sud est négatif.
     */
    lat: number;
    /**
     * Longitude, en degrés décimaux, l'ouest est négatif.
     */
    lon: number;
    /**
     * (Optionnel) Calcul en tenant compte des ombres dues à un horizon élevé. Valeur de 1 pour "oui".
     */
    usehorizon?: QSboolean;
    /**
     * (Optionnel) Hauteur de l'horizon dans des directions équidistantes autour du point d'intérêt, en degrés. En partant du nord et en tournant dans le sens des aiguilles d'une montre.
     */
    userhorizon?: number[];
    /**
     * (Optionnel) Première année de la TMY. La disponibilité dépend de la couverture temporelle de la base de données de rayonnement choisie. La valeur par défaut est la première année de la base de données.
     */
    startyear?: number;
    /**
     * (Optionnel) Dernière année de la TMY. La disponibilité dépend de la couverture temporelle de la base de données de rayonnement choisie. La valeur par défaut est la dernière année de la base de données.
     * La période définie par startyear et endyear doit être >= 10 ans.
     */
    endyear?: number;
    /**
     * (Optionnel) Type de sortie.
     * Choix possibles : "csv" pour la sortie CSV normale avec des explications textuelles, "basic" pour obtenir uniquement les données de sortie sans texte, "epw" pour obtenir un format adapté au logiciel EnergyPlus, et "json".
     */
    outputformat?: OutputFormat;

    /**
     * (Optionnel) Utilisez ceci avec une valeur de "1" si vous accédez au service web depuis un navigateur web et que vous souhaitez enregistrer les données dans un fichier.
     */
    browser?: QSboolean;
};

/**
 * Inputs for  PrintHorizon
 */
export type PrintHorizonParams = {
    /**
     * Latitude, en degrés décimaux, le sud est négatif.
     */
    lat: number;

    /**
     * Longitude, en degrés décimaux, l'ouest est négatif.
     */
    lon: number;

    /**
     * (Optionnel) Hauteur de l'horizon dans des directions équidistantes autour du point d'intérêt, en degrés. En partant du nord et en tournant dans le sens des aiguilles d'une montre.
     */
    userhorizon?: string;

    /**
     * (Optionnel) Type de sortie.
     * Choix possibles : "csv" pour la sortie CSV normale avec des explications textuelles, "basic" pour obtenir uniquement les données de sortie sans texte, et "json".
     */
    outputformat?: OutputFormat;

    /**
     * (Optionnel) Utilisez ceci avec une valeur de "1" si vous accédez au service web depuis un navigateur web et que vous souhaitez enregistrer les données dans un fichier.
     */
    browser?: QSboolean;
};

export type PvgisParams = {
    PVcalc: PVcalcParams;
    SHScalc: SHScalcParams;
    MRcalc: MRcalcParams;
    DRcalc: DRcalcParams;
    seriescalc: SeriesCalcParams;
    tmy: TmyParams;
    printhorizon: PrintHorizonParams;
};

export type AllPvgisParams = PVcalcParams | SHScalcParams | MRcalcParams | DRcalcParams | SeriesCalcParams | TmyParams | PrintHorizonParams;

//#endregion

//#region output types

export type PvgisResult = {
    PVcalc: PVcalcReport;
    SHScalc: SHScalcReport;
    MRcalc: MRcalcReport;
    DRcalc: DRcalcReport;
    seriescalc: SeriesCalcReport;
    tmy: TmyReport;
    printhorizon: PrintHorizonReport;
};

export type AllPvgisResult = PVcalcReport | SHScalcReport | MRcalcReport | DRcalcReport | SeriesCalcReport | TmyReport | PrintHorizonReport;

type UnitDescriptor = {
    description: string;
    units?: string | undefined;
};
type DescriptorFor<Keys extends string> = { [key in Keys]?: UnitDescriptor };

type VariableDescriptor<Keys extends string> = {
    description: string;
    variables: DescriptorFor<Keys>;
};

type Location = {
    latitude: number;
    longitude: number;
    elevation: number;
};

type MeteoData = {
    radiation_db: string;
    meteo_db: string;
    year_min: number;
    year_max: number;
    use_horizon: boolean;
    horizon_db: string | null | undefined;
    horizon_data?: string | undefined;
};

type Fixation = {
    slope: { value: number | '-'; optimal: boolean | '-' };
    azimuth: { value: number | '-'; optimal: boolean | '-' };
    type?: string;
};

type PVModule = {
    technology?: string;
    peak_power?: number;
    system_loss?: number;
};

type BaseInput = {
    location: Location;
    meteo_data: MeteoData;
};

type MetaInputsBase = {
    location: VariableDescriptor<keyof Location>;
    meteo_data: VariableDescriptor<keyof MeteoData>;
};

type EconomicData = {
    system_cost?: number | null;
    interest?: number | null;
    lifetime?: number | null;
};

type FieldsDescriptor<Keys extends string> = {
    description: string;
    choices?: string;
    fields: DescriptorFor<Keys>;
};

type MetaOutput<Keys extends string> = {
    description?: string;
    type: string;
    timestamp?: string;
    variables: DescriptorFor<Keys>;
};

type Battery = {
    capacity: number;
    discharge_cutoff_limit: number;
};

type Consumption = {
    daily: number;
    hourly_profile: number[] | 'default';
};

export type PVCalcOutput = {
    month: number;
    E_d: number;
    E_m: number;
    'H(i)_d': number;
    'H(i)_m': number;
    SD_m: number;
};
export type PVCalcTotalOutput = {
    E_d: number;
    E_m: number;
    E_y: number;
    'H(i)_d': number;
    'H(i)_m': number;
    'H(i)_y': number;
    SD_m: number;
    SD_y: number;
    l_aoi: number;
    l_spec: string;
    l_tg: number;
    l_total: number;
    LCOE_pv: number;
};

export type PVcalcReport = {
    inputs: BaseInput & {
        mounting_system: { [key in MonutingSystemsTypes]?: Fixation };
        pv_module: PVModule;
        economic_data?: EconomicData;
    };
    outputs: {
        monthly: {
            [key in MonutingSystemsTypes]?: Array<PVCalcOutput>;
        };
        totals: {
            [key in MonutingSystemsTypes]?: PVCalcTotalOutput;
        };
    };
    meta?: {
        inputs: MetaInputsBase & {
            mounting_system: FieldsDescriptor<keyof Fixation>;
            pv_module: VariableDescriptor<keyof PVModule>;
            economic_data: VariableDescriptor<keyof EconomicData>;
        };

        outputs: {
            monthly: MetaOutput<keyof PVCalcOutput>;
            totals: MetaOutput<keyof PVCalcTotalOutput>;
        };
    };
};

export type SHScalcMonthlyOutput = {
    month: number;
    E_d: number;
    E_m: number;
    'H(i)_d': number;
    'H(i)_m': number;
    SD_m: number;
};
export type SHScalcTotalsOutput = {
    d_total: number;
    f_f: number;
    f_e: number;
    E_lost: number;
    E_miss: number;
};
export type SHScalcHistogramOutput = {
    CS_min: number;
    CS_max: number;
    f_CS: number;
};

export type SHScalcReport = {
    inputs: BaseInput & {
        mounting_system: { [key in MonutingSystemsTypes]?: Fixation };
        pv_module: PVModule;
        battery: Battery;
        consumption: Consumption;
    };
    outputs: {
        monthly: Array<SHScalcMonthlyOutput>;
        totals: SHScalcTotalsOutput;
        histogram: Array<SHScalcHistogramOutput>;
    };
    meta: {
        inputs: MetaInputsBase & {
            mounting_system: FieldsDescriptor<keyof Fixation>;
            pv_module: VariableDescriptor<keyof PVModule>;
        };
        outputs: {
            monthly: MetaOutput<keyof SHScalcMonthlyOutput>;
            totals: MetaOutput<keyof SHScalcTotalsOutput>;
            histogram: MetaOutput<keyof SHScalcHistogramOutput>;
        };
    };
};

export type MRCalcOutput = {
    year: number;
    month: number;
    /** exists if input.horirad === '1' */
    'H(h)_m'?: number;
    /** exists if input.optrad === '1' */
    'H(i_opt)_m'?: number;
    /** exists if input.selectrad === '1' */
    'H(i)_m'?: number;
    /** exists if input.mr_dni === '1' */
    'Hb(n)_m'?: number;
    /** exists if input.d2g === '1' */
    Kd?: number;
    /** exists if input.avtemp === '1' */
    T2m?: number;
};

export type MRcalcReport = {
    inputs: BaseInput & { plane: { [key in MonutingSystemsTypes]?: Fixation } };
    outputs: {
        monthly: Array<MRCalcOutput>;
    };
    meta: {
        inputs: MetaInputsBase & {
            plane: FieldsDescriptor<keyof Fixation>;
        };
        outputs: {
            monthly: MetaOutput<keyof MRCalcOutput>;
        };
    };
};

export type DRCalcOutput = {
    month: number;
    time: string;
    'G(i)': number;
    'Gb(i)': number;
    'Gd(i)': number;
};

export type DRcalcReport = {
    inputs: BaseInput & {
        plane: { [key in MonutingSystemsTypes]?: Fixation };
        time_format: string;
    };
    outputs: {
        daily_profile: Array<DRCalcOutput>;
    };
    meta: {
        inputs: MetaInputsBase & {
            plane: FieldsDescriptor<keyof Fixation>;
            time_format: Array<{ description: string }>;
        };
        outputs: {
            daily_profile: MetaOutput<keyof DRCalcOutput>;
        };
    };
};

export type SeriesCalcOutput = {
    time: string;
    P?: number;
    'G(i)'?: number;
    'Gb(i)'?: number;
    'Gd(i)'?: number;
    'Gr(i)'?: number;
    H_sun: number;
    T2m: number;
    WS10m: number;
    Int: number;
};

export type SeriesCalcReport = {
    inputs: BaseInput & {
        mounting_system: { [key in MonutingSystemsTypes]?: Fixation };
        pv_module: PVModule;
    };
    outputs: {
        hourly: Array<SeriesCalcOutput>;
    };
    meta: {
        inputs: MetaInputsBase & {
            mounting_system: FieldsDescriptor<keyof Fixation>;
            pv_module: VariableDescriptor<keyof PVModule>;
        };
        outputs: {
            hourly: MetaOutput<keyof SeriesCalcOutput>;
        };
    };
};

export type TmyOutput = {
    'time(UTC)': string;
    T2m: number;
    RH: number;
    'G(h)': number;
    'Gb(n)': number;
    'Gd(h)': number;
    'IR(h)': number;
    WS10m: number;
    WD10m: number;
    SP: number;
};

export type TmyReport = {
    inputs: BaseInput;
    outputs: {
        months_selected: Array<{
            month: number;
            year: number;
        }>;
        tmy_hourly: Array<TmyOutput>;
    };
    meta: {
        inputs: MetaInputsBase;
        outputs: {
            months_selected: {
                type: string;
                timestamp: string;
                description: string;
            };
            tmy_hourly: MetaOutput<keyof TmyOutput>;
        };
    };
};

export type HorizonOutput = { A: number; H_hor: number };

type AH_sun<T extends 's' | 'w'> = `A_sun(${T})` | `H_sun(${T})`;
export type HorizonSolsticeOutput<T extends 's' | 'w'> = { [key in AH_sun<T>]: number };

//type x = HorizonSolsticeOutput<'w'>;

export type PrintHorizonReport = {
    inputs: {
        location: Location;
        horizon_db: 'DEM-calculated';
    };
    outputs: {
        horizon_profile: Array<HorizonOutput>;
        winter_solstice: Array<HorizonSolsticeOutput<'w'>>;
        summer_solstice: Array<HorizonSolsticeOutput<'s'>>;
    };
    meta: {
        inputs: {
            location: VariableDescriptor<keyof Location>;
            horizon_db: UnitDescriptor;
        };
        outputs: {
            horizon_profile: MetaOutput<keyof HorizonOutput>;
            winter_solstice: MetaOutput<keyof HorizonSolsticeOutput<'w'>>;
            summer_solstice: MetaOutput<keyof HorizonSolsticeOutput<'s'>>;
        };
    };
};

// #endregion
