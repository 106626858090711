import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
// Simulator
import TemplateSimulator from '../../assets/json/template-simulator.json';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { isLocal } from '../../services/tools/generalTools';
import * as api from '../../services/apiParticulierService';
import * as apiService from '../../services/apiFlowService';
import * as apiFlow from '../../services/apiFlowService';
import * as apiDRA from '../../services/apiDRAService';
import * as storageService from '../../services/localStorageService';
import * as ticketService from '../../services/calculs/ticket';
import * as themesService from '../../services/calculs/theme';
import SimulatorContainer from '../../components/recommandations/SimulatorContainer';
import { filterAL, filterByUserPref, filterPreconisations, filterTva } from '../../services/calculs/filterPreconisation';
import { ReactComponent as SVGLoader } from '../../assets/icons/loader.svg';
import * as aideRenoDAmpleur from '../../services/calculs/aides/aideRenovationDAmpleur';
import withReactContent from 'sweetalert2-react-content';
import { applyPackageSelectorSpecialRules, PackageSelector, redimenstionnerLaPacEnCasDisolation } from '../../services/calculs/packageSelectorSpecialRules';

// Components
import TicketComponent from '../../components/recommandations/TicketContainer';
import {
    aideRenovationDAmpleurParamsStateAtom,
    ticketStateAtom,
    aidesLocalesListStateAtom,
    currentThemeIndexAtom,
    ticketLoaderAtom,
    themesStateAtom,
    aideRenovationDAmpleurTargetStateAtom,
    ticketValidateDisableStateAtom,
} from '../../services/Recoil/Atom/Themes.atom';
import { FilterThemePreference, defaultFilterThemePreference } from '../espace-agent/mon-compte/preference';
import { Countdown } from './countdown/Countdown';
import { getAppointmentDetails, isDeadLineAideOk } from '../../services/calculs/ticket';
import Themes from '../../components/recommandations/ThemeComponents';
import { RenovationDAmpleurParams, RenovationDAmpleurElligible, DraContext } from '../../services/calculs/aides/aideRenovationDAmpleurDefs';
import { AidesLocalesBuilder, updateTicket } from './ticketUpdater';
import { DossierRenoDAmpleur } from '../../services/apiDRAService';

// Style
import './audit-simulator.scss';

// READ ME
//
// fonctionnel de l'écran :
// - on télécharge des packages (WS)
// - on les filtre et on les trie avant de les afficher
// - on selectionne des packages,
// - a chaque selection, on reconstruit le ticket (WS)
// - on modifie le ticket (téléchargé) ici, pour ajouter des aides qui ne sont pas du ressort de icoll.
//
// Les aides icoll :
// - Les aides ne sont connue que lorsque on a recu un ticket du Web service.
// - on ne connait que les aides applicables avec la combinaison ed package passée en paramètres à la route.
// Les aides locales :
// - on ajoute après coup (sans appel WS) les aides que icoll n'a pas a connaitre, dites les aides locales
//  l'aide à la consommation photo voltaique, l'écopub, remise commerciale, Renovation D'ampleur

//
//
// README:
//
// Ce composant est le parent de TOUTE la page.
//
// Son cycle de vie se comporte en plusieurs temps.
// Il y a 2 grands cas selon qu'on soit en audit normaux (Expert/Thermique) ou en audit MAR (ExpertMar/ThermiqueMar)
//
// Il y a 4 use effect, appelés simultanément.
// 1 - useEffect N°1
//      Ne se produit qu'une seule fois
//      Construit les aides (remiseCommerciale, remise prévisite et 2/3 aides MAR)
//      Calcule la renovation d'ampleur (local ou lue sur l'api)
//      Charge les preférences de l'utilisaeur.
// 2 - useEffect N°2
//      Ne se produit qu'une seule fois
//      appelle la route getThemes afin d'obtenir la liste des packages.
//      filtre la liste selon les donénes de l'audit
//      Construit les aides pv et mma (le cas échéant))
//      Charge le tout (themes, et aides) sur l'écran.
//      déclenche le useEffect N°3
// 3 - useEffect N°3
//      Ne se produit qu'une seule fois
//      UPDATE LE TICKET (appel api + mise à jour des aides)
//      selon le parcours MAR, on s'occupe de changer les packages selectionnés par défaut.
//

const AuditSimulator: React.FC = () => {
    const agentCode: string = storageService.getCurrentAgent().agentCode;
    const auditSimulator: string | null = localStorage.getItem(`${agentCode}-SIM`);
    const [prefThemeFilter, setPrefThemeFilter] = useState<FilterThemePreference | undefined>(undefined); // Yes undefined
    const [themes, setThemes] = useRecoilState(themesStateAtom);
    const [draParams, setDraParams] = useRecoilState(aideRenovationDAmpleurParamsStateAtom);
    const [draTarget] = useRecoilState(aideRenovationDAmpleurTargetStateAtom);
    const [packageUpdatedForRA, setPackageUpdatedForRA] = useState<boolean>(false);
    const [themesLoaded, setThemesLoaded] = useState(false);
    const [currentThemeIndex, setCurrentThemeIndex] = useRecoilState(currentThemeIndexAtom);
    const [ticketIsLoading, setTicketIsLoading] = useRecoilState(ticketLoaderAtom);
    const setTicket = useSetRecoilState(ticketStateAtom);
    const setTicketValidateDisable = useSetRecoilState(ticketValidateDisableStateAtom);
    const [aidesLocales, setAidesLocales] = useRecoilState(aidesLocalesListStateAtom);
    const { recoAppointmentDate } = getAppointmentDetails();

    const AlertSwal = withReactContent(Swal);
    const flowId = localStorage.getItem('flowId');

    if (!auditSimulator) {
        storageService.setSim(TemplateSimulator);
    }

    /**
     * obtient les données de rénovation d'ampleur de ce customerFlow, via l'api.
     *
     * @returns les données de renovation d'ampleur, ou undefined si elle n'existent aps en base.
     */
    const loadRenoDAmpleur = async (): Promise<DossierRenoDAmpleur | undefined | null> => {
        try {
            if (flowId) {
                const response: apiFlow.FlowOutputDetail = await apiService.getFlow(flowId);
                return response.dossierRenoDAmpleur;
            } else {
                return undefined;
            }
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect N° 1,
    // on charge les prefs de l'utilisateur,
    // On charge la rénovation d'ampleur depuis la base (si ca existe)
    useEffect(() => {
        // console.log('useEffect N°1');
        // Set user pref (or keep default.)
        (async () => {
            const aidePrevisite: ticketService.Aide | undefined = await ticketService.createAidePrevisit();
            const aideCo: ticketService.Aide | undefined = await ticketService.createAideCommerciale();
            // let fraisRenAmp: ticketService.Aide | undefined = undefined;
            // let aideFraisRenAmp: ticketService.Aide | undefined = undefined;

            // Chargement des information de reno d'ampleur :
            // 2 cas :
            // 1) audit type MAR == true
            // 2) audit type MAR == false.

            // On a peut être calculé et enregistré les données de rénovation d'ampleur =>.
            let reno = await loadRenoDAmpleur();
            let renoParams: RenovationDAmpleurParams | undefined | null = reno?.renoDampleurParams;

            if (storageService.isCurrentAuditMar()) {
                // si on est en parcours MAR, on devrait les avoir et elle devraient être correctes.
                // sinon on crash
                if (reno === undefined || reno === null || !reno.renoDampleurParams) throw Error("Paramètre de rénovation d'ampleur inexistants.");
                if (!reno.renoDampleurParams.elligible) throw Error("Inélligible à la rénovation d'ampleur.");

                // WARNING !
                // dans ce cas l'audit vient peut être de changer, donc les information de reno d'ampleur initiale peuvent être différentes.

                const audit = storageService.stepListToAuditAndClient().audit;
                let newReno = aideRenoDAmpleur.isElligible(audit);
                if (!newReno.elligible) {
                    // throw Error("Inélligible à la rénovation d'ampleur.");
                    showNotElligible();
                    setTicketValidateDisable(true);
                } else {
                    newReno.marShouldContactCustomer = true;
                    const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
                    newReno = await aideRenoDAmpleur.upgradeReno(newReno, allThemesClone);

                    // on calcule les aides Renovation d'ampleur (première  partie)
                    //fraisRenAmp = ticketService.createFraisRenoDAmpleur(reno, renoDAmpleurParams);
                    //aideFraisRenAmp = ticketService.createAideFraisRenoDAmpleur(reno, renoDAmpleurParams);
                }
                const flowId = localStorage.getItem('flowId');
                // On met à jour en base.
                if (flowId) {
                    apiDRA.updateDra(reno.id, { renoDampleurParams: newReno });
                }
                reno.renoDampleurParams = newReno;
                renoParams = newReno;
            } else {
                // On est en parcours initial (audit non mar)
                if (reno === undefined || reno === null || Object.keys(reno).length === 0 || !reno.renoDampleurParams) {
                    // Non, on les calcule l'elligibilité pour la première fois.

                    const audit = storageService.stepListToAuditAndClient().audit;
                    const renoDampleurParams: RenovationDAmpleurParams = aideRenoDAmpleur.isElligible(audit);

                    //prettyLog('isElligible', reno);
                    // LA
                    // - soit reno.elligible === false et reno.marShouldContactCustomer === false
                    // - soit reno.elligible === true  et reno.marShouldContactCustomer === undefined;
                    setDraParams(renoDampleurParams);
                    renoParams = renoDampleurParams;
                } else {
                    if (reno === undefined || reno === null || !reno.renoDampleurParams) throw Error("Paramètre de rénovation d'ampleur inexistants.");
                    if (!renoParams || renoParams === undefined) throw Error("Paramètre de rénovation d'ampleur inexistants.");
                }
            }
            // On enregistre ce résultat en tant qu'etat.
            //console.log('useEffect N°1 - setRenoDAmpleur - elligible = ', reno.elligible);
            setDraParams(renoParams);

            // Si on les fait une par une ca marche pas !
            // Car On repart du _même_ ...aidesLocales
            // faut les faire d'un coup.
            setAidesLocales({
                ...aidesLocales,
                [ticketService.AIDE_REMISE_COMMERCIALE_NAME]: aideCo,
                [ticketService.AIDE_REMISE_PREVISITE]: aidePrevisite,
                //[ticketService.FRAIS_EXPERTISE_RENO_AMPLEUR]: fraisRenAmp,
                //[ticketService.AIDE_FRAIS_EXPERTISE_RENO_AMPLEUR]: aideFraisRenAmp,
            });

            // Puis des préférences qui vont déclencher le prochain use effect.
            // on veut que la remise co soit déjà présente.
            const pref = (await api.getMyself()).preferences;
            if (pref && pref.themeFilter) {
                setPrefThemeFilter(pref.themeFilter as FilterThemePreference);
            } else setPrefThemeFilter(defaultFilterThemePreference);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect N° 2
    useEffect(() => {
        // console.log('useEffect N°2');
        // On attends que les préférences soient chargées avant de faire les filtrage (On attend le useEffect 1)
        if (!prefThemeFilter) return;

        (async () => {
            // console.log('useEffect N°2 - dedans');
            const audit = storageService.stepListToAuditAndClient().audit;

            let themes = await themesService.getThemes();
            if (!themes || themes.length === 0) {
                showMessages([{ message: 'Aucun Packages disponibles', title: 'Erreur' }]);
                setThemesLoaded(true);
                return;
            }

            await filterAL(themes);
            await filterTva(audit, themes);
            themes = await filterByUserPref(prefThemeFilter, themes);
            themes = await filterPreconisations(audit, themes);
            // ici, on vient d'appliquer le filtrage et le dimenstionnement indivuduel des package.
            // mais la selection de la pac AE (le cas échéant) ne tient pas encore compte des packages isolations déjà sélectionnés.
            // Donc on l'appel avec undefeined, parce que ce 'nest pas un changement de package qui l'a déclenché.
            await redimenstionnerLaPacEnCasDisolation(themes, undefined);

            // On créé une eventuelle aide mma selon les packages sélectionnés. Qui est peut être undefined.
            const aideMma: ticketService.Aide | undefined = await ticketService.createAideMMA(themes);
            const aidePv: ticketService.Aide | undefined = await ticketService.createAideReventeSurplus(themes);

            setAidesLocales({
                ...aidesLocales,
                [ticketService.AIDE_REMISE_MMA_NAME]: aideMma,
                [ticketService.AIDE_PANNEAU_SOLAIRE_NAME]: aidePv,
            });

            setCurrentThemeIndex(0);
            setThemes(themes);

            setThemesLoaded(true);
            //console.timeEnd('chargement useEffect 2');
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefThemeFilter]);

    // useEffect N° 3
    useEffect(() => {
        // console.log('useEffect N°3');
        // on attend que les packages soient chargés avant d'appeler l'api pour créer le ticket (on attend le useEffect 2)
        if (!themesLoaded) return;
        (async () => {
            //console.log('useEffect N°3 - dedans');
            setTicketIsLoading(true);
            // initialise le ticket
            const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
            // On fait un appel API, car c'est le but ici !
            await updateTicket(allThemesClone, setThemes, undefined, setTicket, aidesLocales, setAidesLocales);

            // La on a chargé les themes/packages dans le use effect 2.
            // Maintenant en fonction de l'état dans le parcours renovation d'ampleur, on modifie les packages sélectionnés :
            if (storageService.isCurrentAuditMar()) {
                if (draParams && draParams.elligible) UpdatePackageFor(aideRenoDAmpleur.packageSelectorForRenoDAmpleurMar);
                //else throw Error("Paramètre de rénovation d'ampleur inexistants.");
            } else {
                // Si on est dans le aprcours normal, et que marShouldContactCustomer n'est pas défnit,
                // c'est que l'utilisateur n'a aps encore répondu à la question si il souhaite être contacté par u MAR.
                if (draParams && draParams.elligible) {
                    if (draParams.marShouldContactCustomer === undefined) {
                        askIfMaShouldContactCustomer();
                    }
                    if (draParams.marShouldContactCustomer === true) {
                        UpdatePackageFor(selectorForRenoDAmpleur);
                    }
                }
                // SI il n'est pas elligible, ou qui'l ne souhaite pas être contacté par un mar, on ne met pas à jour les selections de package.
            }

            setTicketIsLoading(false);
        })();
        // on passe aide dans le tableau de dépendances car il faut mettre à jour le ticket lorsque les aides changent.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themesLoaded]);

    const changeThemes = (themeIndex: number) => {
        setCurrentThemeIndex(themeIndex);
    };

    const isLoading = () => !themesLoaded || ticketIsLoading;

    const createContentForAskIfMaShouldContactCustomer = (reno: RenovationDAmpleurElligible): JSX.Element => {
        return (
            <>
                <p>
                    Vous pouvez bénéficier d'une prise en charge de <span className="fw-bold">{reno.renoTextes.priseEnCharge}</span> des dépenses (
                    <span className="fw-bold">{reno.renoTextes.priseEnChargeBonusPassoire}</span> si votre habitation est classée F/G) pour un projet de travaux
                    d'amélioration de votre habitat (isolation, chauffage, ouvertures, etc.).
                </p>
                <div className="div-as-p">
                    Dans la limite d'un plafond de dépenses éligibles (HT) de :
                    <ul className="bullet-list">
                        <li>
                            <span className="fw-bold">{reno.plafonds.gain2classes}</span> pour un gain de 2 classes,
                        </li>
                        <li>
                            <span className="fw-bold">{reno.plafonds.gain3classes}</span> pour un gain de 3 classes,
                        </li>
                        <li>
                            <span className="fw-bold">{reno.plafonds.gain4classes}</span> pour un gain de 4 classes.
                        </li>
                    </ul>
                    Ce projet de rénovation nécessitera un reste à charge de <span className="fw-bold">{reno.renoTextes.resteACharge}</span>. Ce montant peut
                    être financé par un prêt à la consommation si nécessaire (ou un éco-prêt à taux zéro). Si vous êtes interessé(e) par cette aide, veuillez
                    valider cette étape avec votre conseiller.
                </div>
                <br />
                <p>
                    Un Accompagnateur Rénov' prendra contact avec vous afin d'ouvrir votre dossier auprès de MaPrimeRénov' et vous accompagnera tout au long de
                    ce projet. Les frais de <span className="fw-bold">{reno.renoTextes.fraisExpertises}</span> liés à l'intervention de votre Accompagnateur
                    Rénov' sont également pris en charge à hauteur de "<span className="fw-bold">{reno.renoTextes.aideFraisExpertise}</span>" par MaPrimeRénov'.
                </p>
                <br />
                <p>
                    Votre conseiller SOGYS vous accompagnera tout au long de votre projet et vous fera parvenir en temps voulu les devis pour la réalisation des
                    travaux préconisés.
                </p>
                <br />
                <p>
                    À ce stade, nous vous recommandons l'installation d'une centrale photovoltaïque pour produire votre propre énergie et réduire vos factures
                    d'électricité jusqu'à 70%.
                </p>
                <br />
                <p>Je souhaite recevoir la visite d'un Accompagnateur Rénov'.</p>
            </>
        );
    };

    const showNotElligible = (): void => {
        AlertSwal.fire({
            title: <strong>Vous n'êtes pas éligible à l'aide "Rénovation d'Ampleur"</strong>,
            html: (
                <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>
                    <p>Suite aux modifications apportées à votre audit, il semble que vous ne soyez plus éligible à l'aide "Rénovation d'Ampleur".</p>
                </div>
            ),
            width: 960,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Fermer',
            reverseButtons: true,
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'btn btn-continue min-width',
                cancelButton: 'btn btn-retour min-width',
            },
        });
    };

    const askIfMaShouldContactCustomer = async (): Promise<void> => {
        // Si aps d'info d'elligibilité, on quitte.
        if (!draParams || draParams.elligible === false) return;
        // Si c'est pas undefined, ca veut dire qu'on a déjà montré la popup
        if (draParams.marShouldContactCustomer !== undefined) return;

        setTicketIsLoading(true);
        // On va afficher une popup pour dire que le client est elligible.
        // Et qu'il dise si il veut être contacté par un MAR.

        const content = createContentForAskIfMaShouldContactCustomer(draParams);

        AlertSwal.fire({
            title: <strong>Vous êtes éligible à l'aide "Rénovation d'Ampleur"</strong>,
            html: <div style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}>{content}</div>,
            width: 960,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            reverseButtons: true,
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'btn btn-continue min-width',
                cancelButton: 'btn btn-retour min-width',
            },
        }).then((response) => {
            (async () => {
                try {
                    // Update reno d'ampleur  :
                    const newRenoDampleur: RenovationDAmpleurElligible = { ...draParams, marShouldContactCustomer: response.isConfirmed };
                    setDraParams(newRenoDampleur);

                    const allThemesClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;
                    const upgradedRenoDAmpleur = await aideRenoDAmpleur.upgradeReno(newRenoDampleur, allThemesClone);

                    //console.log(JSON.stringify(upgradedRenoDAmpleur.scenariosExtText, null, 2));
                    // On sauvegarde l'état en base.
                    const flowId = localStorage.getItem('flowId');
                    if (flowId) {
                        await api.sendRenovationAmpleur(flowId, upgradedRenoDAmpleur);
                    }

                    // en cas d'erreur utiliser ceci pour créer un nouveau TU
                    // const audit = storageService.stepListToAuditAndClient().audit;
                    // const input = {
                    //     // comble non aménagés
                    //     ceilingType: { value: audit.ceilingType.value },
                    //     // ... Avec comble non isolés
                    //     ceilingInsulationPresence: { value: audit.ceilingInsulationPresence.value },
                    //     // comble aménagés
                    //     //ceilingType: { value: '2' },
                    //     //... avec rampant isolés
                    //     //rampantIsolated: { value: true },

                    //     // type de plancher
                    //     // 0 : terre plein, 3 : sous sol
                    //     floorType: { value: audit.floorType.value },
                    //     //plancher isolé
                    //     floorInsulationPresence: { value: audit.floorInsulationPresence.value },
                    //     // isolation des murs
                    //     exteriorWallInsulationPresence: { value: audit.exteriorWallInsulationPresence.value },
                    //     // extérieur true, intérieur false.
                    //     exteriorWallInsulationExterior: { value: audit.exteriorWallInsulationExterior.value },
                    //     // epaisseur intérieur >=12 ou non
                    //     exteriorWallInsulationThickness: { value: audit.exteriorWallInsulationThickness.value },
                    //     // elec == 0, bois == 1, pac == 3, combustible == 4
                    //     heaterType: { value: audit.heaterType.value },
                    //     //chauffe eau thermo
                    //     heaterHotWaterThermodynamic: { value: audit.heaterHotWaterThermodynamic.value },
                    //     // vmc double flux == 5.
                    //     ventilationType: { value: audit.ventilationType.value },
                    // };
                    // console.log('input ' + JSON.stringify(input, null, 2));

                    // Si c'est aps confirmé on arrête tout.
                    if (response.isDenied || response.isDismissed) {
                        setTicketIsLoading(false);
                        return;
                    }
                    // On met à jour les packages, pour ce cas.
                    UpdatePackageFor(selectorForRenoDAmpleur);
                } catch (ex) {
                    console.error('Erreur lors de la mise à jour des packages', ex);
                }
            })();
        });
    };

    const selectorForRenoDAmpleur: PackageSelector = (themes) => {
        // On fait un gros clone profond, pour que tout soit en lecture écriture.
        const allThemeClone: Array<themesService.Theme> = JSON.parse(JSON.stringify(themes)) as Array<themesService.Theme>;

        // On garde les PV ET le changement comportemental.
        for (const theme of allThemeClone) {
            if (theme.themeType !== "Economie d'énergie") continue;
            if (!theme.subThemes) continue;
            for (const subTheme of theme.subThemes) {
                const type = subTheme.subThemeType as themesService.SubThemeType;
                if (type !== 'Photovoltaïque' && type !== 'Changement comportemental') {
                    // DAns ce qu'il reste, on décoche tout !
                    // TODO : sauf MMA Si il y a une remiseMMA.
                    for (const pack of subTheme.packages) {
                        pack.applicable = false;
                    }
                }
            }
        }
        return allThemeClone;
    };

    const UpdatePackageFor = async (selector: PackageSelector): Promise<void> => {
        // return;
        if (!draParams || draParams.elligible === false) return;
        if (draParams.marShouldContactCustomer === false) return;

        // On ne veut faire ca qu'une seule fois !
        if (packageUpdatedForRA) return;
        setPackageUpdatedForRA(true);

        setTicketIsLoading(true);

        // On applique le selecteur de package.
        const allThemesClone = selector(themes);
        //const ticketClone: ticketService.TicketData = JSON.parse(JSON.stringify(ticket)) as ticketService.TicketData;

        const applied = await applyPackageSelectorSpecialRules(allThemesClone, undefined);
        showMessages(applied.messages);
        setTicketValidateDisable(applied.disableValidation);

        // forcer le re-render
        await updateTicket(allThemesClone, setThemes, undefined, setTicket, aidesLocales, setAidesLocales, aidesLocalesBuilder, {
            renoDAmpleur: draParams,
            params: draTarget,
        });
        setTicketIsLoading(false);
    };

    const aidesLocalesBuilder: AidesLocalesBuilder = async (
        aidesLocales: ticketService.AidesLocalesList,
        allThemesClone: Array<themesService.Theme>,
        ticket: ticketService.TicketData,
        context: DraContext
    ): Promise<ticketService.AidesLocalesList> => {
        const aides: ticketService.AidesLocalesList = {
            ...aidesLocales,
            [ticketService.AIDE_PANNEAU_SOLAIRE_NAME]: await ticketService.createAideReventeSurplus(allThemesClone),
            [ticketService.AIDE_REMISE_MMA_NAME]: await ticketService.createAideMMA(allThemesClone),
            [ticketService.AIDE_RENO_AMPLEUR]: ticketService.createAideRenoDAmpleur(context, ticket),
        };

        return aides;
    };

    const showMessages = async (messages: Array<{ title: string; message: string }>): Promise<void> => {
        for (const mess of messages) {
            const swal: SweetAlertOptions = {
                title: mess.title,
                html: mess.message,
                showConfirmButton: true,
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            };
            await AlertSwal.fire(swal);
        }
    };

    const isAppointmentToday = !storageService.isCurrentAuditMar() && isDeadLineAideOk();
    const islocal = isLocal();

    return (
        <div className="container container-audit">
            <h1 className="main-title-mini">Recommandation</h1>
            <SimulatorContainer />
            <div className="row pb-5">
                <div className="col-12 col-md-9">
                    {themesLoaded && <Themes changeThemes={changeThemes} currentThemeIndex={currentThemeIndex} themes={themes} />}
                </div>
                <div className="col-12 col-md-3">
                    <TicketComponent />
                </div>
            </div>
            {!isLoading() && isAppointmentToday && !islocal && <Countdown date={recoAppointmentDate} />}
            {isLoading() && (
                <div className="loading">
                    <SVGLoader height={200} width={200} />
                </div>
            )}
        </div>
    );
};

export default AuditSimulator;
