import { useEffect, useState } from 'react';
import { LabelledString } from './TypeHelper';
import AuditTemplate from '../../assets/json/template-audit.json';
import * as storageService from '../../services/localStorageService';

// #region Step8
/**
 * Liste des images de l'étape 8 en base64 à purger
 */
export const picturesNames = [
    'buildingPictureGeneral',
    'buildingPictureNorth',
    'buildingPictureEast',
    'buildingPictureSouth',
    'buildingPictureWest',
    'buildingPictureOther',
    'buildingPictureBalcony',
    'buildingPicturesSolarMask',
    'buildingPicturesheaterGeneratorPower',
    'buildingPicturesheaterFeature',
    'buildingPicturesenergyWater',
    'buildingPictureRoofing',
    'buildingPicturesPathology1',
    'buildingPicturesPathology2',
    'buildingPicturesPathology3',
    'buildingPicturesPathology4',
    'buildingPicturesPathology5',
    'buildingPicturesPathology6',
    'buildingPicturesPathology7',
    'buildingPictureCeilingInsulationPresence',
    'buildingPictureFloorInsulationPresence',
    'buildingPictureExteriorWall',
    'buildingPictureUnheatedRoom',
    'buildingPictureDoor1',
    'buildingPictureDoor2',
    'buildingPictureDoorUnheatedRoom1Presence',
    'buildingPictureBuildingWindows1',
    'buildingPictureBuildingWindows2',
    'buildingPictureDoorWindows1',
    'energyCostsElectricInvoice1',
    'energyCostsElectricInvoice2',
    'energyCostsElectricInvoice3',
    'energyCostsGasInvoice1',
    'energyCostsGasInvoice2',
    'energyCostsGasInvoice3',
] as const;

export type PictureName = (typeof picturesNames)[number];

export type Picture = {
    desc: string;
    /** La valeur en base64 */
    value?: string | undefined;
    /** L'url de l'image */
    url?: string | undefined;
};

export type Step8Pictures = Record<PictureName, Picture>;

/**
 * Retourne les photos de l'étape 8 et la validité de l'audit
 * @param audit l'audit pour lequel on veut les photos, si undefined, l'audit sera chargé depuis le local storage
 * @returns les photos de l'étape 8 et la validité de l'audit
 */
export const getStep8 = (audit: any | undefined = undefined): { photos: Step8Pictures; isValid: boolean } => {
    if (!audit) audit = storageService.getAudit();
    const step8 = { ...audit.step8 };
    const isValid = audit.step8.isValid;
    // Suppression de la valeur isValid, pour transformer step8 en Step8Pictures
    delete step8.isValid;

    const photos: Step8Pictures = step8;
    return { photos, isValid };
};

// #endregion

// #region calcul de surface
type presenceSS = 'avecSS' | 'horsSS';
type presenceCA = 'avecCA' | 'horsCA';

export type LevelCountMode = `${presenceSS}${presenceCA}`;

/**
 * retourne le nombre de niveaux suivant differentes règles.
 * @param flatAudit l'audit pour lequel on veut le mode.
 * @param mode par défaut, c'est audit.floorCount directement
 * @returns le nombre de niveaux selon le mode de calcul.
 */
export const getLevelCount = (flatAudit: any, mode: LevelCountMode = 'avecSSavecCA'): number => {
    if (!flatAudit || flatAudit.floorCount === undefined) {
        return 1;
    }
    // audit.floorCount correspond à avecSSavecCA
    let floorCount = +flatAudit.floorCount.value;

    switch (mode) {
        case 'horsSShorsCA':
            // Typiquement pour la surface des murs, hauteur de la goutière
            // supprimer les combles aménagés.
            if (flatAudit && flatAudit.ceilingType && flatAudit.ceilingType.value_label === 'Combles aménagés') floorCount--;
            // supprimer le sous sol.
            if (flatAudit && flatAudit.floorType && flatAudit.floorType.value_label === 'Plancher sur sous sol') floorCount--;
            break;

        case 'horsSSavecCA':
            // supprimer le sous sol.
            // Cardonnel
            if (flatAudit && flatAudit.floorType && flatAudit.floorType.value_label === 'Plancher sur sous sol') floorCount--;

            break;

        case 'avecSShorsCA':
            // supprimer les combles aménagés.
            if (flatAudit && flatAudit.ceilingType && flatAudit.ceilingType.value_label === 'Combles aménagés') floorCount--;
            break;

        case 'avecSSavecCA':
        default:
            // rien, c'est le cas par défaut.
            break;
    }
    // console.log('floor count (mode : ' + mode + ') : ' + floorCount);
    return floorCount;
};

const surfaceDunNiveau = (flatAudit: any): number => {
    if (flatAudit.buildingPlans === undefined) return 0;
    let surfaceNiv0 = 0;
    let shape = flatAudit.buildingPlans.value[0];

    // Le calcul de la longueur des murs est plus simple qu'il n' parait.
    // Pour les formes suivantes rectangle, L, T, Y et X, c'est comme le rectangle.
    // Pour autre, faute de mieux, on prend, comme le rectangle.

    const baseRect = +flatAudit.buildingWidth.value * +flatAudit.buildingLength.value;

    switch (+shape.shape) {
        case 1: // 'Rectangulaire',
            surfaceNiv0 = baseRect;
            break;
        case 11: // 'En L',
            surfaceNiv0 = baseRect - shape.l1 * (flatAudit.buildingWidth.value - shape.l2);

            break;
        case 12: // 'En L inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2;
            break;
        case 21: // 'En T',
        case 22: // 'En T inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4;
            break;
        case 41: // 'En Y',
        case 42: // 'En Y inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4 - shape.l5 * shape.l6;
            break;
        case 51: // 'En X',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l3 * shape.l4 - shape.l5 * shape.l6 - shape.l7 * shape.l8;
            break;

        case 31: // 'En U',
        case 32: // 'En U inversé',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2;
            break;

        case 52: // 'En H',
            surfaceNiv0 = baseRect - shape.l1 * shape.l2 - shape.l5 * shape.l7;
            break;
        case 99: // 'Autre forme',
            surfaceNiv0 = baseRect;
            break;
        default:
            surfaceNiv0 = 0;
            break;
    }
    if (isNaN(surfaceNiv0)) return baseRect;
    return surfaceNiv0;
};

const perimetreDunNiveau = (flatAudit: any): number => {
    if (flatAudit.buildingPlans === undefined) return 0;
    let perimetreNiv0 = 0;
    let shape = flatAudit.buildingPlans.value[0];

    // Le calcul de la longueur des murs est plus simple qu'il n' parait.
    // Pour les formes suivantes rectangle, L, T, Y et X, c'est comme le rectangle.
    // Pour autre, faute de mieux, on prend, comme le rectangle.

    switch (+shape.shape) {
        case 1: // 'Rectangulaire',
        case 11: // 'En L',
        case 12: // 'En L inversé',
        case 21: // 'En T',
        case 22: // 'En T inversé',
        case 41: // 'En Y',
        case 42: // 'En Y inversé',
        case 51: // 'En X',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width;
            break;

        case 31: // 'En U',
        case 32: // 'En U inversé',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width + shape.l2 + shape.l2;
            break;

        case 52: // 'En H',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width + shape.l2 + shape.l2 + shape.l7 + shape.l7;
            break;
        case 99: // 'Autre forme',
            perimetreNiv0 = shape.length + shape.length + shape.width + shape.width;
            break;
    }
    if (isNaN(perimetreNiv0)) return 0;
    return perimetreNiv0;
};
export const getSurfaceDesMurs = (flatAudit: any): number => {
    if (flatAudit.buildingPlans === undefined) return 0;

    let height = flatAudit.buildingPlans.value[0].height;

    let contourNiv0 = perimetreDunNiveau(flatAudit);
    // on calcule le nombre de niveaux :
    const floorCount = getLevelCount(flatAudit, 'horsSShorsCA');

    // Le contour du niveau 0 est considéré comme le même que les autres niveaux.
    // La hauteur des murs est dans shape
    const surfaceMurs = floorCount * contourNiv0 * height;
    // console.log('surface des murs (fnêtre incluses) = ' + surfaceMurs);
    const surfaceMursHorsOuvrants = surfaceMurs - getSurfaceDesVitrages(flatAudit) - getSurfaceDesPortes(flatAudit);

    return surfaceMursHorsOuvrants;
};

type orientationFenetre = 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SO' | 'O' | 'NO';
/**
 * Type pour les fenetres.
 * @example
 * {
 *     "N":{ "count":2, "surface":2 },
 *     "S":{ "count":2, "surface":4 }
 *  } */
type AuditFenetre = { [key in orientationFenetre]?: { count: number; surface: number } };

export const getSurfaceDesVitrages = (flatAudit: any): number => {
    let surface = 0;
    surface += getSurfaceDesFenetres(flatAudit);
    surface += getSurfaceDesPortesFenetres(flatAudit);
    return surface;
};
export const getSurfaceDesFenetres = (flatAudit: any): number => {
    let surface = 0;

    // fenêtre de type 1
    const F1 = flatAudit.buildingWindows1details?.value as AuditFenetre | undefined;
    // fenêtre de type 2
    const F2 = flatAudit.buildingWindows2details?.value as AuditFenetre | undefined;

    // toutes les fenêtres
    const FFF = [F1, F2].filter((f) => f !== undefined);

    // Je boucle sur toutes les fenetres,
    for (const f of FFF) {
        for (const or in f) {
            const F = f[or as orientationFenetre];
            if (F === undefined || F.count === undefined || F.surface === undefined) continue;
            surface += F.count * F.surface;
        }
    }

    // console.log('surface des fenêtres = ' + surface);
    return surface;
};
export const getSurfaceDesPortesFenetres = (flatAudit: any): number => {
    let surface = 0;

    // fenêtre de type 3
    const F3 = flatAudit.buildingDoorWindows1details?.value as AuditFenetre | undefined;
    if (!F3) return 0;

    // Je boucle sur toutes les fenetres,
    for (const or in F3) {
        const F = F3[or as orientationFenetre];
        if (F === undefined || F.count === undefined || F.surface === undefined) continue;
        surface += F.count * F.surface;
    }

    // console.log('surface des fenêtres = ' + surface);
    return surface;
};

/**
 * Retourne le nombre de fenêtre qu'il faut pour faire un quart (25%) de la surface des vitres (fenetre + portes fenêtres).
 * @param flatAudit l'audit pour lequel on veut le nombre de fenetres
 * @returns le nombre de fenetres qu'il faut pour faire un quart de la surface
 */
export const getSurfaceDesFenetresX025 = (flatAudit: any): number => {
    let surface = 0;
    // Pour la surface totale des fenetre, on prend bien les portes fenêtres dedans.
    const surfaceVitrages = getSurfaceDesVitrages(flatAudit);
    const surfaceFenetres = getSurfaceDesFenetres(flatAudit);
    const surfaceCible = surfaceVitrages * 0.25;
    let result = 0;

    // Comme on ne prendra que des fenêtres pour obtenir une surface de 1/4 des (fenêtres + portes fenêtres),
    // Et comme notre algo prend un quart de chaque type de fenetre (pas de porte fenetre), arrondi au sup.
    // on doit trouver un autre ratio :
    const ratio = surfaceCible / surfaceFenetres;

    // fenêtre de type 1
    const F1 = flatAudit.buildingWindows1details?.value as AuditFenetre | undefined;
    // fenêtre de type 2
    const F2 = flatAudit.buildingWindows2details?.value as AuditFenetre | undefined;
    // fenêtre de type 3. On ne prend pas en compte les portes fenêtres.
    // const F3 = flatAudit.buildingDoorWindows1details?.value as AuditFenetre | undefined;

    // toutes les fenêtres
    const FFF = [F1, F2].filter((f) => f !== undefined);

    // L'approche retenue pour l'instant est très basique :
    //    On prend 'ratio' de chaque type de fenetre, arrondi au sup.
    //    Si la surface est supérieure à la surface cible, on retourne le nombre de fenetres.
    //    Sinon, on continue.
    // problème : selon l'ordre de rangement des fenetre, et si il y a peu de fenêtre, on peut vite dépasser.
    // avec l'exemple au dessus on est a 12m² on veut 3m² de fenêtre, on se retrouve avec 1x2m²(=2 <25%) puis 1x4m2 (=2+6 =50%)
    // Si les fenêtre avaient été triée dans l'autre sens, on aurait 4m² avec une seule fenêtre.

    // Un algo qui trouverait la meilleure combinaison de fenêtre pour dépasser 25% le moins possible me semble compliqué.

    // Je boucle sur toutes les fenetres,
    for (const f of FFF) {
        for (const or in f) {
            const F = f[or as orientationFenetre];
            if (F === undefined || F.count === undefined || F.surface === undefined) continue;
            const c = Math.ceil(F.count * ratio); // en gros on prends un quart de chaque type de fenetre, arrondi au sup.
            surface += c * F.surface;
            result += c;
            // dès qu'on dépasse, on retourne le nombre de fenetres.
            if (surface >= surfaceCible) {
                console.log('surface selectionnée : ' + surface + ' >= le quart :' + surfaceCible);
                return result;
            }
        }
    }

    // normalement, comme on arrondi au sup. Et qu'on a calculé un ratio adéquat.
    // on ne devrait jamais passer par ici.
    console.log('LES MATHS SE TROMPENT, on est passé par la quand même.');
    console.log('surface selectionnée : ' + surface + ' >= le quart :' + surfaceCible);
    return result;
};

export const getSurfaceDesPortes = (audit: any): number => {
    // TODO, visiblement on ne sait aps calculer la surface des portes.
    const SURFACE_D_UNE_PORTE = 2.0; // standard arbitraire en m² pour une porte de 90cm X 200cm
    let porteCount = 1;

    if (audit && audit.buildingDoor2Presence && audit.buildingDoor2Presence.value === true) porteCount++;

    return porteCount * SURFACE_D_UNE_PORTE;
};
/** Alias surface rempant */
export const getSurfaceToiture = (flatAudit: any): number => {
    // Surface à isoler :
    // surface = Sruface d'un niveau / cosinus(inclinaison)
    let cos = 1;
    if (flatAudit.tilt !== undefined) {
        const angleDegres = +flatAudit.tilt.value_label.slice(0, -1);
        const angleRad = angleDegres * (Math.PI / 180);
        // Si l'angle fait 90° (par pourrissage des données), on a cos = 0, et crash à la division.
        cos = Math.cos(angleRad);
    }
    if (cos === 0) return 0; // ou autre valeur à la con
    return surfaceDunNiveau(flatAudit) / cos;
};
/** Alias surface rempant */
export const getSurfaceRampants = getSurfaceToiture;

// Isoler les combles aménagés === isoler les rempants === isoler le toit.
// isoler les combles non aménagés === isoler le plancher des combles (on a pas l'intention de les chauffer.)

export const getSurfacePlancherComble = (flatAudit: any): number => {
    return surfaceDunNiveau(flatAudit);
};
export const getSurfacePlancher = (flatAudit: any): number => {
    // if (!audit || !audit.SHON || !audit.floorCount || audit.floorCount.value === 0) return 0;
    // return +audit.SHON.value / +audit.floorCount.value;
    return surfaceDunNiveau(flatAudit);
};
// #endregion

// #region divers

export const canInstallPV = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label !== 'Appartement' && audit.projectType?.value_label !== 'Immeuble collectif';
};
export const isAppartement = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label === 'Appartement';
};
export const isMaison = (audit: any): boolean => {
    return audit.projectType && audit.projectType?.value_label === 'Maison individuelle';
};

// #endregion

// TODO, tout ce qu'il y a partir de la devrait se trouver ailleurs.
// Ce sont des outils génériques. Ce fichier ne devrait contenir que des outils liés à l'audit.

// Unique keys
export const randomKeyGen = (): string => {
    let result = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 15; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
};

// Modal counter
export const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

export const removeItemAtIndexWithCopy = <T>(arr: Array<T>, index: number): Array<T> => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

// Create dynamic select list
export const createDynamicList = (min: number, max: number): LabelledString[] => {
    const list = [];

    for (let i = min; i <= max; i++) {
        const item: LabelledString = {
            label: `${i}`,
            value: `${i}`,
        };

        list.push(item);
    }

    return list;
};

// Extract number from string
export const extractNumberFromString = (input: string): number | null => {
    if (typeof input !== 'string' || !input) {
        return null;
    }

    const parts = input.split(' ');
    let number: number | null = null;

    for (const part of parts) {
        if (!isNaN(parseInt(part, 10))) {
            number = parseInt(part, 10);
            break;
        }
    }

    return number;
};

export const formatNumberWithLeadingZero = (num: number) => {
    if (num >= 1 && num <= 9) {
        return `0${num}`;
    } else {
        return num.toString();
    }
};

/** recursively log a json object, with collapsible output
 * WARNING : this log is UGLY for perf, do not use massively.
 */
export const prettyLog = (key: string | undefined, value: unknown, open = false) => {
    if (value === null) {
        console.log(key ? key + ' : null' : 'null');
        return;
    }
    if (value === undefined) {
        console.log(key ? key + ' : undefined' : 'undefined');
        return;
    }

    if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'symbol') {
        console.log(key ? key + ' : ' : '', value);
        return;
    }
    if (typeof value === 'string') {
        console.log(key ? key + ' : ' : '', '"' + value + '"');
        return;
    }
    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            open ? console.group(key ? key + ' : [' : '[') : console.groupCollapsed(key ? key + ' : [' : '[');
            const array = value as Array<unknown>;
            for (const elem of array) {
                // recursive call
                prettyLog(undefined, elem, open);
            }
            console.log(']');
        } else {
            open ? console.group(key ? key + ' : {' : '{') : console.groupCollapsed(key ? key + ' : {' : '{');
            for (const subkey of Object.keys(value as object)) {
                const elem = (value as Record<string, unknown>)[subkey];
                // recursive call
                prettyLog(subkey, elem, open);
            }
            console.log('}');
        }

        console.groupEnd();
    }
};
export const removeItemAtIndex = <T>(arr: Array<T>, index: number): Array<T> => {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const emptyAudit = (): void => {
    const audit = storageService.getAudit();
    if (!audit) return;

    // Keep step1 and currentStep
    const step1 = audit.step1;
    const currentStep = audit.currentStep;

    // Reset with empty template
    const template = AuditTemplate;

    // Restore step1 and important properties
    template.step1 = step1;
    template.currentStep = currentStep;

    // Empty DPE and GES
    template.step1.etiquetteDPE.value = '';
    template.step1.etiquetteGES.value = '';

    // Save new audit
    storageService.setAudit(template);
};
