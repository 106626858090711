import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FlowStep, flowStepNames, initialStepProperties } from './DetailEnums';
import * as storageService from '../../../services/localStorageService';
import * as apiFlow from '../../../services/apiFlowService';
import * as api from '../../../services/apiParticulierService';
import * as routes from '../../../routing/paths';
import Template from '../../../assets/json/template-audit.json';
import TemplateSimulator from '../../../assets/json/template-simulator.json';
import TemplatePreVisit from '../../../assets/json/template-previsite.json';
import { format } from 'date-fns';
import { Back } from '../../../components/Back/Back';
import { useSetRecoilState } from 'recoil';
import { themesStateAtom } from '../../../services/Recoil/Atom/Themes.atom';
import { EMPTY_THEMES } from '../../../services/calculs/theme';
import { currentRouteAtom } from '../../../services/Recoil/Atom/PackageContext.atom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { FormRow } from '../../../components/FormRow/FormRow';
import DossierRenoDampleur from './DossierRenoDampleur';
import DocumentList, { DocumentRow } from '../../../components/Document/DocumentList';
import { KnownFlowDocType, knownAssociatedDocTypeToString } from '../../../services/apiFlowService';

// Style
import '../list-audit/ListAudit.scss';

// Icon
import { ReactComponent as IconLoader } from '../../../assets/icons/loader-white.svg';
import { capitalizeFirstLetter } from '../../../services/tools/TypeHelper';
import DivDebug from '../../../components/DivDebug/DivDebug';
import CopyToClipboard from '../../../components/CopyToClipboard/CopyToClipboard';

const Detail: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab') || 'mine';

    const { id } = useParams<{ id: string }>();
    const [flowDetail, setFlowDetail] = useState<apiFlow.FlowOutputDetail | null>(null);
    const [isReadonly, setIsReadonly] = useState<boolean>(true);
    const [flowFunding, setFlowFunding] = useState<api.FlowFundingOutput | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const AlertSwal = withReactContent(Swal);
    const [comment, setComment] = useState<string>('');
    const [disableComment, setDisableComment] = useState<boolean>(true);
    const setThemes = useSetRecoilState(themesStateAtom);
    const setCurrent = useSetRecoilState(currentRouteAtom);

    // Les différents documents de l'audit (pas du dossier RA.)
    const [flowDocRow1, setFlowDocRow1] = useState<DocumentRow>([]);
    const [flowDocRow2, setFlowDocRow2] = useState<DocumentRow>([]);
    const [flowDocRow3, setFlowDocRow3] = useState<DocumentRow>([]);
    const [flowDocRow4, setFlowDocRow4] = useState<DocumentRow>([]);

    /**
     * Status :
     * grey === '';
     * yellow === 'edit';
     * green === 'complete';
     */
    const links = useMemo(() => {
        const updatedLinks = { ...initialStepProperties };
        const url = flowDetail && apiFlow.isAuditThermique(flowDetail.auditType) ? `${process.env.PUBLIC_URL}/audit-thermique/audit` : routes.ROUTE_AUDIT;

        switch (flowDetail?.state) {
            case 'Created':
            case 'AuditSent':
                updatedLinks.audit.btnStatus = 'edit';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = '';
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            case 'WithoutRdv':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = 'Audit sans rendez-vous';
                // Extra button
                updatedLinks.extra.btnStatus = 'edit';
                updatedLinks.extra.label = 'Prise de rendez-vous';
                updatedLinks.extra.url = routes.ROUTE_AUDIT_RDV;

                updatedLinks.simulateur.btnStatus = '';
                updatedLinks.simulateur.url = '';

                updatedLinks.previsite.btnStatus = '';
                updatedLinks.previsite.url = '';

                updatedLinks.financement.btnStatus = '';
                break;

            case 'WithoutDevis':
            case 'RdvRequested':
            case 'RdvSigned':
            case 'DevisSent':
            case 'WithoutCommand':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'edit';
                updatedLinks.simulateur.url = isReadonly ? '' : routes.ROUTE_SIM_RECOMMANDATION;
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            case 'CommandSigned':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'complete';
                updatedLinks.simulateur.url = ''; // leads nowhere
                updatedLinks.previsite.btnStatus = 'edit';
                updatedLinks.previsite.url = isReadonly ? '' : routes.ROUTE_PV_VALIDATION;
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            // Pre-visite
            case 'WithoutPrevisit':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'complete';
                updatedLinks.simulateur.url = ''; // leads nowhere
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            case 'WithoutInstallation':
            case 'WithInstallation':
                updatedLinks.audit.btnStatus = 'complete';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = 'complete';
                updatedLinks.simulateur.url = ''; // leads nowhere
                updatedLinks.previsite.btnStatus = 'complete';
                updatedLinks.previsite.url = ''; // leads nowhere
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;

            default:
                updatedLinks.audit.btnStatus = 'edit';
                updatedLinks.audit.url = url;
                updatedLinks.audit.label = "Visualiser l'audit";
                updatedLinks.simulateur.btnStatus = '';
                updatedLinks.previsite.btnStatus = '';
                updatedLinks.financement.btnStatus = '';
                updatedLinks.extra.btnStatus = 'invisible';
                break;
        }

        switch (flowFunding?.fundingState) {
            case 'EN ANALYSE':
            case 'A CONTROLER':
            case 'INCOMPLET':
                updatedLinks.financement.btnStatus = 'edit';
                updatedLinks.financement.url = isReadonly ? '' : (flowFunding?.fundingUrl as string);
                updatedLinks.financement.label = 'Financement';
                break;

            case 'A FINANCER':
                updatedLinks.financement.btnStatus = 'complete';
                updatedLinks.financement.url = isReadonly ? '' : (flowFunding?.fundingUrl as string);
                updatedLinks.financement.label = 'Financement';
                break;

            case 'ABANDONNEE':
            case 'DEFAVORABLE':
                updatedLinks.financement.btnStatus = 'error';
                updatedLinks.financement.url = isReadonly ? '' : (flowFunding?.fundingUrl as string);
                updatedLinks.financement.label = 'Financement';
                break;

            default:
                updatedLinks.financement.btnStatus = '';
                updatedLinks.financement.label = 'Financement';
                break;
        }

        if (loading) {
            updatedLinks.financement.btnStatus = 'loading';
            updatedLinks.financement.label = <IconLoader width={38} height={38} />;
        }

        return updatedLinks;
    }, [flowDetail, flowFunding, loading, isReadonly]);

    const load = (to: string, target: '_self' | '_blank' | undefined): void => {
        if (!flowDetail) return;
        // clear storage & reset states
        localStorage.removeItem('flowId');
        localStorage.removeItem('auditId');
        localStorage.removeItem('devisId');
        localStorage.removeItem('flowState');
        localStorage.removeItem('auditType');

        storageService.resetPrevisitDocUrl();
        storageService.resetInstallationDate();
        storageService.resetRecoAppointment();
        storageService.resetFunding();
        setThemes(EMPTY_THEMES);
        setCurrent(routes.ROUTE_PV_VALIDATION);

        // load general
        localStorage.setItem('flowState', flowDetail.state);
        localStorage.setItem('auditType', flowDetail.auditType);

        // load funding
        if (flowFunding?.fundingType === 'Loan') {
            storageService.setFunding(flowFunding);
        }

        //  move ceilingType and floorType from step6 to step2 :
        if (flowDetail.audit) {
            const newAudit = storageService.copyCeilingTypeAndFloorTypeFromStep6ToStep2(flowDetail.audit);
            flowDetail.audit = newAudit;
        }

        console.log('flowDetail.step2', JSON.stringify((flowDetail.audit as any).step2, null, 2));

        // load / unload audit data
        storageService.setAudit(flowDetail.audit ?? Template);
        // TODO Manage AL / RAC
        storageService.setAuditCampagne(undefined);
        localStorage.setItem('flowId', flowDetail.id);
        if (flowDetail.auditId) {
            localStorage.setItem('auditId', flowDetail.auditId.toString());
        }

        // load / unload sim data
        storageService.setSim(flowDetail.devis ?? TemplateSimulator);
        if (flowDetail?.themes) {
            setThemes(flowDetail.themes);
        }
        if (flowDetail?.devisId) {
            storageService.setDevisId(flowDetail.devisId.toString());
        }

        // load / unload previsit data
        const invalidPrevisit = flowDetail.previsit === undefined || flowDetail.previsit === null || Object.keys(flowDetail.previsit).length <= 0;
        storageService.setPrevisit(!invalidPrevisit ? flowDetail.previsit : TemplatePreVisit);
        if (flowDetail.previsitDoc && flowDetail.previsitDoc.url) {
            storageService.setPrevisitDocUrl(flowDetail.previsitDoc.url);
        }

        if (flowDetail.installationAppointment) {
            storageService.setInstallationDate(flowDetail.installationAppointment.date);
        }

        if (flowDetail.recoAppointment) {
            storageService.setRecoAppointment(flowDetail.recoAppointment);
        }

        window.open(to, target);
        return;
    };

    // Save/Remove installation date
    useEffect(() => {
        if (!flowDetail) return;

        // TODO : Si il n'y a pas de recoAppointement dans ce flow. On devrait effacer celui du storage. non ?
        // Pareil pour les autres prorpiétés ....

        if (flowDetail.recoAppointment) storageService.setRecoAppointment(flowDetail.recoAppointment);
        if (flowDetail.installationAppointment) storageService.setInstallationDate(flowDetail.installationAppointment.date);
        if (flowDetail.comment) setComment(flowDetail.comment);

        if (flowDetail.previsitDoc) {
            storageService.setPrevisitDocUrl(flowDetail.previsitDoc.url);

            // On prepare les meta données pour afficher les boutons dans lee composant documents
            const row2: DocumentRow = [
                {
                    title: 'Pré-visite',
                    url: flowDetail.previsitDoc.url,
                    extension: flowDetail.previsitDoc.extension,
                },
            ];
            setFlowDocRow2(row2);
        }
    }, [flowDetail]);

    // Update current study status
    useEffect(() => {
        const fetchData = async () => {
            try {
                const flowDetail = await apiFlow.getFlow(id);
                const agentCode = storageService.getCurrentAgentCode();
                setIsReadonly(agentCode !== flowDetail.agentCode);

                setFlowDetail(flowDetail);

                const row1: DocumentRow = [];

                if (flowDetail.quotationDoc !== null) {
                    row1.push({
                        title: flowDetail.quotationType !== 'Complete' ? 'Devis' : 'Proposition commerciale & technique',
                        url: flowDetail.quotationDoc.url,
                        extension: flowDetail.quotationDoc.extension,
                    });
                }

                if (flowDetail.enedisConsent !== null) {
                    row1.push({
                        title: 'Consentement ENEDIS',
                        url: flowDetail.enedisConsent.document.url,
                        extension: flowDetail.enedisConsent.document.extension,
                    });
                }

                setFlowDocRow1(row1);

                if (flowDetail.fundingType !== 'Loan') return;
                setLoading(true);
                const funding = await api.getFundingData(id);
                storageService.setFunding(funding);
                setFlowFunding(funding);
            } catch (error: any) {
                if (error.statusCode === 403) {
                    AlertSwal.fire({
                        icon: 'error',
                        title: "Vous n'êtes pas autorisé à accéder au détail de cet audit",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                    console.log("Vous n'êtes pas autorisé à accéder au détail de cet audit");
                    // Rediriger vers la page précédente
                    window.history.back();
                } else {
                    console.error("Une erreur s'est produite lors de la récupération des données :", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // Update signed documents
    useEffect(() => {
        if (!flowDetail) return;
        const fetchCommandDocuments = async () => {
            try {
                const apiDocuments = await apiFlow.getFlowSignedDocuments(id);

                const row3: DocumentRow = Object.entries(apiDocuments).map(([key, value]) => {
                    const title: string = key.replaceAll('_', ' ');
                    return { title: capitalizeFirstLetter(title), url: value, extension: 'pdf' };
                });
                setFlowDocRow3(row3);
            } catch (error: any) {
                if (error.statusCode === 400) {
                    console.log('Aucun document signé trouvé pour cet audit');
                } else {
                    console.error('Erreur lors de la génération des documents:', error);
                }
            }
        };

        fetchCommandDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowDetail]);

    // Associated documents
    useEffect(() => {
        if (!flowDetail) return;
        const fetchAssociatedDocuments = async () => {
            try {
                const associatedDocuments = await apiFlow.getAssociatedFlowDocuments(id);

                const row4: DocumentRow = associatedDocuments.map((doc) => {
                    const comment = capitalizeFirstLetter(knownAssociatedDocTypeToString(doc.comment as KnownFlowDocType));
                    return {
                        title: knownAssociatedDocTypeToString(doc.comment as KnownFlowDocType),
                        comment,
                        url: doc.document.url,
                        extension: doc.document.extension,
                    };
                });
                setFlowDocRow4(row4);
            } catch (error) {
                console.error(error);
            }
        };

        fetchAssociatedDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowDetail]);

    // date d'installation
    const installationDate: string | null = storageService.getInstallationDate();

    const displayDate = (date: string) => {
        return format(Date.parse(date), 'dd/MM/yyyy');
    };

    const displayHours = (date: string) => {
        const hours = format(Date.parse(date), 'kk:mm');

        switch (hours) {
            case '08:00':
                return '08:00 - 12:00';

            case '14:00':
                return '14:00 - 18:00';

            default:
                return '';
        }
    };

    // Update comment
    const updateComment = (newComment: string) => {
        try {
            api.updateFlowComment(id, newComment);

            // Show success modal and autoclose
            AlertSwal.fire({
                icon: 'success',
                title: 'Succès',
                html: <p>Votre modification a bien été prise en compte</p>,
                timer: 1000, // time in ms
                timerProgressBar: true,
                showConfirmButton: false,
            });

            // Disable comment field
            setDisableComment(true);
        } catch (error) {
            console.error('Erreur lors de la mise à jour du commentaire:', error);
        }
    };

    return (
        <div className="container">
            <div className="py-4">
                <Back title="Liste" url={`${routes.ROUTE_EA_LIST}?tab=${tab}`} />
            </div>
            <div className="row align-items-stretch py-4">
                <div className="col-12 col-md-8">
                    <div className="container-detail split-70 py-4 h-100">
                        <div className="row">
                            <h2>Informations</h2>

                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                <div className="form-inline">
                                    <DivDebug>
                                        <FormRow
                                            label="id technique"
                                            value={<CopyToClipboard text={flowDetail?.id as string}>{flowDetail?.id}</CopyToClipboard>}
                                        />
                                    </DivDebug>
                                    <FormRow label="Type d'audit" value={flowDetail?.auditType ?? '-'} />
                                    <FormRow
                                        label="Date"
                                        value={
                                            flowDetail?.updatedAt ?? flowDetail?.createdAt
                                                ? format(new Date(flowDetail?.updatedAt ?? flowDetail?.createdAt), 'dd/MM/yyyy')
                                                : '-'
                                        }
                                    />
                                    <FormRow label="Nom" value={flowDetail?.customer?.userLastname ?? '-'} />
                                    <FormRow label="Prénom" value={flowDetail?.customer?.userFirstname ?? '-'} />

                                    <div className="form-inline">
                                        <FormRow
                                            label="Date d'installation"
                                            value={installationDate ? `${displayDate(installationDate)} ${displayHours(installationDate)}` : 'Non défini'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                <div className="form-inline">
                                    <FormRow label="Téléphone" value={flowDetail?.customer?.userTelephone ?? '-'} />
                                    <FormRow label="Adresse" value={flowDetail?.customer?.userAddress ?? '-'} />
                                    <FormRow label="Code postal" value={flowDetail?.customer?.userZipCode ?? '-'} />
                                    <FormRow label="Ville" value={flowDetail?.customer?.userLocality ?? '-'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="container-detail split-30 py-4 h-100">
                        <div className="row">
                            <h2>Commentaire</h2>
                            <div className="col-12">
                                {!isReadonly && (
                                    <>
                                        {!disableComment ? (
                                            <button className="btn btn-change-pref btn-change-pref-save" onClick={() => updateComment(comment)}>
                                                Enregistrer
                                            </button>
                                        ) : (
                                            <button className="btn btn-change-pref" onClick={() => setDisableComment(false)}>
                                                Modifier
                                            </button>
                                        )}
                                    </>
                                )}
                                <div className="form-group">
                                    <textarea
                                        name="comment"
                                        className="form-control invisible-field form-control--textarea"
                                        value={comment}
                                        placeholder="Aucun commentaire"
                                        onChange={(e) => setComment(e.target.value)}
                                        disabled={disableComment}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {flowDetail?.dossierRenoDAmpleur && (
                <DossierRenoDampleur
                    dossierRenoDAmpleur={flowDetail.dossierRenoDAmpleur}
                    isReadonly={isReadonly}
                    // hasMandatAnah={hasMandatAnah}
                    // mandatAnahDate={mandatAnahDate}
                    // setDraDocuments={setDraDocuments}
                    load={load}
                />
            )}

            <div className="container-detail py-4 mb-4">
                <div className="row">
                    <h2>Statut</h2>

                    {flowStepNames.map((key, index) => {
                        const step = key as FlowStep;
                        const descriptor = links[step];

                        return (
                            <div className="col" key={index} style={descriptor.btnStatus !== 'invisible' ? { display: 'block' } : { display: 'none' }}>
                                <div className="status" data-status={descriptor.btnStatus}>
                                    <button
                                        className="btn btn-status"
                                        onClick={() => load(descriptor.url, descriptor.target)}
                                        style={{ cursor: !descriptor.url ? 'not-allowed' : 'pointer' }}
                                    >
                                        {descriptor.label}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <DocumentList title="Liste des documents de l'audit" rows={[flowDocRow1, flowDocRow2, flowDocRow3, flowDocRow4]} AddDocument={undefined} />
        </div>
    );
};

export default Detail;
