import React, { useEffect } from 'react';
import * as routes from '../../../../routing/paths';
import { Back } from '../../../../components/Back/Back';
import AppForm from '../../../../components/Forms/AppForm';
import AppFormField from '../../../../components/Forms/AppFormField';
import { Field, useFormikContext } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { GetValidationSchema } from './schema/schema';
import { useHistory } from 'react-router-dom';
import * as api from '../../../../services/apiParticulierService';
import AppFormSelectField from '../../../../components/Forms/AppFormSelectField';
import { EmptyCreateAgentFormValues, civilityList } from './CreateAgentEnums';
import { Labelled } from '../../../../services/tools/TypeHelper';
import FileUpload from '../../../../components/Document/FileUpload';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sponsorState } from '../../../../services/Recoil/Atom/SponsorMode.atom';

// Icons
import { ReactComponent as IconUser } from '../../../../assets/icons/espace-agent/icon-account.svg';

export type CreateAgentFormValues = {
    civility: Labelled<api.Civility> | undefined;
    lastname: string;
    firstname: string;
    email: string;
    phoneNumber: string;
    address: string;
    zipcode: string;
    city: string;
    identityDocument1: File | null;
    // ids to send
    identityDocument1Id?: string | undefined;
};

interface FormProps {
    values: CreateAgentFormValues;
}

const Form: React.FC<FormProps> = () => {
    const { errors } = useFormikContext<CreateAgentFormValues>();
    const AlertSwal = withReactContent(Swal);
    const { push } = useHistory();
    const [sponsor, setSponsorMode] = useRecoilState(sponsorState);

    const handleCancel = async () => {
        return await AlertSwal.fire({
            title: "Quitter l'écran",
            html: <p>Les données rentrées seront perdues.</p>,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Annuler',
            confirmButtonText: "Quitter l'écran",
            customClass: {
                confirmButton: 'btn btn-continue',
                cancelButton: 'btn btn-retour',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                push(routes.ROUTE_EA_NETWORK);
            }
        });
    };

    useEffect(() => {
        const loadMyself = async () => {
            const loadedSponsorMode = (await api.getMyself()).sponsorMode;
            setSponsorMode(loadedSponsorMode);
        };

        loadMyself();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container-creation p-4 mb-4">
            <div className="row">
                <h2 className="d-flex align-items-center gap-2">
                    <IconUser fill="#5a5a5a" height={16} />
                    Informations agent
                </h2>

                <p>
                    Merci de remplir les éléments suivants. Une fois ces éléments vérifiés, l'agent concerné par la demande d'affiliation recevra un email lui
                    permettant de finaliser la création de son compte.
                </p>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-5">
                    <Field
                        labelName="Civilité"
                        name="civility"
                        customLabelClass={'form-label col-lg-4'}
                        isRequired
                        component={AppFormSelectField}
                        options={civilityList}
                        placeholder={''}
                    />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-5">
                    <Field labelName="Nom" name="lastname" customLabelClass={'col-lg-4'} isRequired component={AppFormField} className={'form-control'} />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-5">
                    <Field labelName="Prénom" name="firstname" customLabelClass={'col-lg-4'} isRequired component={AppFormField} className={'form-control'} />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-5">
                    <Field labelName="Email" name="email" customLabelClass={'col-lg-4'} isRequired component={AppFormField} className={'form-control'} />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-5">
                    <Field
                        type="text"
                        labelName="Téléphone portable"
                        name="phoneNumber"
                        maxLength={10}
                        customLabelClass={'col-lg-4'}
                        isRequired
                        component={AppFormField}
                        className={'form-control'}
                    />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-lg-5">
                    <Field labelName="Adresse" name="address" customLabelClass={'col-lg-4'} isRequired component={AppFormField} className={'form-control'} />
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-12 col-md-6 col-lg-5 mb-4 mb-md-0">
                    <Field
                        type="number"
                        labelName="Code postal"
                        name="zipcode"
                        customLabelClass={'col-lg-4'}
                        isRequired
                        component={AppFormField}
                        className={'form-control'}
                    />
                </div>

                <div className="col-12 col-md-6 col-lg-5">
                    <Field labelName="Ville" name="city" customLabelClass={'col-lg-4'} isRequired component={AppFormField} className={'form-control'} />
                </div>
            </div>

            {sponsor === 'Normal' && (
                <div className="row mb-4">
                    <div className="form-group">
                        <label className="form-label">
                            <span>
                                Pièce d'identité<sup>*</sup>
                            </span>
                            &nbsp;
                            <span className="info">(carte nationale d'identité, passeport ou permis de conduire)</span>:
                        </label>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4">
                        <FileUpload label="Télécharger document" name="identityDocument1" />
                    </div>
                </div>
            )}

            <div className="btn-grp justify-content-center">
                <button type="button" className="btn btn-retour" onClick={handleCancel}>
                    Annuler
                </button>
                <button type="submit" className="btn btn-continue" disabled={Object.entries(errors).length > 0}>
                    Créer
                </button>
            </div>
        </div>
    );
};

const CreateAgent: React.FC = () => {
    // Formik default values
    const initialValues = EmptyCreateAgentFormValues;
    //const initialValues = createRandomAgent();
    const sponsor = useRecoilValue(sponsorState);

    const AlertSwal = withReactContent(Swal);
    const { push } = useHistory();

    const validationSchema = GetValidationSchema();

    const handleSubmit = async (values: CreateAgentFormValues) => {
        let title = '';
        let message = '';
        let confirmButton = '';

        switch (sponsor) {
            case 'Pyramidal':
                title = "Demande d'affiliation";
                message = `Merci de confirmer votre demande d'affiliation pour ${values.lastname} ${values.firstname}`;
                confirmButton = 'Demander affiliation';
                break;

            case 'Normal':
                title = "Création d'un nouvel agent";
                message = `Merci de confirmer votre demande de création pour ${values.lastname} ${values.firstname}`;
                confirmButton = 'Créer agent';
                break;

            default:
                title = "Demande d'affiliation";
                message = `Merci de confirmer votre demande d'affiliation pour ${values.lastname} ${values.firstname}`;
                confirmButton = 'Demander affiliation';
        }

        return await AlertSwal.fire({
            title: `${title}`,
            html: <p>{message}</p>,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Annuler',
            confirmButtonText: `${confirmButton}`,
            customClass: {
                confirmButton: 'btn btn-continue',
                cancelButton: 'btn btn-retour',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    if (sponsor === 'Normal') {
                        const documents = api.postDocument({ recto: values.identityDocument1 });
                        documents.then((documents) => {
                            const data: api.InviteNewAgentInput = {
                                ...values,
                                civility: values.civility?.value as api.Civility,
                                identityDocument1Id: documents.recto.id,
                            };

                            api.inviteNewAgent(data);
                            // Debugg
                            // console.log('values:', data);
                            handleValidateAgent();
                        });
                    } else {
                        const data: api.InviteNewAgentInput = {
                            ...values,
                            civility: values.civility?.value as api.Civility,
                        };

                        api.inviteNewAgent(data);
                        // Debugg
                        // console.log('values:', data);
                        handleValidateAgent();
                    }
                    // Send datas to API
                } catch (error) {
                    console.error("Erreur lors de l'envoi des données de l'agent :", error);
                }
            }
        });
    };

    // Agent validation and re-route to
    const handleValidateAgent = () => {
        let title = '';
        let message = '';

        switch (sponsor) {
            case 'Pyramidal':
                title = 'Demande envoyée';
                message = `Votre demande d'affiliation a été envoyé à un administrateur Sogys. Elle sera traitée dans les plus brefs délais.`;
                break;

            case 'Normal':
                title = "Création d'un nouvel agent";
                message = `Votre demande sera traitée dans les plus bref délais`;
                break;

            default:
                title = "Votre demande de création d'un nouvel agent a été envoyée à l'administrateur";
                message = `Votre demande d'affiliation a été envoyé à un administrateur Sogys. Elle sera traitée dans les plus brefs délais.`;
        }

        AlertSwal.fire({
            title: `${title}`,
            icon: 'success',
            html: <p>{message}</p>,
            confirmButtonText: 'Valider',
            customClass: {
                confirmButton: 'btn btn-continue',
            },
        }).then(() => {
            push(routes.ROUTE_EA_NETWORK);
        });
    };

    return (
        <div className="container list-audit">
            <div className="list-audit-header d-flex justify-content-between align-items-center w-100 py-4 mb-5">
                <Back title="Mon réseau" url={routes.ROUTE_EA_NETWORK} />
            </div>

            <div className="row">
                <div className="col-12">
                    <AppForm initialValues={initialValues} onSubmit={(values) => handleSubmit(values)} validationSchema={validationSchema} validateOnMount>
                        <Form values={initialValues} />
                    </AppForm>
                </div>
            </div>
        </div>
    );
};

export default CreateAgent;
