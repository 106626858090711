export const viewableDocs = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'ico', 'webp'];

export const downloadableDocs = ['zip', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];

export const imageDocs = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'ico', 'webp'];

export const imageDocsRegex = new RegExp(`\\.(${imageDocs.join('|')})$`, 'i');

export type DocumentEntry = {
    title: string;
    url: string;
    extension: string;
    comment?: string | undefined;
};
