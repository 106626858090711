import React, { useEffect, useMemo } from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { DocumentsViewer } from '../../../components/Document/DocumentsViewer';
import * as api from '../../../services/apiParticulierService';
import * as storageService from '../../../services/localStorageService';
import { useRecoilValue } from 'recoil';
import { previsitContextPacksSelector, PrevisitSubThemes } from '../../../services/Recoil/Atom/PackageContext.atom';
import { getLevelCount, getSurfaceDesMurs } from '../../../services/tools/auditTools';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';
import { createUploadFormDataForPreVisit } from '../../../services/tools/imageHelper';
import { PAC_RATIO_DEPERDITION_BESOIN_CHAUFFAGE } from '../../../services/calculs/filterPreconisation/filterChauffage';
import { PackageData } from '../../../services/calculs/package';

const Finalisation: React.FC = () => {
    // Initialisation modal
    const AlertSwal = withReactContent(Swal);
    const companyLogo = storageService.getCurrentAgent().companyLogo;
    const flowId = localStorage.getItem('flowId')!;
    const { step1, step2, step3, step6 } = storageService.getAudit();
    const audit = storageService.stepListToAuditAndClient().audit;
    const solarEnergyPanels = storageService.checkPropertyExistThenCreateOrRenderSimulator(
        'solarEnergyPanels',
        'Panneaux solaires',
        storageService.PropertyType['basic']
    );

    const previsitContextPacks = useRecoilValue(previsitContextPacksSelector);

    const openPrevisitPdf = () => {
        const storedUrl = storageService.getPrevisitDocUrl();

        if (storedUrl) {
            // window.open(storedUrl);
            const doc = { url: storedUrl, title: 'Document de pré-visite', extension: 'pdf' };
            AlertSwal.fire({
                title: '',
                html: <DocumentsViewer doc={doc} />,
                width: 900,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });
        }
    };

    const previsitPacks = useMemo(() => {
        return previsitContextPacks?.map((pack: PackageData) => {
            // le cast est OK, car previsitContextPacks est filtré par isSearchedSubThemesType, dans le code de l'atom
            return pack.themeLb.split('/')[1] as PrevisitSubThemes;
        });
    }, [previsitContextPacks]);

    // Pac Air ou Eau
    const power = useMemo(() => {
        for (const item of previsitContextPacks!) {
            if (item.puissance) {
                return item.puissance;
            }
        }
        return 0;
    }, [previsitContextPacks]);

    const createDimensionnement = async (
        audit: any,
        lossInput: api.EnergyLossInput,
        lossOutput: api.EnergyLossOutput,
        pacPower: number
    ): Promise<api.NoteDeDimensionnement> => {
        // Attention, le nombre de level varie entre appell algorithme cardonnel et affichage dans le pdf (ici on est PAS borné à 3.)
        let levels = getLevelCount(audit, 'horsSSavecCA'); // Check that.
        levels = levels < 1 ? 1 : levels;

        const wallSurface = getSurfaceDesMurs(audit);
        return {
            address: step1.userAddress.value,
            postalCode: step1.userZipCode.value,
            city: step1.userLocality.value,
            altitude: lossInput.altitude.toFixed(0) + ' m',
            constructionDate: lossInput.year.toString(),
            setTemperature: lossInput.consigneTemperature + '°C',
            baseTemperature: lossOutput.temperatureBase + '°C',
            habitableSurface: lossInput.surfaceHab + 'm²',
            houseWidth: step2.buildingWidth.value + 'm',
            houseLength: step2.buildingLength.value + 'm',
            numberOfLevels: levels.toString(), // NOT lossInput.levels, wich is max 3.
            wallSurface: wallSurface + 'm²',
            wallInsulation: lossInput.murs ? 'Oui' : 'Non',
            roofInsulation: lossInput.combles ? 'Oui' : 'Non',
            floorInsulation: lossInput.plancher ? 'Oui' : 'Non',
            humidityControlledVentilation: lossInput.hygroreglable ? 'Oui' : 'Non',
            doubleTripleGlazing: lossInput.doubleVitrage ? 'Oui' : 'Non',
            heatingLinkedHotWaterProduction: audit.heaterHotWaterIncluded.value === true ? 'Oui' : 'Non',
            heatLossCoefficient: lossOutput.coefficientDerperdition.toFixed(2) + ' W/K',
            totalHeatLoss: lossOutput.deperditions.toFixed(2) + ' kW',
            besoinPuissanceChauffage: (lossOutput.deperditions * PAC_RATIO_DEPERDITION_BESOIN_CHAUFFAGE).toFixed(2) + ' kW',
            recommendedHeatPumpPower: pacPower === 0 ? 'Aucune recommandation' : pacPower + ' kW',
        };
    };

    const validatePrevisit = async () => {
        AlertSwal.fire({
            title: 'Votre document de pré-visite est en cours de génération',
            html: <ModalCounter timer={30} />,
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
        });
        try {
            const energyLossInput = api.exctractAndCheckEnergyLossInfo(audit);

            let dimensionnement: api.NoteDeDimensionnement = {};

            if (energyLossInput) {
                try {
                    const energyLossOutput = await api.getEnergyLoss(energyLossInput);
                    dimensionnement = await createDimensionnement(audit, energyLossInput, energyLossOutput, power);
                } catch (error) {
                    dimensionnement = {};
                    console.log('Dimentionnement crashed = ' + error);
                }
            }

            // Generate PDF
            const docUrl = await api.validatePrevisit(flowId, { step1, step2, step3, step6, packages: previsitPacks, solarEnergyPanels, dimensionnement });

            // Store in local storage
            storageService.setPrevisitDocUrl(docUrl);
        } catch (error) {
            console.log('error ' + JSON.stringify(error, null, 2));
            console.error(error);
        } finally {
            AlertSwal.close();
        }
    };

    const handleMarAudit = async () => {
        try {
            AlertSwal.fire({
                title: 'Votre document de pré-visite est en cours de génération',
                html: <ModalCounter timer={30} />,
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
            });
            //création et
            const preVisiteFromLocalStorage = storageService.getPrevisit();
            const formData = createUploadFormDataForPreVisit();
            const data: api.UploadOutput = await api.uploadFiles(formData);

            if (data && data.success) {
                Object.values(data.success).forEach((value: api.UploadedImage) => {
                    preVisiteFromLocalStorage[value.fileKey].value = value.url;
                });
            }
            // mise à jour des données de prévisite
            await api.updatePrevisitData(flowId, preVisiteFromLocalStorage);
            //création de l'appointment
            await api.withoutInstallationAppointment(flowId);
            //validation de la prévisite => génération du pdf de prévisite.
            await validatePrevisit();
        } catch (error) {
            console.error('error', error);
            // TODO : ERROR MANAGEMENT
        } finally {
            AlertSwal.close();
        }
    };

    // useEffect modifié
    useEffect(() => {
        const storedUrl = storageService.getPrevisitDocUrl();
        if (storedUrl && storedUrl.includes(flowId)) {
            // URL is already available, no need to validate
            return;
        }

        // Parcours normal on appelle validate prévisit
        if (!storageService.isCurrentAuditMar()) {
            validatePrevisit();
            return;
        } else {
            // Parcours MAR
            handleMarAudit();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <h1 className="main-title-mini sr-only">Merci !</h1>
            <div className="py-5"></div>
            <div className="row justify-content-center">
                <div className="signature-logos mt-3 mb-5">
                    <div className="main-logo">
                        <img src={companyLogo} alt="Logicoll" aria-hidden="true" />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <h2 className="main-title-mini">Merci !</h2>
                    <p>Votre pré-visite est désormais finalisée.</p>

                    <p className="mb-5">
                        Nos équipes reprendront rapidement contact avec vous afin de finaliser les dernières modalités de votre installation.
                    </p>

                    <p className="text-end mb-5">Equipe SOGYS</p>

                    <div className="text-center">
                        <button type="button" className="btn btn-continue mt-4" onClick={openPrevisitPdf}>
                            Visualiser le PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Finalisation;
