import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { ReactComponent as LoaderSVG } from '../../../assets/icons/loader.svg';
import { CreateContractOutput } from '../../../services/apiTypes';

export type NetheosContratMarPropsContext = {
    draId: string;
    emptyMarContract: CreateContractOutput<'reno_ampleur', 'contrat_mar'>;
};

type NetheosContratMarProps = {
    token: string;
    context: NetheosContratMarPropsContext;
    onContratMarSigned: (context: NetheosContratMarPropsContext) => Promise<boolean>;
};

export const NetheosContratMar: React.FC<NetheosContratMarProps> = (props: NetheosContratMarProps) => {
    const signbookRef = useRef<any>(null);
    const [waitUpload, setWaitUpload] = useState<boolean>(false);

    function onErrorEventMessageFn(event: any) {
        // Errors detected by the SignBook (server errors, invalid state, unsupported browser, etc.)
        if (event.cause === 'REJECTED_STATE' || event.cause === 'ACCEPTED_STATE' || event.cause === 'SUSPENDED_STATE' || event.cause === 'WAITING_STATE') {
            // Do not deal with these "errors", this is just for backwards compatibility purpose...
            return;
        }
        console.log('netheos signature error event:', event.cause);
    }

    // Retours event iFrame
    function onExitEventMessageFn(event: any) {
        // TODO Fermer la popup avec isConfirmed à false.
        console.log('netheos signature exit.');
        Swal.close({ isConfirmed: false, isDenied: true, isDismissed: true });
    }

    const onClientFileEventMessage = async (event: any) => {
        if (
            (event.changedState && event.changedState.from === 'PENDING' && (event.changedState.to === 'WAITING' || event.changedState.to === 'ACCEPTED')) ||
            event.participantState === 'WAITING'
        ) {
            console.log('netheos signature success.');
            setWaitUpload(true);
            const success = await props.onContratMarSigned(props.context);
            setWaitUpload(false);
            Swal.close({ isConfirmed: success, isDenied: false, isDismissed: false });
        } else {
            if (event.state !== 'PENDING') {
                console.log('netheos signature State:', event.state);
            } else {
                console.log('netheos signature State:', event.state);
            }
        }
    };
    const onUserEventMessage = (event: any) => {
        console.log('onUserEventMessage = ' + JSON.stringify(event, null, 2));
        //     onUserEventMessage = {
        //     "type": "userEvent",
        //     "action": "REFUSE_SIGNATURE",
        //     "context": {
        //       "userIndex": 1,
        //       "page": "signature"
        //     }
        //   }
        if (event && event.action === 'REFUSE_SIGNATURE') {
            console.log('netheos signature refus de signature ');
            Swal.close({ isConfirmed: false, isDenied: false, isDismissed: false });
        }
    };
    useEffect(() => {
        const initializeSignbook = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-new, no-undef
            signbookRef.current = new NthSignbook({
                iframeSelectorId: 'contrat-mar',
                url: `${process.env.REACT_APP_EKEYNOX}/contract/signbook/v3/#/signature-page`,
                token: props.token,
                options: {
                    renderMode: 'pretty',
                },
                onExitEventMessage: onExitEventMessageFn,
                onErrorEventMessage: onErrorEventMessageFn,
                onClientFileEventMessage: onClientFileEventMessage,
                onDisplayEventMessage: () => {}, //(event: any) => {console.log('onDisplayEventMessage = ' + JSON.stringify(event, null, 2));},
                onUserEventMessage: onUserEventMessage,
            });
        };
        const destroySignbook = () => {
            if (signbookRef.current) {
                signbookRef.current.destroy();
            }
        };

        initializeSignbook();

        // Clean up event listeners when the component unmounts
        return () => {
            destroySignbook();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {waitUpload ? (
                <div className="text-center">
                    <LoaderSVG width={80} height={80} />
                    <p className="mb-0">
                        Mise à jour de votre Contrat MAR.
                        <br />
                        Cette action peut durer plusieurs secondes, merci de patienter.
                    </p>
                </div>
            ) : (
                <iframe
                    id="contrat-mar"
                    title="Contrat MAR"
                    width="100%"
                    height="500px"
                    allow="microphone; camera"
                    style={{ border: '0', overflow: 'hidden' }}
                />
            )}
        </>
    );
};
