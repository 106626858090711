import { useState } from 'react';
import './ToolTip.scss';

interface ToolTipProps {
    tooltip: string;
    left?: boolean;
    children?: React.ReactNode;
}
const ToolTip: React.FC<ToolTipProps> = (props: ToolTipProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e: React.MouseEvent) => {
        setPosition({ x: e.clientX, y: e.clientY });
    };

    return (
        <>
            <div
                onMouseMove={handleMouseMove}
                onMouseEnter={() => {
                    setShowTooltip(true);
                }}
                onMouseLeave={() => {
                    setShowTooltip(false);
                }}
            >
                {props.children || ''}
            </div>
            {showTooltip && (
                <div
                    className={`custom-tooltip ${props.left ? 'custom-tooltip-left' : ''}`}
                    style={{
                        position: 'fixed',
                        left: `${position.x + 20}px`,
                        top: `${position.y + 10}px`,
                    }}
                >
                    {props.tooltip}
                </div>
            )}
        </>
    );
};

export default ToolTip;
