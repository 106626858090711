import React from 'react';
import { SHOW_DEBUG } from '../../services/tools/generalTools';
import './DivDebug.scss';

interface DivDebugProps {
    children?: React.ReactNode;
}

const DivDebug: React.FC<DivDebugProps> = (props: DivDebugProps) => {
    const className = `div-debug ${SHOW_DEBUG ? 'show' : ''}`;

    return <div className={className}>{props.children}</div>;
};

export default DivDebug;
