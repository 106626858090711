import React, { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import Checkbox from '../../../components/checkbox/CheckBox';
import * as apiDRA from '../../../services/apiDRAService';
import { FormRow } from '../../../components/FormRow/FormRow';
import * as api from '../../../services/apiParticulierService';
import { draDocOutputToDocumentEntry, KnownDraDocType } from '../../../services/apiDRAService';
import FileFormRow from '../../../components/Document/FileFormRow';
import { NetheosAnah, NetheosAnahPropsContext } from './NetheosAnah';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { dateFormat } from '../../../services/tools/TypeHelper';
import { NetheosContratMar, NetheosContratMarPropsContext } from './NetheosContratMar';

import MailToMarForm from './MailToMarForm';

import './DraMakeItReady.scss';

type DraMakeItReadyProps = {
    dossierRenoDAmpleur: apiDRA.DossierRenoDAmpleur;
};

const DraMakeItReady: React.FC<DraMakeItReadyProps> = (props: DraMakeItReadyProps) => {
    const AlertSwal = withReactContent(Swal);
    const [clientInformed, setClientInformed] = useState<boolean | undefined>(props.dossierRenoDAmpleur.clientInformed ?? undefined);
    const [clientMprOpened, setClientMprOpened] = useState<boolean | undefined>(props.dossierRenoDAmpleur.clientMprOpened ?? undefined);

    const [collapsed, setCollapsed] = useState<boolean>(
        props.dossierRenoDAmpleur.state !== 'Created' && props.dossierRenoDAmpleur.state !== 'ReadyForContactMar'
    );

    const modeste =
        props.dossierRenoDAmpleur.renoDampleurParams?.elligible === true &&
        (props.dossierRenoDAmpleur.renoDampleurParams?.categorieRevenus === 'modeste' ||
            props.dossierRenoDAmpleur.renoDampleurParams?.categorieRevenus === 'tresModeste');

    const disabled = props.dossierRenoDAmpleur.state !== 'Created' && props.dossierRenoDAmpleur.state !== 'ReadyForContactMar';
    // 4 docs obligatoires :
    const mandatAnah = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'mandat_anah_mpr');
    const marContract = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'contrat_mar');
    const pieceIdentite = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'piece_identite');
    const avisImposition = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'avis_imposition');
    const taxeFonciere = props.dossierRenoDAmpleur.draDocs.find((doc) => doc.comment === 'taxe_fonciere');

    const showAskJustif = pieceIdentite === undefined || avisImposition === undefined || taxeFonciere === undefined;

    const updateDra = async (ci: boolean | undefined, cmp: boolean | undefined, comment: string[] | undefined = undefined) => {
        setClientInformed(ci);
        setClientMprOpened(cmp);
        await apiDRA.updateDra(props.dossierRenoDAmpleur.id, {
            clientInformed: ci,
            clientMprOpened: cmp,
            comment,
        });
        window.location.reload();
    };

    const handleDeleteDocument = async (document: apiDRA.DraDocOutput | undefined) => {
        // SI il y  a un lcc, on le supprime
        if (document && document.id) {
            await apiDRA.deleteDraDocument(props.dossierRenoDAmpleur.id, document.id);
            window.location.reload();
        }
    };

    const uploadDra = async (file: File, comment: KnownDraDocType) => {
        try {
            // Upload du doc, et association entre le doc et le dra
            const documents = await api.postDocument({ [comment]: file });
            await apiDRA.associateDraDocument(props.dossierRenoDAmpleur.id, documents[comment].id, comment);

            window.location.reload();
        } catch (error) {
            // TODO NICO : afficher un message d'erreur echec du téléchargement
            console.error("Erreur lors de l'envoi des données de l'agent :", error);
        }
    };

    const handleAnahModal = () => {
        try {
            apiDRA.anahConsentSign(props.dossierRenoDAmpleur.id).then((emptyNetheosContract) => {
                const context: NetheosAnahPropsContext = {
                    draId: props.dossierRenoDAmpleur.id,
                    emptyAnahContract: emptyNetheosContract,
                };

                AlertSwal.fire({
                    title: 'Signature du mandat Anah / MaPrimeRénov',
                    html: <NetheosAnah token={emptyNetheosContract.participantAccessToken} context={context} onAnahConsentSigned={onAnahConsentSigned} />,
                    width: 800,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
            });
        } catch (error) {
            console.log('consentement impossible a envoyer : ' + error);
            throw error;
        }
    };

    const onAnahConsentSigned = async (context: NetheosAnahPropsContext): Promise<boolean> => {
        try {
            await apiDRA.anahConsentDownload(context.draId, context.emptyAnahContract.dossierUuid);

            return true;
        } catch (err) {
            console.log('download failed');
            return false;
        } finally {
            // ATTENTION ICI, si jamais on modifie le code de src/pages/espace-agent/Detail/NetheosAnah.tsx
            // Et qu'on utilise vraiment le returne true ou false pour faire un traitement
            // (autre qu'arrêter le sablier). Alors il faudra trouver un autre moyen de rafraichir la page.
            window.location.reload();
        }
    };

    const handleContratMarModal = () => {
        try {
            apiDRA
                .contractMarSign(props.dossierRenoDAmpleur.id)
                .then((emptyNetheosContract) => {
                    const context: NetheosContratMarPropsContext = {
                        draId: props.dossierRenoDAmpleur.id,
                        emptyMarContract: emptyNetheosContract,
                    };

                    AlertSwal.fire({
                        title: 'Signature du contrat MAR',
                        html: (
                            <NetheosContratMar token={emptyNetheosContract.participantAccessToken} context={context} onContratMarSigned={onContratMarSigned} />
                        ),
                        width: 800,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                    });
                })
                .catch((error) => {
                    if (api.isErrorBody(error)) {
                        AlertSwal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: error.message,
                        });
                    } else {
                        console.log('consentement impossible a envoyer : ' + error);
                        throw error;
                    }
                });
        } catch (error) {
            console.log('consentement impossible a envoyer : ' + error);
            throw error;
        }
    };

    const onContratMarSigned = async (context: NetheosContratMarPropsContext): Promise<boolean> => {
        try {
            await apiDRA.contractMarDownload(context.draId, context.emptyMarContract.dossierUuid);
            return true;
        } catch (err) {
            console.log('download failed');
            return false;
        } finally {
            window.location.reload();
        }
    };

    const handleAskJustif = async (): Promise<void> => {
        try {
            await apiDRA.askJustificatif(props.dossierRenoDAmpleur.id);

            // Show success modal and autoclose
            AlertSwal.fire({
                icon: 'success',
                title: 'Succès',
                html: <p>Le mail de demande de justificatif a bien été envoyé</p>,
                timer: 1500, // time in ms
                timerProgressBar: true,
                showConfirmButton: false,
            }).then(() => {
                window.location.reload();
            });
        } catch (e) {
            console.error(e);
        }
    };

    const popupMprAnah = (title: string, url: string) => {
        // si le context n'est pas rempli, il y a eu une erreur Financo, l'agent devra créer l'étude financo à la main
        const content =
            "Vous êtes sur le point d&apos;ouvrir un nouvel onglet afin de vérifier si votre client a déjà un compte Anah / MaPrimeRénov ouvert. L'onglet de votre audit est resté ouvert et vous pourrez y revenir à tout moment.";
        AlertSwal.fire({
            title,
            html: (
                <>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{content}</p>
                    <div className="swal2-actions">
                        <a href={url} className="btn btn-continue min-width" target="_blank" rel="noopener noreferrer" onClick={() => AlertSwal.close()}>
                            Ouvrir l&apos;onglet
                        </a>
                    </div>
                </>
            ),
            width: 600,
            showCancelButton: false,
            showConfirmButton: false,
            confirmButtonText: 'Ouvrir l&apos;onglet',
            allowOutsideClick: false,
        });
    };

    return (
        <>
            {collapsed ? (
                <div className="d-flex align-items-center justify-content-between">
                    <span>Voir les détails de préparation du dossier.</span>
                    <button className="btn p-0" onClick={() => setCollapsed(false)}>
                        <span>▶</span>
                    </button>
                </div>
            ) : (
                <div className={`${collapsed ? 'collapsed' : ''}`}>
                    <button className="btn p-0 float-end" onClick={() => setCollapsed(true)}>
                        <span>▼</span>
                    </button>
                    <Formik initialValues={{ comment: '' }} onSubmit={() => {}}>
                        {({ values, errors, touched, setValues }: FormikProps<{ comment: string }>) => (
                            <div>
                                {disabled ? (
                                    <>
                                        {props.dossierRenoDAmpleur.justificatifAskedAt && (
                                            <FormRow label="Jusitificatifs demandés le" value={dateFormat(props.dossierRenoDAmpleur.justificatifAskedAt)} />
                                        )}
                                        <FormRow
                                            label="Le client est bien au courant du règlement à faire au MAR"
                                            value={props.dossierRenoDAmpleur.clientInformed ? 'Oui' : 'Non'}
                                        />
                                        <FormRow
                                            label="Le client a déjà un compte MPR ouvert"
                                            value={props.dossierRenoDAmpleur.clientMprOpened ? 'Oui' : 'Non'}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {props.dossierRenoDAmpleur.justificatifAskedAt ? (
                                            <>
                                                <FormRow
                                                    label="Jusitificatifs demandés le"
                                                    value={
                                                        <>
                                                            <span className="me-5">{dateFormat(props.dossierRenoDAmpleur.justificatifAskedAt)}</span>
                                                            <button
                                                                className="btn btn-change-pref btn-ask-justif-done position-static mw-100 "
                                                                onClick={handleAskJustif}
                                                            >
                                                                Re-demander les justificatifs au client
                                                            </button>
                                                        </>
                                                    }
                                                />
                                            </>
                                        ) : (
                                            showAskJustif && (
                                                <button className="btn btn-change-pref position-static mw-100 mb-3" onClick={handleAskJustif}>
                                                    Demander les justificatifs au client
                                                </button>
                                            )
                                        )}

                                        <FormRow
                                            label="Le client est bien au courant du règlement à faire au MAR"
                                            value={
                                                <Checkbox
                                                    checked={clientInformed}
                                                    onCheckedChange={(checked: boolean) => {
                                                        updateDra(checked, clientMprOpened);
                                                    }}
                                                />
                                            }
                                        />
                                    </>
                                )}

                                <div className="col-12">
                                    {mandatAnah ? (
                                        <FileFormRow<apiDRA.DraDocOutput>
                                            label={apiDRA.knownDraDocTypeToString('mandat_anah_mpr')}
                                            optionalLabels={{ uploadFilename: 'Mandat Anah' }}
                                            fileDescriptor={mandatAnah}
                                            disabled={disabled}
                                            canDeleteDocs={true}
                                            convertToDocumentEntry={draDocOutputToDocumentEntry}
                                            onDelete={handleDeleteDocument}
                                            onUpload={(file: File) => uploadDra(file, 'mandat_anah_mpr')}
                                            labelWidth="235px"
                                        />
                                    ) : (
                                        <>
                                            {' '}
                                            <FormRow
                                                label={apiDRA.knownDraDocTypeToString('mandat_anah_mpr')}
                                                value={
                                                    <>
                                                        <button className="btn btn-change-pref position-static mw-100 me-2" onClick={() => handleAnahModal()}>
                                                            Signature Mandat Anah
                                                        </button>
                                                    </>
                                                }
                                            />
                                        </>
                                    )}
                                    {marContract ? (
                                        <FileFormRow<apiDRA.DraDocOutput>
                                            label={apiDRA.knownDraDocTypeToString('contrat_mar')}
                                            optionalLabels={{ uploadFilename: 'Contrat MAR' }}
                                            fileDescriptor={marContract}
                                            disabled={disabled}
                                            canDeleteDocs={true}
                                            convertToDocumentEntry={draDocOutputToDocumentEntry}
                                            onDelete={handleDeleteDocument}
                                            onUpload={(file: File) => uploadDra(file, 'contrat_mar')}
                                            labelWidth="235px"
                                        />
                                    ) : (
                                        <>
                                            <FormRow
                                                label={apiDRA.knownDraDocTypeToString('contrat_mar')}
                                                value={
                                                    <>
                                                        <button className="btn btn-change-pref position-static mw-100" onClick={() => handleContratMarModal()}>
                                                            Signer le contrat avec le MAR
                                                        </button>
                                                    </>
                                                }
                                            />
                                        </>
                                    )}
                                    <FileFormRow<apiDRA.DraDocOutput | undefined>
                                        label={apiDRA.knownDraDocTypeToString('piece_identite')}
                                        optionalLabels={{ uploadFilename: "Pièce d'identité" }}
                                        fileDescriptor={pieceIdentite}
                                        disabled={disabled}
                                        canDeleteDocs={true}
                                        convertToDocumentEntry={draDocOutputToDocumentEntry}
                                        onDelete={handleDeleteDocument}
                                        onUpload={(file: File) => uploadDra(file, 'piece_identite')}
                                        labelWidth="235px"
                                    />
                                    <FileFormRow<apiDRA.DraDocOutput | undefined>
                                        label={apiDRA.knownDraDocTypeToString('avis_imposition')}
                                        optionalLabels={{ uploadFilename: "Avis d'imposition" }}
                                        fileDescriptor={avisImposition}
                                        disabled={disabled}
                                        canDeleteDocs={true}
                                        convertToDocumentEntry={draDocOutputToDocumentEntry}
                                        onDelete={handleDeleteDocument}
                                        onUpload={(file: File) => uploadDra(file, 'avis_imposition')}
                                        labelWidth="235px"
                                    />
                                    <FileFormRow<apiDRA.DraDocOutput | undefined>
                                        label={apiDRA.knownDraDocTypeToString('taxe_fonciere')}
                                        optionalLabels={{ uploadFilename: 'Taxe foncière' }}
                                        fileDescriptor={taxeFonciere}
                                        disabled={disabled}
                                        canDeleteDocs={true}
                                        convertToDocumentEntry={draDocOutputToDocumentEntry}
                                        onDelete={handleDeleteDocument}
                                        onUpload={(file: File) => uploadDra(file, 'taxe_fonciere')}
                                        labelWidth="235px"
                                    />
                                </div>

                                {modeste ? (
                                    <a
                                        href="#!"
                                        onClick={() =>
                                            popupMprAnah(
                                                'Vérifiez le compte Anah',
                                                'https://monprojet.anah.gouv.fr/projet_prospects/nouveau?po_prospect_projet%5Baccept_conditions%5D=0&po_prospect_projet%5Baccept_conditions%5D=1&commit=Commencer'
                                            )
                                        }
                                    >
                                        Vérifiez le compte Anah.
                                    </a>
                                ) : (
                                    <a
                                        href="#!"
                                        onClick={() => popupMprAnah('Vérifiez le compte MPR', 'https://www.maprimerenov.gouv.fr/prweb/PRAuth/app/AIDES/')}
                                    >
                                        Vérifiez le compte MPR.
                                    </a>
                                )}

                                {disabled ? (
                                    <FormRow label="Le client a déjà un compte MPR ouvert" value={props.dossierRenoDAmpleur.clientMprOpened ? 'Oui' : 'Non'} />
                                ) : (
                                    <FormRow
                                        label="Le client a déjà un compte MPR ouvert"
                                        value={
                                            <Checkbox
                                                checked={clientMprOpened}
                                                onCheckedChange={(checked: boolean) => {
                                                    updateDra(clientInformed, checked);
                                                }}
                                            />
                                        }
                                    />
                                )}

                                {props.dossierRenoDAmpleur.state === 'ReadyForContactMar' && (
                                    <div className="border rounded p-3 mb-3">
                                        <MailToMarForm dossierRenoDAmpleur={props.dossierRenoDAmpleur} />
                                    </div>
                                )}
                            </div>
                        )}
                    </Formik>
                </div>
            )}
        </>
    );
};

export default DraMakeItReady;
